import React, { useState, useEffect, useContext } from "react";

const ProgressBar = ({ qandas }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setProgress(
      (qandas.filter((qanda) => qanda.answer !== null || qanda.isNa).length /
        qandas.length) *
        100,
    );
  }, [qandas]);

  if (progress === 0) return null;

  return (
    <div className="px-16">
      <div className="h-4 w-full rounded-full bg-neon-50">
        <div
          className="h-full rounded-full bg-neon-200 text-center text-xs text-white"
          style={{ width: `${progress}%` }}
        ></div>
      </div>
    </div>
  );
};

export default ProgressBar;
