import React, { Fragment, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { Input } from "@headlessui/react";
import { PacksContext } from "../../../context/PacksContext";
import clsx from "clsx";

const WebsiteDialog = ({ handleSave, updatePack, handleSuccess }) => {
  const { packs } = useContext(PacksContext);
  const { packId } = useParams();
  const [newWebsite, setNewWebsite] = useState(packs[packId].vendorWebsite);
  return (
    <Fragment>
      <Input
        type="text"
        placeholder="Enter the vendor's website"
        value={newWebsite}
        onChange={(e) => setNewWebsite(e.target.value)}
        className={clsx(
          "my-3 block w-full rounded-md border-none px-3 py-1.5 text-sm/6 text-gray-900 shadow-sm ring-1 ring-gray-200 focus:outline-none focus:ring-2 focus:ring-neon-400 focus:ring-offset-2 focus:ring-offset-gray-300",
          "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-black/25",
        )}
      />
      <button
        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 active:bg-gray-100 sm:col-start-1 sm:mt-0"
        onClick={() =>
          handleSave(
            packId,
            { vendor_website: newWebsite },
            updatePack,
            handleSuccess,
          )
        }
      >
        Save
      </button>
    </Fragment>
  );
};

export default WebsiteDialog;
