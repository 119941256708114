import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "../../axiosInstance";
import Cookies from "js-cookie";
import { PacksContext } from "../../context/PacksContext";
import PackHeader from "../../components/Pack/PackHeader/PackHeader";
import Slate from "../../components/ui/Slate/Slate";
import GenericModal from "../../components/ui/GenericModal/GenericModal";
import "./PackDocuments.css";

const PackDocuments = () => {
  const { packId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { packs, selectPack, updatePack } = useContext(PacksContext);
  const [packDocuments, setPackDocuments] = useState(null);
  const [documentId, setDocumentId] = useState(null);
  const [documentName, setDocumentName] = useState("");
  const [documentUrl, setDocumentUrl] = useState("");

  useEffect(() => {
    if (packId) selectPack(packId);

    let isMounted = true;
    const fetchPackDocuments = async () => {
      if (isMounted) setIsLoading(true);
      try {
        const response = await axiosInstance.get(
          `/api/packs/${packId}/documents`,
        );
        if (isMounted) {
          setPackDocuments(response.data);
        }
      } catch (error) {
        if (isMounted) setError(error.message);
      } finally {
        if (isMounted) setIsLoading(false);
      }
    };
    if (packId) fetchPackDocuments();
    return () => {
      isMounted = false;
    }; // Cleanup function to prevent memory leaks
  }, [packId]);

  const saveDocument = async () => {
    try {
      let updatedDocument = {
        packId,
        documentId,
        documentName,
        documentUrl,
      };
      const response = await axiosInstance.post(
        `/api/packs/${packId}/documents/update`,
        updatedDocument,
      );

      setPackDocuments(response.data);
    } catch (error) {
      console.error("Error updating document:", error);
    }

    closeModal();
  };

  const deleteDocument = async () => {
    if (
      window.confirm(
        "Are you sure you want to delete this document? This operation is irreversible.",
      )
    ) {
      try {
        const response = await axiosInstance.post(
          `/api/packs/${packId}/documents/${documentId}/delete`,
        );

        setPackDocuments(response.data);
      } catch (error) {
        console.error("Error deleting document:", error);
      }
      closeModal();
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleNewDocument = () => {
    setDocumentId(null);
    setDocumentName("");
    setDocumentUrl("");
    setIsModalOpen(true);
  };

  const handleEditDocument = (document) => {
    setDocumentId(document.id);
    setDocumentName(document.name);
    setDocumentUrl(document.url);
    setIsModalOpen(true);
  };

  if (isLoading)
    return (
      <div>
        <PackHeader />
        {/* Loading content... */}
      </div>
    );
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="pack-documents flex flex-grow flex-col">
      <PackHeader />
      <Slate
        slateTitle="External Documents"
        slateButton={
          <button
            className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            onClick={handleNewDocument}
          >
            Add Doc
          </button>
        }
      >
        <div className="list-container">
          {packDocuments && packDocuments.length > 0 ? (
            packDocuments.map((document) => (
              <div
                key={document.id}
                className="list-item"
                onClick={() => window.open(document.url, "_blank")}
              >
                <div className="doc-name">{document.name}</div>
                <div className="doc-url">{document.url}</div>
                <button
                  className="button edit-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEditDocument(document);
                  }}
                >
                  Edit
                </button>
              </div>
            ))
          ) : (
            <div>
              <div className="empty-state">
                No external documents in this pack
              </div>
            </div>
          )}
        </div>
      </Slate>
      {isModalOpen && (
        <GenericModal
          isOpen={isModalOpen}
          onClose={closeModal}
          onDelete={documentId != null ? deleteDocument : undefined}
          onSave={saveDocument}
          title="Document details"
        >
          <div className="section">
            <h3 className="text-lg font-semibold leading-7 text-gray-900">
              Name
            </h3>
            <input
              type="text"
              value={documentName}
              onChange={(e) => setDocumentName(e.target.value)}
              placeholder="eg: Contract"
            />
          </div>
          <div className="section">
            <h3 className="text-lg font-semibold leading-7 text-gray-900">
              URL
            </h3>
            <input
              type="text"
              value={documentUrl}
              onChange={(e) => setDocumentUrl(e.target.value)}
              placeholder="eg: https://docs.google.com/d/12345"
            />
          </div>
        </GenericModal>
      )}
    </div>
  );
};

export default PackDocuments;
