import React, { createContext, useState, useEffect } from "react";
import axiosInstance from "../axiosInstance";
import { identifyAmplitudeUser } from "../amplitudeUtils";
import logoImage from "./logo64.png";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState({
    id: "",
    name: "",
    imageUrl: "",
  });
  const [companyId, setCompanyId] = useState("");
  const [companyName, setCompanyName] = useState("");

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const response = await axiosInstance.get("/api/auth/verify");
        // If the request succeeds, the user is authenticated
        setIsAuthenticated(true);
        setIsLoading(false);
        // Add the user data to state
        setUser({
          id: response.data.userId,
          name: response.data.userName,
          imageUrl: response.data.imageUrl,
        });
        setCompanyId(response.data.companyId);
        setCompanyName(response.data.companyName);
        // Identify the user in Amplitude
        identifyAmplitudeUser(response.data.userId);
      } catch (error) {
        // Request failed, user is not authenticated
        setIsAuthenticated(false);
        setIsLoading(false);
      }
    };

    checkAuthentication();
  }, []);

  const login = ({ userId, userName, imageUrl, companyId, companyName }) => {
    setIsAuthenticated(true);
    // Add the user data to state
    setUser({
      id: userId,
      name: userName,
      imageUrl: imageUrl,
    });
    setCompanyId(companyId);
    setCompanyName(companyName);
    // Identify the user in Amplitude
    identifyAmplitudeUser(userId);
  };

  const logout = async () => {
    try {
      await axiosInstance.post("/api/auth/logout");
      setIsAuthenticated(false);
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  if (isLoading) {
    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-white bg-opacity-20 transition-opacity duration-500 ease-in-out">
        <div className="flex flex-col items-center">
          <img src={logoImage} alt="Company Logo" className="mb-4 h-24 w-24" />
          <p className="text-base text-gray-700">Loading</p>
        </div>
      </div>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticated,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
