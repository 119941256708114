import { Fragment, useContext } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { PacksContext } from "../../../context/PacksContext";
import PackApprovalActions from "./PackApprovalActions";
import { packLifecycleOptions } from "../../../utils";
import { ApprovalStatus } from "../../../constants";

export default function PackApprovals({ pack, open, setOpen }) {
  const { packs } = useContext(PacksContext);

  const approvalBadge = (status) => {
    switch (status) {
      case ApprovalStatus.UNANSWERED:
        return (
          <div className="inline-flex items-center rounded-md bg-amber-100 px-4 py-2 text-sm font-medium text-amber-600 ring-1 ring-amber-300">
            Not reviewed yet
          </div>
        );
      case ApprovalStatus.APPROVED:
        return (
          <div className="inline-flex items-center rounded-md bg-green-100 px-4 py-2 text-sm font-medium text-green-600 ring-1 ring-green-300">
            Approved
          </div>
        );
      case ApprovalStatus.REJECTED:
        return (
          <div className="inline-flex items-center rounded-md bg-red-100 px-4 py-2 text-sm font-medium text-red-600 ring-1 ring-red-300">
            Rejected
          </div>
        );
      default:
        return (
          <div className="inline-flex items-center rounded-md bg-gray-100 px-4 py-2 text-sm font-medium text-gray-600 ring-1 ring-gray-300">
            No approver
          </div>
        );
    }
  };

  const PendingModulesPlaceholder = () => {
    if (pack.approvals.length === 0) {
      return null;
    } else if (pack.modules.filter((m) => m.status != 6).length === 0) {
      return null;
    }
    return (
      <div className="my-5 flex flex-col rounded-md bg-paper-darker px-3 py-5 text-sm font-medium">
        <div className="flex text-gray-900">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="h-11 w-11"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"
            />
          </svg>
          <span className="ml-3 font-bold text-gray-900">
            Before reviewing this Pack, keep in mind these modules haven't been
            finished yet
          </span>
        </div>
        <ul className="mt-2 px-5 text-gray-900">
          {pack.modules
            .filter((m) => m.status != 6)
            .map((m) => (
              <li key={m.id} className="my-1 list-disc font-normal">
                {m.name}
              </li>
            ))}
        </ul>
      </div>
    );
  };

  const getLastReviewedDate = (pack) => {
    if (
      pack.approvals.length > 0 &&
      (pack.approvals[0]?.status === ApprovalStatus.APPROVED ||
        pack.approvals[0]?.status === ApprovalStatus.REJECTED)
    ) {
      return `${new Date(pack.approvals[0]?.reviewedOn).toLocaleDateString()} by ${pack.approvals[0]?.reviewedBy?.name}`;
    } else {
      return "NA";
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-400 sm:duration-100"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-400 sm:duration-100"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-400"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-400"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                      <button
                        type="button"
                        className="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={() => setOpen(false)}
                      >
                        <span className="absolute -inset-2.5" />
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="h-full w-screen max-w-md overflow-y-auto bg-paper p-8">
                    <div className="space-y-6 pb-24">
                      <div className="flex justify-between">
                        <h2 className="text-2xl font-bold text-gray-900">
                          Pack Approval
                        </h2>
                        {approvalBadge(packs[pack.id].approvalStatus)}
                      </div>
                      <div>
                        <h3 className="font-medium text-gray-900">
                          Modules info
                        </h3>
                        <dl className="mt-2 divide-y divide-gray-200 border-b border-t border-gray-200">
                          <div className="flex justify-between py-3 text-sm font-medium">
                            <dt className="text-gray-500">Number of modules</dt>
                            <dd className="text-gray-900">
                              {pack.modules.length}
                            </dd>
                          </div>
                          <div className="flex justify-between py-3 text-sm font-medium">
                            <dt className="text-gray-500">Finished modules</dt>
                            <dd className="text-gray-900">
                              {
                                pack.modules.filter(
                                  (m) => m.status === 6 || m.status === 3,
                                ).length
                              }
                            </dd>
                          </div>
                        </dl>
                        <PendingModulesPlaceholder />
                      </div>
                      <div>
                        <h3 className="font-medium text-gray-900">Pack info</h3>
                        <dl className="mt-2 divide-y divide-gray-200 border-b border-t border-gray-200">
                          <div className="flex justify-between py-4 text-sm font-medium">
                            <dt className="text-gray-500">Pack lifecycle</dt>
                            <dd className="text-gray-900">
                              {packLifecycleOptions[pack.lifecycle].label}
                            </dd>
                          </div>
                          <div className="flex justify-between py-4 text-sm font-medium">
                            <dt className="text-gray-500">Pack Owner</dt>
                            <dd className="text-gray-900">
                              {pack.owner ? (
                                <div className="flex items-center">
                                  <img
                                    src={pack.owner.image_24}
                                    alt=""
                                    className="mr-2 h-6 w-6 rounded-full"
                                  />
                                  <span>{pack.owner.name}</span>
                                </div>
                              ) : (
                                <span>No owner</span>
                              )}
                            </dd>
                          </div>
                          <div className="flex justify-between py-4 text-sm font-medium">
                            <dt className="text-gray-500">Pack approver</dt>
                            <dd className="text-gray-900">
                              {pack.approvals.length > 0 ? (
                                <div className="flex items-center">
                                  <img
                                    src={pack.approvals[0]?.user.image_24}
                                    alt=""
                                    className="mr-2 h-6 w-6 rounded-full"
                                  />
                                  <span>{pack.approvals[0]?.user.name}</span>
                                </div>
                              ) : (
                                <span>No approver</span>
                              )}
                            </dd>
                          </div>
                          <div className="flex justify-between py-4 text-sm font-medium">
                            <dt className="text-gray-500">Last reviewed</dt>
                            <dd className="text-gray-900">
                              {getLastReviewedDate(pack)}
                            </dd>
                          </div>
                        </dl>
                      </div>
                    </div>
                  </div>
                  <PackApprovalActions pack={pack} />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
