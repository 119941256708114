import React, { Fragment, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { Label, Radio, RadioGroup } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { PacksContext } from "../../../context/PacksContext";
import { vendorTypeOptions } from "../../../utils";

const SupplierTypeDialog = ({ handleSave, updatePack, handleSuccess }) => {
  const { packs } = useContext(PacksContext);
  const { packId } = useParams();
  const [newVendorType, setNewVendorType] = useState(packs[packId].vendorType);
  return (
    <Fragment>
      <RadioGroup
        value={newVendorType}
        onChange={setNewVendorType}
        className="my-6"
      >
        <Label className="sr-only">Choose a type of pack</Label>
        <div className="relative grid grid-cols-2 gap-2">
          {vendorTypeOptions.map((type) => (
            <Radio
              key={type.value}
              value={type.value}
              className={
                "group cursor-pointer rounded-md bg-white/5 px-5 py-4 text-gray-900 shadow-sm ring-1 ring-gray-200 transition focus:outline-none data-[checked]:bg-neon-500/10 data-[focus]:outline-1 data-[focus]:outline-neon-500 data-[checked]:ring-neon-200"
              }
            >
              <div className="flex w-full items-center justify-between">
                <div className="flex space-x-2">
                  {(() => {
                    let Icon = type.heroIcon;
                    return <Icon className="h-5 w-5 text-gray-700" />;
                  })()}
                  <div className="text-sm font-semibold">{type.label}</div>
                </div>
                <div>
                  <CheckCircleIcon className="[active]:opacity-100 size-6 text-neon-500 opacity-0 transition group-data-[checked]:opacity-100" />
                </div>
              </div>
            </Radio>
          ))}
        </div>
      </RadioGroup>

      <button
        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 active:bg-gray-100 sm:col-start-1 sm:mt-0"
        onClick={() =>
          handleSave(
            packId,
            { vendor_type: newVendorType },
            updatePack,
            handleSuccess,
          )
        }
      >
        Save
      </button>
    </Fragment>
  );
};

export default SupplierTypeDialog;
