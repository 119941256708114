import React, { useState, useContext, Fragment, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { CheckIcon } from "@heroicons/react/24/outline";
import axiosInstance from "../axiosInstance";
import { PacksContext } from "../context/PacksContext";
import ModuleModal from "../components/ModuleModal/ModuleModal";
import EditDialog from "../components/PackOverview/KeyInformationDialogs/EditDialog";
import PackTypesRow from "../components/InitialQuestionnaire/General/PackTypesRow";
import AboutPack from "../components/PackOverview/AboutPack";
import QandasCard from "../components/InitialQuestionnaire/Qandas/QandasCard";
import StakeholdersCard from "../components/InitialQuestionnaire/Stakeholders/StakeholdersCard";
import { classNames } from "../utils";

const InitialQuestionnaire = () => {
  const navigate = useNavigate();
  const { packId } = useParams();
  const { packs, selectedPack, updatePack } = useContext(PacksContext);
  const [status, setStatus] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setStatus(packs[selectedPack.id]?.questionnaireStatus);
  }, [packs, selectedPack]);

  useEffect(() => {
    const fetchQuestionnaireStatus = async () => {
      setIsLoading(true);
      try {
        const response = await axiosInstance.get("/api/questionnaire/status", {
          params: { packId: packId },
        });
        let updatedPack = {
          ...packs[selectedPack.id],
          questionnaireStatus: response.data,
        };
        updatePack(selectedPack.id, updatedPack);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching questionnaire status:", error);
      }
    };
    if (packId && !status) fetchQuestionnaireStatus();
  }, [packs, status, updatePack, packId]);

  const handleFinishInitialAssessment = async () => {
    try {
      const response = await axiosInstance.post("/api/questionnaire/finish", {
        packId: selectedPack.id,
      });
      let updatedPack = {
        ...response.data.pack,
        modules: response.data.modules,
      };

      updatePack(selectedPack.id, updatedPack);
      navigate(`/packs/${selectedPack.id}`);
    } catch (error) {
      console.error("Error fetching stakeholders data:", error);
    }
  };

  if (!status || isLoading) return null;

  return (
    <div className="flex flex-1 flex-col space-y-6">
      <PackTypesRow
        setDialogContent={setDialogContent}
        setEditDialogOpen={setEditDialogOpen}
      />
      <AboutPack
        setDialogContent={setDialogContent}
        setEditDialogOpen={setEditDialogOpen}
      />
      <QandasCard
        cardEnabled={status.showQandasCard}
        qandasComplete={status.qandasComplete}
        qandasStats={status.qandasStats}
        modulesInfo={status.modulesInfo}
        setIsModalOpen={setIsModalOpen}
      />

      <StakeholdersCard
        cardEnabled={status.qandasComplete}
        moduleModalOpen={isModalOpen}
        packType={packs[selectedPack.id].type}
      />
      <div className="flex justify-end">
        <button
          className={classNames(
            "flex space-x-3 rounded bg-neon-500 px-4 py-3 font-bold text-white hover:bg-neon-700",
            !status.qandasComplete && "cursor-not-allowed opacity-30",
          )}
          onClick={() => handleFinishInitialAssessment()}
          disabled={!status.qandasComplete}
        >
          <CheckIcon className="size-6" />
          <span> Finish initial assessment</span>
        </button>
      </div>
      <EditDialog
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        dialogContent={dialogContent}
      />
      <ModuleModal
        // moduleId={selectedModuleId}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
      />
    </div>
  );
};

export default InitialQuestionnaire;
