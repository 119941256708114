import React from "react";
import { classNames } from "../../utils";

const ViewNotes = ({ currentModule, handleClick }) => {
  return (
    <div
      className={classNames(
        currentModule.notes === null || currentModule.notes === undefined
          ? "mx-3 mt-2 border-2 border-dashed border-gray-300 p-6 text-center text-xs font-light italic hover:border-gray-400"
          : "p-3 hover:bg-paper-dark",
        "cursor-pointer rounded-lg text-sm",
      )}
      onClick={() => handleClick(currentModule, "notes")}
    >
      <dd className="mt-1 text-sm text-gray-900">{currentModule.notes}</dd>
    </div>
  );
};

export default ViewNotes;
