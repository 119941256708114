import React from "react";

const Placeholder = () => {
  return (
    <div className="flex h-64 items-center justify-center rounded-lg bg-white p-6 shadow">
      <div className="flex flex-col items-center space-x-2 space-y-5">
        <div className="text-gray-400">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="h-8 w-8"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
            />
          </svg>
        </div>
        <span className="text-as mt-2 block font-medium text-gray-400">
          Stakeholders
        </span>
      </div>
    </div>
  );
};

export default Placeholder;
