import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../axiosInstance";
import { PacksContext } from "../../context/PacksContext";
import Slate from "../../components/ui/Slate/Slate";

function DangerZone({ packId }) {
  const navigate = useNavigate();
  const { removePack } = useContext(PacksContext);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const deletePack = async () => {
    try {
      const response = await axiosInstance.delete("/api/packs/delete", {
        data: {
          pack_id: packId,
        },
      });

      removePack(packId);
      setConfirmDelete(false);
      navigate(`/packs`);
    } catch (error) {
      console.error("Error deleting pack:", error);
    }
  };

  const handleDeleteClicked = () => {
    if (confirmDelete === true) {
      deletePack();
    } else {
      setConfirmDelete(true);
    }
  };

  return (
    <Slate slateTitle="Danger Zone">
      <div className="flex flex-1 items-center justify-between pt-5">
        <h3 className="text-base leading-6 text-gray-900">
          Delete this pack and all its modules, answers and approvals.
        </h3>
        <div className="mt-3">
          {!confirmDelete ? (
            <button
              type="button"
              className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-2 ring-red-500 hover:bg-red-50"
              onClick={(e) => {
                handleDeleteClicked();
              }}
            >
              Delete Pack
            </button>
          ) : (
            <button
              type="button"
              className="inline-flex items-center rounded-md bg-red-500 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm  ring-red-600 hover:bg-red-800"
              onClick={(e) => {
                handleDeleteClicked();
              }}
            >
              ARE YOU SURE? THIS IS IRREVERSIBLE!
            </button>
          )}
        </div>
      </div>
    </Slate>
  );
}

export default DangerZone;
