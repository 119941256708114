import React, { Fragment, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { PacksContext } from "../../../context/PacksContext";
import { UserIcon } from "@heroicons/react/24/outline";

const OwnerDialog = ({ handleSave, handleSuccess }) => {
  const { packs } = useContext(PacksContext);
  const { packId } = useParams();
  const [packOwner, setPackOwner] = useState(packs[packId].owner);

  const ownerViewer = () => {
    return (
      <div className="relative flex space-x-4 rounded bg-paper p-5">
        {packOwner.image512 ? (
          <img
            className="h-28 w-28 rounded-md"
            src={packOwner.image512}
            alt="pack owner profile picture"
          />
        ) : (
          <div className="flex h-28 w-28 items-center justify-center rounded-md bg-paper-dark ring-1 ring-paper-darker">
            <UserIcon
              className="h-20 w-20 p-1 text-gray-500"
              aria-hidden="true"
            />
          </div>
        )}
        <div className="mt-2 flex-col">
          <div className="text-xl font-semibold">
            {packOwner.name || "No owner set"}
          </div>
          <div className="text-sm">{packOwner.email}</div>
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      <div className={"my-4"}>
        <div className="my-2 text-sm text-gray-700">
          {packOwner
            ? "The pack owner can be changed in the settings tab."
            : "No owner set for this pack. You can set the pack owner in the Settings tab."}
        </div>
        {packOwner && ownerViewer()}
      </div>
      <button
        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
        onClick={handleSuccess}
      >
        Close
      </button>
    </Fragment>
  );
};

export default OwnerDialog;
