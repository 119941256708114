import React, { Fragment, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { RadioGroup, Radio, Label } from "@headlessui/react";
import {
  CheckCircleIcon,
  Squares2X2Icon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import { packTypeOptions, vendorTypeOptions } from "../../../utils";
import { PacksContext } from "../../../context/PacksContext";
import { updatePackData } from "../../../services/packApi";

const PackTypesRow = ({ setDialogContent, setEditDialogOpen }) => {
  const { packId } = useParams();
  const { packs, updatePack } = useContext(PacksContext);
  const [packType, setPackType] = useState(packs[packId]?.type || "");
  const [vendorType, setVendorType] = useState(packs[packId]?.vendorType || "");

  let vendorTypeObj = vendorTypeOptions.find(
    (option) => option.value === packs[packId]?.vendorType || "",
  );

  let VendorTypeIcon = vendorTypeObj?.heroIcon || ExclamationCircleIcon;

  const packTypeSelected = (value) => {
    // When the user selects a pack in the type radio group, we:
    // 1. Update the local state with the selected pack type
    // 2. Update the pack in the database via the API
    // 3. Update the pack data in the context with the API response
    setPackType(value);
    updatePackData(packId, { type: value }, updatePack, () => {});
  };

  return (
    <Fragment>
      <RadioGroup value={packType} onChange={packTypeSelected}>
        <Label className="sr-only">Choose a type of pack</Label>
        <div className="relative flex gap-2 xl:gap-4">
          {packTypeOptions.map((type) => (
            <Radio
              key={type.value}
              value={type.value}
              className={
                "group flex-1 cursor-pointer rounded-md bg-white px-5 py-4 text-gray-900 shadow-sm ring-1 ring-gray-200 transition focus:outline-none data-[checked]:bg-neon-500/10 data-[focus]:outline-1 data-[focus]:outline-neon-500 data-[checked]:ring-neon-200"
              }
            >
              <div className="flex w-full items-center justify-between">
                <div className="flex space-x-2">
                  {(() => {
                    let Icon = type.heroIcon;
                    return <Icon className="h-5 w-5 text-gray-700" />;
                  })()}
                  <div className="text-sm font-semibold">{type.label}</div>
                </div>
                <div>
                  <CheckCircleIcon className="[active]:opacity-100 size-6 text-neon-500 opacity-0 transition group-data-[checked]:opacity-100" />
                </div>
              </div>
            </Radio>
          ))}
        </div>
      </RadioGroup>
      {packType === "supplier" && (
        <button
          value={vendorType}
          className={
            "group cursor-pointer rounded-md bg-white px-5 py-4 text-gray-900 shadow-sm ring-1 ring-gray-200 transition focus:outline-none data-[checked]:bg-neon-500/10 data-[focus]:outline-1 data-[focus]:outline-neon-500 data-[checked]:ring-neon-200"
          }
          onClick={() => {
            setDialogContent({
              code: "supplierType",
              label: "Supplier Type",
              value: packs[packId]?.vendorType,
              icon: <Squares2X2Icon className="h-5 w-5 text-gray-700" />,
            });
            setEditDialogOpen(true);
          }}
        >
          <div className="flex w-full items-center justify-between">
            <div className="flex space-x-2">
              <VendorTypeIcon className="h-5 w-5 text-gray-700" />
              <div className="text-sm font-semibold">
                {vendorTypeObj?.label || "What kind of supplier is this?"}
              </div>
            </div>
            <div>
              <CheckCircleIcon className="[active]:opacity-100 size-6 text-neon-500 opacity-0 transition group-data-[checked]:opacity-100" />
            </div>
          </div>
        </button>
      )}
    </Fragment>
  );
};

export default PackTypesRow;
