import React, { Fragment, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { Input } from "@headlessui/react";
import { PacksContext } from "../../../context/PacksContext";
import { XCircleIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";

const SignDateDialog = ({ handleSave, updatePack, handleSuccess }) => {
  const { packs } = useContext(PacksContext);
  const { packId } = useParams();
  const [newContractSignDate, setNewContractSignDate] = useState(
    packs[packId].contractSignDate
      ? new Date(packs[packId].contractSignDate).toISOString().slice(0, 10)
      : "",
  );

  return (
    <Fragment>
      <div className="flex items-center">
        <Input
          type="date"
          value={newContractSignDate}
          onChange={(e) => setNewContractSignDate(e.target.value)}
          className={clsx(
            "my-3 block w-full rounded-md border-none px-3 py-1.5 text-sm/6 text-gray-900 shadow-sm ring-1 ring-gray-200 focus:outline-none focus:ring-2 focus:ring-neon-400 focus:ring-offset-2 focus:ring-offset-gray-300",
            "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-black/25",
          )}
        />
        <button onClick={() => setNewContractSignDate("")}>
          <XCircleIcon className="ml-2 size-5 text-gray-500" />
        </button>
      </div>
      <button
        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 active:bg-gray-100 sm:col-start-1 sm:mt-0"
        onClick={() =>
          handleSave(
            packId,
            { contract_sign_date: newContractSignDate || null },
            updatePack,
            handleSuccess,
          )
        }
      >
        Save
      </button>
    </Fragment>
  );
};

export default SignDateDialog;
