import React, { Fragment, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { PacksContext } from "../../../context/PacksContext";
import AutoResizingTextarea from "../../ui/AutoResizingTextarea/AutoResizingTextarea";

const PackDescriptionDialog = ({ handleSave, updatePack, handleSuccess }) => {
  const { packs } = useContext(PacksContext);
  const { packId } = useParams();
  const [newDescription, setNewDescription] = useState(
    packs[packId].description,
  );
  return (
    <Fragment>
      <AutoResizingTextarea
        value={newDescription}
        onChange={setNewDescription}
      />
      <button
        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 active:bg-gray-100 sm:col-start-1 sm:mt-0"
        onClick={() =>
          handleSave(
            packId,
            { description: newDescription },
            updatePack,
            handleSuccess,
          )
        }
      >
        Save
      </button>
    </Fragment>
  );
};

export default PackDescriptionDialog;
