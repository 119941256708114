import React from "react";

const Module = ({ module, handleDragStart }) => {
  return (
    <div
      key={module.id}
      className="mr-3 inline-flex transform cursor-pointer items-center rounded-md bg-red-50 px-5 py-1 text-base font-medium text-red-800 ring-1 ring-inset ring-red-600/20 transition duration-100 ease-in-out hover:-translate-y-1 hover:scale-105 hover:bg-red-100 hover:ring-red-600"
      draggable="true"
      onDragStart={(e) => handleDragStart(e, module.id)}
    >
      {module.name}
    </div>
  );
};

export default Module;
