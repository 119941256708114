import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../axiosInstance";
import TodosTable from "../components/Todos/TodosTable";
import TopTodo from "../components/Todos/TopTodo";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Todos = () => {
  const navigate = useNavigate();
  const [allTodos, setAllTodos] = useState([]);
  const [topTodos, setTopTodos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchTodos();
  }, []);

  const fetchTodos = async () => {
    try {
      const response = await axiosInstance.get("/api/todos/list");
      setAllTodos(response.data.allTodos);
      setTopTodos(response.data.topTodos);
    } catch (error) {
      console.error("Error fetching todos:", error);
    } finally {
      // if (isMounted) setIsLoading(false);
      setIsLoading(false);
    }
  };

  const handleTodoClicked = (todo) => {
    console.log("Todo clicked:", todo);
    navigate(`${todo.url}`);
  };

  if (isLoading) {
    return (
      <div className="flex flex-grow flex-col items-center justify-center rounded-xl bg-transparent p-4">
        <FontAwesomeIcon
          icon={faCircleNotch}
          className="h-12 w-12 animate-spin text-neon-500"
        />
        <div className="mt-3 text-sm font-semibold">Loading tasks...</div>
      </div>
    );
  }

  return (
    <div className="flex flex-grow flex-col">
      <div className="grid grid-cols-3 gap-4">
        {topTodos.map((todo) => (
          <TopTodo
            key={todo.id}
            todo={todo}
            handleTodoClicked={handleTodoClicked}
          />
        ))}
      </div>
      <div>
        <TodosTable todos={allTodos} handleTodoClicked={handleTodoClicked} />
      </div>
    </div>
  );
};

export default Todos;
