import React, { useEffect, useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axiosInstance from "../../axiosInstance";
import Cookies from "js-cookie";
import AuthContext from "../../context/AuthContext";

const OAuthRedirectHandler = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { login } = useContext(AuthContext);

  useEffect(() => {
    const code = searchParams.get("code");

    if (code) {
      // Send the code to the backend to exchange for a JWT
      const exchangeCodeForToken = async () => {
        try {
          const response = await axiosInstance.post("/api/oauth/callback", {
            code,
          });
          // If the backend responds positively, navigate to the homepage
          login({
            userId: response.data.userId,
            userName: response.data.userName,
            imageUrl: response.data.imageUrl,
            companyId: response.data.companyId,
            companyName: response.data.companyName,
          });
          navigate("/");
        } catch (error) {
          // Handle errors, like showing a message or redirecting to the login page
          console.error("Error exchanging code for token:", error);
          navigate("/login");
        }
      };

      exchangeCodeForToken();
    } else {
      // No code present in URL, redirect to login
      navigate("/login");
    }
  }, [navigate, searchParams, login]);

  return <div>Authenticating...</div>;
};

export default OAuthRedirectHandler;
