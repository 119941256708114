import React, { useState, useEffect } from "react";
import axiosInstance from "../axiosInstance";
import ReportsTable from "../components/Reports/ReportsTable";
import ReportOverlay from "../components/Reports/ReportOverlay";

const Reports = () => {
  const [reports, setReports] = useState([]);
  const [selectedId, setSelectedId] = useState(null); // Id of the item being reported on eg pack id for packBasic
  const [selectedType, setSelectedType] = useState(null); // packBasic, etc
  const [showOverlay, setShowOverlay] = useState(false);
  const fetchReports = async () => {
    try {
      const response = await axiosInstance.get("/api/reports/list");
      setReports(response.data.reports);
    } catch (error) {
      //   setError(error.message);
    } finally {
      //   setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchReports();
  }, []);

  return (
    <div className="flex flex-grow flex-col">
      <ReportsTable
        reports={reports}
        setSelectedId={setSelectedId}
        setSelectedType={setSelectedType}
        setShowOverlay={setShowOverlay}
      />
      <ReportOverlay
        packId={selectedId}
        isOpen={showOverlay}
        setIsOpen={setShowOverlay}
        reportType={selectedType}
      />
    </div>
  );
};

export default Reports;
