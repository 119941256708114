import React, { Fragment, useEffect, useState } from "react";
import { conditionOperators, packConditionParameters } from "../../utils";

const ViewCondition = ({ conditionScope, condition, setEditMode }) => {
  switch (conditionScope) {
    case "pack":
      return (
        <div className="space-y-4 text-gray-900">
          <div className="flex items-start justify-between">
            <div className="text-base font-semibold">Pack condition</div>
            <button
              className="ml-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={() => setEditMode(true)}
            >
              Edit
            </button>
          </div>
          <div className="">
            {packConditionParameters.find((p) => p.id == condition.target).name}{" "}
            is{" "}
            {packConditionParameters
              .find((p) => p.id == condition.target)
              .options.find((o) => o.value == condition.value)
              .label.toLowerCase()}
            .
          </div>
        </div>
      );
    case "question":
      return (
        <div className="space-y-4 text-gray-900">
          <div className="flex items-start justify-between">
            <div className="text-base font-semibold">
              <span>The question: </span>
              <span className="italic">"{condition.target.text}"</span>
            </div>
            <button
              className="ml-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={() => setEditMode(true)}
            >
              Edit
            </button>
          </div>
          <div className="rounded-full bg-paper-darker text-center text-3xl opacity-60">
            {conditionOperators.find((o) => o.value == condition.operator).icon}
          </div>
          <div className="">
            {
              condition.target.options.find((o) => o.value == condition.value)
                .label
            }
          </div>
        </div>
      );
    default:
      return null;
  }
};

export default ViewCondition;
