import React, { Fragment } from "react";
import { Description } from "@headlessui/react";

const DerivedRiskDialog = ({ handleSuccess }) => {
  return (
    <Fragment>
      <Description className={"my-6"}>
        The derived risk is the highest risk across all pack modules. This
        cannot be edited.
      </Description>
      <button
        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
        onClick={() => handleSuccess()}
      >
        Ok
      </button>
    </Fragment>
  );
};

export default DerivedRiskDialog;
