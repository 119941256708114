import { Fragment, useState, useContext } from "react";
import { RadioGroup } from "@headlessui/react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import axiosInstance from "../../axiosInstance";
import { packTypeOptions, classNames } from "../../utils";
import { PacksContext } from "../../context/PacksContext";

export default function TypeModal({ pack, open, setOpen }) {
  const { packs, selectedPack, updatePack } = useContext(PacksContext);
  const [newPackType, setNewPackType] = useState(pack.type);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSaveType = async (newValue) => {
    try {
      const response = await axiosInstance.post("/api/packs/edit", {
        id: selectedPack.id,
        newValues: {
          type: newValue,
        },
      });
      updatePack(selectedPack.id, response.data.pack);
      setIsError(false);
      setErrorMessage("");
      setOpen(false);
    } catch (error) {
      console.error("Error:", error);
      setIsError(true);
      setErrorMessage(error.response.data.msg);
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-100"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-100"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                      <button
                        type="button"
                        className="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={() => setOpen(false)}
                      >
                        <span className="absolute -inset-2.5" />
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="h-full w-screen max-w-md overflow-y-auto bg-paper p-8">
                    <div className="space-y-6 pb-16">
                      <h2 className="text-2xl font-bold text-gray-900">
                        Pack Type
                      </h2>
                      <RadioGroup
                        value={newPackType}
                        onChange={(value) => handleSaveType(value)}
                        className="my-6"
                      >
                        <RadioGroup.Label className="sr-only">
                          Choose a type of pack
                        </RadioGroup.Label>
                        <div className="flex flex-col gap-3">
                          {packTypeOptions.map((type) => (
                            <RadioGroup.Option
                              key={type.value}
                              value={type.value}
                              className={({ active }) =>
                                classNames(
                                  active
                                    ? "ring-2 ring-neon-400 ring-offset-2"
                                    : "",
                                  pack.type === type.value
                                    ? "bg-neon-400 text-white hover:bg-neon-300"
                                    : "bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50",
                                  "flex items-center justify-center rounded-md px-3 py-4 text-sm font-semibold uppercase sm:flex-1",
                                  "cursor-pointer focus:outline-none",
                                )
                              }
                            >
                              <RadioGroup.Label as="span">
                                {type.icon} {type.label}
                              </RadioGroup.Label>
                            </RadioGroup.Option>
                          ))}
                          {isError && (
                            <div className="mb-4 rounded-md text-sm text-red-600">
                              <ExclamationCircleIcon className="mr-2 inline-block h-5 w-5" />

                              {errorMessage}
                            </div>
                          )}
                        </div>
                      </RadioGroup>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
