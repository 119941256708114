import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Input,
  RadioGroup,
  Radio,
  Label,
} from "@headlessui/react";
import { SparklesIcon, CheckCircleIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import axiosInstance from "../../axiosInstance";
import { PacksContext } from "../../context/PacksContext";
import { packTypeOptions } from "../../utils";
import AutoResizingTextarea from "../ui/AutoResizingTextarea/AutoResizingTextarea";

const NewPackDialog = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const { updatePack } = useContext(PacksContext);
  const [dialogPage, setDialogPage] = useState(1);
  const [packType, setPackType] = useState("");
  const [packName, setPackName] = useState("");
  const [packDescription, setPackDescription] = useState("");

  // Save pack
  const handleSave = async () => {
    try {
      let newPack = {
        name: packName,
        description: packDescription,
        type: packType,
      };
      const response = await axiosInstance.post("/api/packs/create", newPack);
      updatePack(response.data.pack.id, response.data.pack);
      navigate(`/packs/${response.data.pack.id}/questionnaire`);
      onClose();
    } catch (error) {
      // Handle any errors
      console.error("Error creating pack:", error);
    }
  };

  useEffect(() => {
    // Reset dialog state when dialog is closed
    if (!isOpen) {
      setDialogPage(1);
      setPackType("");
      setPackName("");
      setPackDescription("");
    }
  }, [isOpen]);

  const renderTypeSelection = () => {
    return (
      <RadioGroup value={packType} onChange={setPackType} className="my-2">
        <Label className="sr-only">Choose a type of pack</Label>
        <Label className="text-sm font-semibold text-gray-900">
          What type of pack are you creating?
        </Label>
        <div className="relative my-3 grid grid-cols-2 gap-2">
          {packTypeOptions.map((type) => (
            <Radio
              key={type.value}
              value={type.value}
              className={
                "group cursor-pointer rounded-md bg-white/5 px-5 py-4 text-gray-900 shadow-sm ring-1 ring-gray-200 transition focus:outline-none data-[checked]:bg-neon-500/10 data-[focus]:outline-1 data-[focus]:outline-neon-500 data-[checked]:ring-neon-200"
              }
            >
              <div className="flex w-full items-center justify-between">
                <div className="flex space-x-2">
                  {(() => {
                    let Icon = type.heroIcon;
                    return <Icon className="h-5 w-5 text-gray-700" />;
                  })()}
                  <div className="text-sm font-semibold">{type.label}</div>
                </div>
                <div>
                  <CheckCircleIcon className="[active]:opacity-100 size-6 text-neon-500 opacity-0 transition group-data-[checked]:opacity-100" />
                </div>
              </div>
            </Radio>
          ))}
        </div>
      </RadioGroup>
    );
  };

  const renderNameSelection = () => {
    return (
      <div className="my-2">
        <div className="text-sm font-semibold text-gray-900">
          What should we call it?
        </div>
        <Input
          type="text"
          placeholder={(() => {
            if (packType === "product") {
              return "eg: Virtual Card";
            } else if (packType === "feature") {
              return "eg: Invoices";
            } else if (packType === "supplier") {
              return "eg: AWS";
            } else if (packType === "geography") {
              return "eg: France";
            } else if (packType === "other") {
              return "eg: New Fincrime Rules";
            } else {
              return "My new pack";
            }
          })()}
          value={packName}
          onChange={(e) => setPackName(e.target.value)}
          className={clsx(
            "my-3 block w-full rounded-md border-none px-3 py-1.5 text-sm/6 text-gray-900 shadow-sm ring-1 ring-gray-200 focus:outline-none focus:ring-2 focus:ring-neon-400 focus:ring-offset-2 focus:ring-offset-gray-300",
            "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-black/25",
          )}
        />
      </div>
    );
  };

  const renderDescriptionSelection = () => {
    return (
      <div className="my-2">
        <div className="text-sm font-semibold text-gray-900">
          Briefly describe what this pack is for
        </div>
        <AutoResizingTextarea
          value={packDescription}
          onChange={setPackDescription}
          placeholder={"Short description..."}
          className={clsx(
            "my-3 block w-full rounded-md border-none px-3 py-1.5 text-sm/6 text-gray-900 shadow-sm ring-1 ring-gray-200 focus:outline-none focus:ring-2 focus:ring-neon-400 focus:ring-offset-2 focus:ring-offset-gray-300",
            "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-black/25",
          )}
        />
      </div>
    );
  };

  const canUserClickNext = () => {
    if (dialogPage === 1) {
      return packType !== "";
    } else if (dialogPage === 2) {
      return packName !== "";
    } else if (dialogPage === 3) {
      return packDescription !== "";
    }
  };

  const renderPageContent = () => {
    if (dialogPage === 1) {
      return renderTypeSelection();
    } else if (dialogPage === 2) {
      return renderNameSelection();
    } else if (dialogPage === 3) {
      return renderDescriptionSelection();
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
          <DialogTitle
            as="h3"
            className="flex items-center space-x-2 text-base font-semibold leading-6 text-gray-900"
          >
            <SparklesIcon
              className="h-5 w-5 text-gray-600"
              aria-hidden="true"
            />
            <span className="ml-1">Create Pack</span>
          </DialogTitle>
          {renderPageContent()}
          <div className="my-2 flex justify-end space-x-2">
            <button
              className={clsx(
                "w-full rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50",
              )}
              onClick={() => {
                if (dialogPage === 1) {
                  onClose(false);
                } else {
                  setDialogPage(dialogPage - 1);
                }
              }}
            >
              {dialogPage === 1 ? "Cancel" : "Back"}
            </button>
            <button
              className={clsx(
                canUserClickNext()
                  ? "opacity-100"
                  : "cursor-default opacity-50",
                "w-full rounded-md bg-neon-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-neon-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-neon-600",
              )}
              onClick={() => {
                if (dialogPage === 3) {
                  canUserClickNext() && handleSave();
                } else {
                  canUserClickNext() && setDialogPage(dialogPage + 1);
                }
              }}
            >
              {dialogPage === 3 ? "Create" : "Next"}
            </button>
          </div>
          <div className="mt-3 flex justify-center space-x-2">
            <div
              className={`size-3 rounded-full ${dialogPage >= 1 ? "bg-neon-500" : "bg-gray-300"}`}
            ></div>
            <div
              className={`size-3 rounded-full ${dialogPage >= 2 ? "bg-neon-500" : "bg-gray-300"}`}
            ></div>
            <div
              className={`size-3 rounded-full ${dialogPage >= 3 ? "bg-neon-500" : "bg-gray-300"}`}
            ></div>
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  );
};

export default NewPackDialog;
