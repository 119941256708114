import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { PacksContext } from "../../context/PacksContext";
import axiosInstance from "../../axiosInstance";
import {
  ArrowRightCircleIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/outline";

const PackTasks = () => {
  const navigate = useNavigate();
  const { packId } = useParams();
  const { packs } = useContext(PacksContext);
  const [tasks, setTasks] = useState([]);

  const fetchPackTasks = async () => {
    try {
      const response = await axiosInstance.get("/api/todos/pack", {
        params: { packId: packId },
      });
      setTasks(response.data.tasks);
    } catch (error) {
      console.error("Error fetching tasks:", error);
    } finally {
      // if (isMounted) setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPackTasks();
  }, [packId]);

  return (
    <div className="space-y-4 rounded-xl bg-paper p-6 shadow ring-1 ring-paper-darker">
      <h2 className="text-xl font-semibold leading-7 text-gray-900">Tasks</h2>
      <div className="space-y-1">
        {tasks.length > 0 ? (
          tasks.map((task, index) => (
            <div
              key={index}
              className="items group flex cursor-pointer space-x-2"
              onClick={() => {
                console.log("Task clicked:", task);
                navigate(`${task.url}`);
              }}
            >
              <div className="py-1">
                <ArrowRightCircleIcon className="h-4 w-4 text-gray-700 group-hover:text-neon-500" />
              </div>
              <span
                className={
                  "text-sm font-light text-gray-900 group-hover:text-neon-500"
                }
              >
                {task.title}
              </span>
            </div>
          ))
        ) : (
          <div className="flex items-center space-x-2 text-sm">
            <CheckCircleIcon className="h-4 w-4 text-green-600" />
            <span className="text-gray-500">No pending tasks</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default PackTasks;
