import React, { useState } from "react";
import ViewCondition from "./ViewCondition";
import CreateCondition from "./CreateCondition";

const ConditionCard = ({
  topics,
  setTopics,
  questionId,
  conditionScope,
  condition,
}) => {
  const [editMode, setEditMode] = useState(false);

  return (
    <div className="my-3 flex flex-col rounded-md bg-paper-darker px-5 py-8">
      {editMode ? (
        <CreateCondition
          topics={topics}
          setTopics={setTopics}
          questionId={questionId}
          scope={conditionScope}
          condition={condition}
          setIsVisible={setEditMode}
        />
      ) : (
        <ViewCondition
          conditionScope={conditionScope}
          condition={condition}
          setEditMode={setEditMode}
        />
      )}
    </div>
  );
};

export default ConditionCard;
