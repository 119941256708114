import React, { createContext, useState, useEffect } from "react";
import { bugsnag } from "../bugsnagUtils";
import axiosInstance from "../axiosInstance";
import AuthContext from "./AuthContext";

export const PacksContext = createContext();

export const PacksProvider = ({ children }) => {
  const [packs, setPacks] = useState([]);
  const [selectedPack, setSelectedPack] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { isAuthenticated } = React.useContext(AuthContext);

  useEffect(() => {
    // Get the packs list from the API if its zero length
    if (packs.length === 0 && isAuthenticated) {
      fetchPacksList();
    }
  }, [packs, isAuthenticated]);

  const fetchPacksList = async () => {
    // console.log("Fetching packs list in PacksContext");
    setIsLoading(true);
    try {
      const response = await axiosInstance.get("/api/packs/list");
      setPacks(response.data);
    } catch (err) {
      setError(err.message);
      bugsnag.notify(new Error("Can't fetch packs"));
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSinglePack = async (packId) => {
    // setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/api/packs/${packId}`);
      setPacks((packs) => ({
        ...packs,
        [packId]: { ...packs[packId], risk: response.data.risk },
      }));
    } catch (err) {
      setError(err.message);
    } finally {
      // setIsLoading(false);
    }
  };

  const fetchPackModules = async (packId) => {
    console.log("Fetching pack modules in PacksContext");
    setIsLoading(true);
    try {
      let response = await axiosInstance.get(`/api/packs/${packId}/modules`);
      setPacks((packs) => ({
        ...packs,
        [packId]: { ...packs[packId], modules: response.data },
      }));
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const selectPack = (packId, packName) => {
    setSelectedPack({ id: packId, name: packName });
  };

  const updatePack = (packId, updatedPack) => {
    setPacks((packs) => ({
      ...packs,
      [packId]: { ...packs[packId], ...updatedPack },
    }));
  };

  const removePack = (packId) => {
    let newPacks = { ...packs };
    delete newPacks[packId];
    setPacks(newPacks);
  };

  const updateModule = ({ packId, moduleId, details }) => {
    // Note: this may be called during the initial questionnaire, at which point we don't care about keeping the "module" up to date on the client side as it's not shown to the user in that abstraction.
    let updatedModules = packs[packId].modules?.map((module) => {
      return module.id === moduleId ? { ...module, ...details } : module;
    });
    updatePack(packId, { ...packs[packId], modules: updatedModules });
  };

  const value = {
    packs,
    selectedPack,
    isLoading,
    error,
    fetchPacksList,
    fetchSinglePack,
    fetchPackModules,
    selectPack,
    updatePack,
    removePack,
    updateModule,
  };

  return (
    <PacksContext.Provider value={value}>{children}</PacksContext.Provider>
  );
};
