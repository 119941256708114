import React from "react";
import "./Login.css";
import slackLogo from "./slackLogo.png";
import packLogo from "./logo64.png";

const Login = () => {
  const slackClientId = process.env.REACT_APP_SLACK_CLIENT_ID;
  const slackRedirectUri = process.env.REACT_APP_SLACK_REDIRECT_URI;
  const slackUrl = `https://slack.com/openid/connect/authorize?client_id=${slackClientId}&redirect_uri=${slackRedirectUri}&response_type=code&scope=openid email profile`;

  return (
    <div className="login">
      <div className="content-area">
        <div className="header">
          <img src={packLogo} alt="App Logo" className="app-logo" />
          <div className="title">Hello</div>
        </div>
        <div className="body">
          <div className="subtitle">Welcome to Product Pack</div>
          <div className="text">
            We're helping fintech startups improve their compliance processes
            with modern Product Governance tooling.
          </div>
        </div>
        <a href={slackUrl} className="slack-button">
          <img className="slack-icon" src={slackLogo} alt="Slack Logo" />
          Sign in with Slack
        </a>
        <div className="text">
          Or learn more at <a href="https://productpack.io">productpack.io</a>
        </div>
      </div>
    </div>
  );
};

export default Login;
