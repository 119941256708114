import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect } from "react";
import "./ReportModal.css";

const ModuleSection = ({ title, risk, status, content }) => {
  const riskLabel = {
    1: "Low Risk",
    2: "Medium Risk",
    3: "High Risk",
  };
  const riskClass = {
    1: "pill-green",
    2: "pill-yellow",
    3: "pill-red",
  };
  const statusLabel = {
    0: "Not Started",
    1: "Draft",
    2: "Pending Review",
    3: "Approved",
    4: "Rejected",
    5: "Pending Review",
  };
  const statusClass = {
    0: "pill-grey",
    1: "pill-grey",
    2: "pill-blue",
    3: "pill-green",
    4: "pill-red",
    5: "pill-blue",
  };
  return (
    <div className="text-section">
      <div className="module-details">
        <h3>{title}</h3>
        {risk && (
          <span className={`pill ${riskClass[risk]}`}>{riskLabel[risk]}</span>
        )}
      </div>
    </div>
  );
};

const RiskSection = ({ title, content }) => {
  const contentToEmoji = {
    1: "🟢 Low Risk",
    2: "🟠 Medium Risk",
    3: "🔴 High Risk",
  };

  return content ? (
    <div className="text-section">
      <p>{contentToEmoji[content]}</p>
    </div>
  ) : null;
};

const TextSection = ({ title, content }) =>
  content ? (
    <div className="text-section">
      <h4>{title}</h4>
      <p>{content}</p>
    </div>
  ) : null;

const TableSection = ({ headers, rows }) => (
  <table>
    <thead>
      <tr>
        {headers.map((header, index) => (
          <th key={index}>{header}</th>
        ))}
      </tr>
    </thead>
    <tbody>
      {rows.map((row, rowIndex) => (
        <tr key={rowIndex}>
          {row.map((cell, cellIndex) => (
            <td key={cellIndex}>{cell}</td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
);

const ReportModal = ({ packName, report, isOpen, onClose }) => {
  // Function to stop the click event from propagating to the overlay
  const handleModalContentClick = (event) => {
    event.stopPropagation();
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      const { key, target } = event;

      // Check if ESC is pressed
      if (key === "Escape") {
        // If a textarea is focused, blur it
        if (target.tagName === "TEXTAREA") {
          target.blur();
        } else {
          // If no textarea is focused, close the modal
          onClose();
        }
      }
    };

    // Add event listener when the modal is open
    if (isOpen) {
      document.addEventListener("keydown", handleKeyPress);
    }

    // Clean up the event listener when the modal is closed or component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [isOpen, onClose, report]);

  return (
    <div className="modal-background" onClick={onClose}>
      <div className="modal" onClick={handleModalContentClick}>
        <div className="modal-header">
          <button className="button" onClick={onClose}>
            Close
          </button>
          <h3>{report.title}</h3>
        </div>
        <div className="modal-body">
          <div className="modal-content">
            <div className="header">
              <h2>{packName}</h2>
            </div>
            <div className="body">
              {report?.sections?.map((section, index) => {
                switch (section.type) {
                  case "text":
                    return <TextSection key={index} {...section} />;
                  case "module":
                    return <ModuleSection key={index} {...section} />;
                  case "assessment":
                    return <TextSection key={index} {...section} />;
                  case "ongoingMonitoring":
                    return <TextSection key={index} {...section} />;
                  // case 'risk':
                  // return <RiskSection key={index} {...section} />;
                  case "table":
                    return <TableSection key={index} {...section} />;
                  default:
                    return null;
                }
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportModal;
