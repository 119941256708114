import axios from "axios";
import Cookies from "js-cookie";

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
  validateStatus: function (status) {
    // return status >= 200 && status < 500;
    return status >= 200 && status < 300; // Only consider 2xx status codes as successful
  },
});

// Add a request interceptor to the Axios instance
axiosInstance.interceptors.request.use(
  (config) => {
    const csrfToken = Cookies.get(process.env.REACT_APP_CSRF_TOKEN_NAME);
    if (csrfToken) {
      config.headers["X-CSRF-TOKEN"] = csrfToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default axiosInstance;
