import { XMarkIcon } from "@heroicons/react/24/outline";
import axiosInstance from "../../axiosInstance";
import React, { Fragment, useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import LeftPane from "./LeftPane";
import RightPane from "./RightPane";
import QuestionnairePane from "../InitialQuestionnaire/Qandas/QuestionnairePane";
import { PacksContext } from "../../context/PacksContext";
import { ApprovalType } from "../../constants";

const ModuleModal = ({ moduleId, isOpen, setIsOpen }) => {
  const { packId } = useParams();
  const { selectedPack, packs, updateModule } = useContext(PacksContext);
  const [qandas, setQandas] = useState([]);
  const [loadingQandas, setLoadingQandas] = useState(false);
  const [showLeftPane, setShowLeftPane] = useState(false);
  const [leftpaneViewMode, setLeftPaneViewMode] = useState("");
  const [selectedItem, setSelectedItem] = useState(null); // Qanda or Approval
  const [currentModule, setCurrentModule] = useState(null);

  // Item selection router
  useEffect(() => {
    // When modal is open, pick unanswered question
    // If risk is uanswered, pick risk
    // If risk is answered, pick next unanswered question
    // If all questions are answered, show placeholder
    // When question is answered, pick next unanswered question
    // When question changes to NA don't pick next question
    // When question is answered, refresh currently selected question
    let unansweredQuestions = qandas.filter(
      (qanda) => qanda.answer === null && !qanda.isNa && qanda.required,
    );

    if (unansweredQuestions.length > 0) {
      setLeftPaneViewMode("qandas");
      setShowLeftPane(true);
    } else {
      setShowLeftPane(false);
      setSelectedItem({});
    }
    if (
      selectedItem !== null &&
      selectedItem !== undefined &&
      selectedItem.moduleId === moduleId
    ) {
      // The user answered something
      if (selectedItem.type === ApprovalType.ENDORSEMENT) {
        setShowLeftPane(false);
      } else {
        // A qanda was answered
        let module = packs[packId]?.modules?.find((m) => m.id === moduleId);
        setCurrentModule(module);
        let selectedQanda = qandas.find(
          (qanda) => qanda.questionId === selectedItem.questionId,
        );
        if (selectedQanda?.isNa != selectedItem.isNa) {
          // NA status changed, we don't want to pick next question
          setSelectedItem(selectedQanda);
        } else {
          // Answered question, pick next unanswered question
          if (unansweredQuestions.length > 0) {
            setSelectedItem(unansweredQuestions[0]);
            // setLeftPaneViewMode("qandas");
          } else {
            // All questions answered
            setShowLeftPane(false);
          }
        }
      }
    } else {
      // The user opened the modal
      if (unansweredQuestions.length > 0) {
        setSelectedItem(unansweredQuestions[0]);
        // setLeftPaneViewMode("qandas");
      } else {
        // All questions answered
        // setShowLeftPane(false);
      }
    }
  }, [qandas, currentModule]);

  // Fetch qandas
  useEffect(() => {
    let module = packs[packId]?.modules?.find((m) => m.id === moduleId);
    setCurrentModule(module);
    const fetchQuestions = async () => {
      try {
        const response = await axiosInstance.get("/api/qandas/list", {
          params: {
            packId: packId,
            moduleId: module ? module.id : null,
          },
        });
        setQandas(response.data);
      } catch (error) {
        // if (isMounted) setError(error.message);
        console.error("Error fetching qandas:", error);
      } finally {
        // if (isMounted) setIsLoading(false);
      }
    };
    isOpen === true && fetchQuestions();
    // return () => { isMounted = false }; // Cleanup function to prevent memory leaks
  }, [isOpen]);

  const handleSaveModule = async (updatedModule) => {
    // Saves module specific content like notes and draft status.
    try {
      const response = await axiosInstance.post(
        "/api/modules/edit",
        updatedModule,
      );
      setCurrentModule(response.data.module);
      updateModule({
        packId: selectedPack.id,
        moduleId: updatedModule.id,
        details: response.data.module,
      });
      // if (didRiskChange) {
      //   // The risk changed so we'll fetch the packs again to get the latest risk
      //   fetchSinglePack(packId);
      //   updatePack(packId, { ...packs[packId], modules: updatedModules });
      // } else {
      //   // The risk didn't change so we'll just update the modules locally
      //   updatePack(packId, { ...packs[packId], modules: updatedModules });
      // }
    } catch (error) {
      console.error("Error updating module:", error);
    }
  };

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setIsOpen}>
        <TransitionChild
          as={Fragment}
          enter="ease-in-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
              <TransitionChild
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-100"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-100"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <DialogPanel className="pointer-events-auto">
                  <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                    <button
                      type="button"
                      className="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                      onClick={() => setIsOpen(false)}
                    >
                      <span className="absolute -inset-2.5" />
                      <span className="sr-only">Close panel</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="h-full overflow-y-scroll bg-paper shadow-xl">
                    <div className="divide flex h-full divide-x divide-gray-300">
                      {showLeftPane && (
                        <LeftPane
                          currentModule={currentModule}
                          setCurrentModule={setCurrentModule}
                          qandas={qandas}
                          setQandas={setQandas}
                          viewMode={leftpaneViewMode}
                          selectedItem={selectedItem}
                          setSelectedItem={setSelectedItem}
                          setShowLeftPane={setShowLeftPane}
                          handleSaveModule={handleSaveModule}
                        />
                      )}
                      <div className="flex h-full w-screen max-w-md">
                        {moduleId && currentModule ? (
                          <RightPane
                            moduleId={moduleId}
                            currentModule={currentModule}
                            setCurrentModule={setCurrentModule}
                            qandas={qandas}
                            setLeftPaneViewMode={setLeftPaneViewMode}
                            setShowLeftPane={setShowLeftPane}
                            selectedItem={selectedItem}
                            setSelectedItem={setSelectedItem}
                            handleSaveModule={handleSaveModule}
                          />
                        ) : (
                          <QuestionnairePane
                            qandas={qandas}
                            isOpen={isOpen}
                            setLeftPaneViewMode={setLeftPaneViewMode}
                            setShowLeftPane={setShowLeftPane}
                            selectedItem={selectedItem}
                            setSelectedItem={setSelectedItem}
                            setIsOpen={setIsOpen}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ModuleModal;
