import React, { useEffect, useState, useContext } from "react";
import axiosInstance from "../../axiosInstance";
import { PacksContext } from "../../context/PacksContext";
import TextAnswerInput from "./QuestionArea/TextAnswerInput";
import OptionsInput from "./QuestionArea/OptionsInput";
import BooleanInput from "./QuestionArea/BooleanInput";

const EditQanda = ({ selectedItem, qandas, setQandas }) => {
  const { packs, updateModule, updatePack } = useContext(PacksContext);
  const [isNa, setIsNa] = useState(selectedItem.isNa);
  useEffect(() => {
    setIsNa(selectedItem.isNa || "");
  }, [selectedItem]);

  const handleSaveAnswer = async ({ item, answer, isNa }) => {
    try {
      let updatedAnswer = { ...item };
      if (answer !== undefined) updatedAnswer.answer = answer;
      if (isNa !== undefined) updatedAnswer.isNa = isNa;
      const response = await axiosInstance.post(
        "/api/qandas/submit",
        updatedAnswer,
      );

      setQandas(response.data.qandas);
      updateModule({
        packId: response.data.module.packId,
        moduleId: response.data.module.id,
        details: response.data.module,
      });
      // If pack risk is updated, update the pack risk in the pack context
      if (response.data.packRisk != packs[response.data.module.packId].risk) {
        let updatedPack = {
          risk: response.data.packRisk,
        };
        updatePack(response.data.module.packId, updatedPack);
      }
    } catch (error) {
      console.error("Error updating answer:", error);
    }
  };

  const renderAnswerInput = () => {
    switch (selectedItem.type) {
      case "text":
        return (
          <TextAnswerInput
            selectedItem={selectedItem}
            handleSaveAnswer={handleSaveAnswer}
          />
        );
      case "text_options":
        return (
          <OptionsInput
            selectedItem={selectedItem}
            handleSaveAnswer={handleSaveAnswer}
          />
        );
      case "int_options":
        return (
          <OptionsInput
            selectedItem={selectedItem}
            handleSaveAnswer={handleSaveAnswer}
          />
        );
      case "boolean":
        return (
          <BooleanInput
            selectedItem={selectedItem}
            handleSaveAnswer={handleSaveAnswer}
          />
        );
      default:
        return null;
    }
  };

  const renderNaOption = () => {
    if (selectedItem.code === "risk") {
      return (
        <>
          <input
            checked={isNa}
            onChange={(e) => {
              setIsNa(e.target.checked);
              handleSaveAnswer({ item: selectedItem, isNa: e.target.checked });
            }}
            id="checked-checkbox"
            type="checkbox"
            value=""
            className="mt-4 h-4 w-4 rounded border-gray-300 bg-gray-100 text-neon-600 focus:ring-2 focus:ring-neon-500"
          />
          <label className="ms-2 text-sm font-medium text-gray-700">
            Risk not applicable
          </label>
        </>
      );
    }
  };

  return (
    <div className="mx-3 rounded-xl bg-paper-dark p-10">
      <h2 className="text-left text-lg font-semibold text-gray-800">
        {selectedItem.question}
        {selectedItem.type == "review" && "What's your review of this module?"}
      </h2>
      <p className="mt-2 text-sm text-gray-800">{selectedItem.description}</p>
      {!selectedItem.isNa && renderAnswerInput()}
      {renderNaOption()}
    </div>
  );
};

export default EditQanda;
