import React, { useState, useEffect } from "react";
import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/20/solid";
import FilterDropdown from "./FilterDropdown";
import {
  packTypeOptions,
  packRiskOptions,
  packLifecycleOptions,
  packApprovalOptions,
} from "../../../utils";

const PackFilters = ({ packsArray, setFilteredPacks }) => {
  const [searchFilter, setSearchFilter] = useState("");
  const [lifecycleFilter, setLifecycleFilter] = useState("all");
  const [typeFilter, setTypeFilter] = useState("all");
  const [riskFilter, setRiskFilter] = useState("all");
  const [approvalFilter, setApprovalFilter] = useState("all");
  const [activeFilters, setActiveFilters] = useState(false);

  useEffect(() => {
    const applyFilters = () => {
      let result = packsArray;

      if (lifecycleFilter !== "all") {
        result = result.filter(
          (pack) => pack.lifecycle === parseInt(lifecycleFilter),
        );
        setActiveFilters(true);
      }

      if (typeFilter !== "all") {
        result = result.filter((pack) => pack.type === typeFilter);
        setActiveFilters(true);
      }

      if (riskFilter !== "all") {
        result = result.filter((pack) => pack.risk === parseInt(riskFilter));
        setActiveFilters(true);
      }

      if (approvalFilter !== "all") {
        result = result.filter(
          (pack) => pack.approvalStatus === parseInt(approvalFilter),
        );
        setActiveFilters(true);
      }

      if (searchFilter !== "") {
        result = result.filter((pack) =>
          pack.name.toLowerCase().includes(searchFilter.toLowerCase()),
        );
        setActiveFilters(true);
      }

      result.sort((a, b) => {
        if (a.updatedOn > b.updatedOn) {
          return -1;
        }
        if (a.updatedOn < b.updatedOn) {
          return 1;
        }
        return 0;
      });
      setFilteredPacks(result);
      if (
        lifecycleFilter === "all" &&
        typeFilter === "all" &&
        riskFilter === "all" &&
        approvalFilter === "all" &&
        searchFilter === ""
      ) {
        setActiveFilters(false);
      }
    };
    applyFilters();
  }, [
    lifecycleFilter,
    typeFilter,
    riskFilter,
    searchFilter,
    approvalFilter,
    packsArray,
  ]);

  const resetFilters = () => {
    setLifecycleFilter("all");
    setTypeFilter("all");
    setRiskFilter("all");
    setApprovalFilter("all");
    setSearchFilter("");
  };

  return (
    <div className="flex">
      {activeFilters && (
        <button
          onClick={resetFilters}
          type="button"
          className="mx-4 rounded-full bg-red-500 px-4 py-2.5 text-sm font-semibold text-white hover:bg-red-400"
        >
          <span className="ml-1">Reset Filters</span>
        </button>
      )}

      <FilterDropdown
        filterState={lifecycleFilter}
        setFilterState={setLifecycleFilter}
        baseOption={{ value: "all", label: "All Lifecycles" }}
        options={packLifecycleOptions}
      />

      <FilterDropdown
        filterState={typeFilter}
        setFilterState={setTypeFilter}
        baseOption={{ value: "all", label: "All Types" }}
        options={packTypeOptions}
      />

      <FilterDropdown
        filterState={riskFilter}
        setFilterState={setRiskFilter}
        baseOption={{ value: "all", label: "All Risks" }}
        options={packRiskOptions.filter((option) => option.value !== null)}
      />

      <FilterDropdown
        filterState={approvalFilter}
        setFilterState={setApprovalFilter}
        baseOption={{ value: "all", label: "All Approvals" }}
        options={packApprovalOptions}
      />

      <div className="relative text-gray-400 focus-within:text-gray-600">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
        </div>
        <input
          id="search"
          className="block w-full rounded-full border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600 sm:text-sm sm:leading-6"
          placeholder="Search"
          value={searchFilter}
          onChange={(e) => setSearchFilter(e.target.value)}
          type="search"
          name="search"
        />
        {searchFilter && (
          <button
            type="button"
            className="absolute inset-y-0 right-0 flex items-center pr-3"
            onClick={() => setSearchFilter("")}
          >
            <XMarkIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        )}
      </div>
    </div>
  );
};

export default PackFilters;
