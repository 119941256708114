import React, { useMemo } from "react";
import { useTable, useSortBy } from "react-table";
import {
  moduleStatusOptions,
  draftStatusOptions,
  classNames,
  packRiskOptions,
} from "../../utils";
import { ModuleStatus } from "../../constants";

const ModulesTable = ({ modules, handleModuleClicked }) => {
  const data = useMemo(() => [...modules], [modules]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        sortable: true,
        Cell: ({ cell: { value } }) => (
          <span className="text-base font-semibold text-gray-700">{value}</span>
        ),
      },
      {
        Header: "Stakeholders",
        accessor: "approvals",
        Cell: ({ cell: { value } }) => (
          <div className="flex">
            {value.map((approval) => (
              <div key={approval.id} className="mr-1 h-8 w-8 flex-shrink-0">
                <img
                  className="h-8 w-8 rounded-full"
                  src={approval.user.image_512}
                  title={approval.user.name}
                  alt="stakeholder profile picture"
                />
              </div>
            ))}
          </div>
        ),
      },
      {
        Header: "Risk",
        accessor: "risk",
        Cell: ({ cell: { value } }) => {
          return (
            <span
              className={classNames(
                value === 1 && "text-green-700",
                value === 2 && "text-yellow-600",
                value === 3 && "text-red-700",
                "text-sm font-semibold text-gray-700",
              )}
            >
              {packRiskOptions.find((option) => option.value === value).label}
            </span>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          let draftStatus = original.draftStatus;
          let moduleStatus = original.status;
          return (
            <span
              className={classNames(
                "inline-flex items-center rounded-full px-3 py-1 text-sm font-semibold ring-1 ring-inset",
                moduleStatus === ModuleStatus.EMPTY &&
                  "bg-gray-50 text-gray-600 ring-gray-500/10",
                moduleStatus === ModuleStatus.DRAFT &&
                  "bg-blue-50 text-blue-600 ring-blue-500/10",
                moduleStatus === ModuleStatus.SUBMITTED_REVIEWED &&
                  "bg-green-50 text-green-600 ring-green-500/10",
              )}
            >
              {moduleStatusOptions[moduleStatus]}
              {moduleStatus === ModuleStatus.DRAFT && (
                <span className="ml-2 text-xs text-gray-400">
                  {draftStatusOptions[draftStatus]}
                </span>
              )}
            </span>
          );
        },
      },
      {
        Header: "Last updated",
        accessor: "updatedOn",
        Cell: ({ cell: { value } }) => {
          const date = new Date(value);
          const friendlyDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
          return <span>{friendlyDate}</span>;
        },
      },
    ],
    [],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);
  return (
    <div className="mt-6 rounded-xl bg-white shadow">
      {/* <h2 className="text-xl font-semibold text-gray-900">Modules</h2> */}
      <table
        {...getTableProps()}
        className="min-w-full divide-y divide-gray-300"
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className="px-4 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
                >
                  {column.render("Header")}
                  {column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="divide-y divide-gray-200">
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className="cursor-pointer hover:bg-gray-50"
                onClick={() => handleModuleClicked(row.original.id)}
              >
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-900"
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ModulesTable;
