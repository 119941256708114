import React, { useState, useEffect, useContext } from "react";
import axiosInstance from "../../axiosInstance";
import { PacksContext } from "../../context/PacksContext";
import { packTypeOptions, vendorTypeOptions } from "../../utils";
import "./NewPack.css";
import { useNavigate } from "react-router-dom";

const NewPack = ({ isOpen, title, onClose, onDelete, onSave }) => {
  const navigate = useNavigate();
  const { updatePack } = useContext(PacksContext);
  const [modalStep, setModalStep] = useState("packType");
  const [packType, setPackType] = useState("");
  const [packName, setPackName] = useState("");
  const [packDescription, setPackDescription] = useState("");
  const [vendorType, setVendorType] = useState("");
  const [modules, setModules] = useState([]);
  const [nextButtonDisabled, setNextButtonDisabled] = useState(true);

  useEffect(() => {
    // Fetch all modules and their status for the pack
    const fetchTopics = async () => {
      try {
        const response = await axiosInstance.get(`/api/topics/list`);

        let modules = response.data.map((topic) => {
          let module = { topicId: topic.id, name: topic.name };
          module.active = topic.defaultTypes.includes(packType);
          return module;
        });
        setModules(modules);
      } catch (error) {
        console.error("Error fetching topics:", error);
      }
    };
    fetchTopics();
  }, [packType]);

  const handleModalContentClick = (event) => {
    event.stopPropagation();
  };

  // Answer Pack Type question
  const handleAnswerPackType = (type) => {
    setPackType(type);
    if (type === "supplier") {
      setModalStep("supplierName");
    } else {
      setModalStep("packName");
    }
  };

  // Answer Pack Name question
  const handlePackNameChange = (name) => {
    setPackName(name);
    // If the packName is longer than 3 characters, enable the next button
    if (name.length > 2) {
      setNextButtonDisabled(false);
    } else {
      setNextButtonDisabled(true);
    }
  };
  // Save Pack Name question
  const handlePackNameSave = () => {
    setNextButtonDisabled(true);
    if (packType === "supplier") {
      setModalStep("supplierDescription");
    } else {
      setModalStep("packDescription");
    }
  };

  // Answer Pack Description question
  const handlePackDescriptionChange = (description) => {
    setPackDescription(description);
    if (description.length > 2) {
      setNextButtonDisabled(false);
    } else {
      setNextButtonDisabled(true);
    }
  };
  // Save Pack Description question
  const handlePackDescriptionSave = () => {
    setNextButtonDisabled(false);
    setModalStep("packModules");
  };

  // Answer Pack Modules question
  const handlePackModulesChange = (topicId) => {
    const updatedModules = modules.map((module) => {
      if (module.topicId === topicId) {
        module.active = !module.active;
      }
      return module;
    });
    setModules(updatedModules);
  };
  // Save Pack Modules question
  const handlePackModulesSave = () => {
    if (packType === "supplier") {
      setModalStep("supplierReview");
    } else {
      setModalStep("packReview");
    }
  };

  // Answer Supplier Description question
  const handleSupplierDescriptionChange = (description) => {
    setPackDescription(description);
    if (description.length > 10) {
      setNextButtonDisabled(false);
    } else {
      setNextButtonDisabled(true);
    }
  };
  // Save Supplier Description question
  const handleSupplierDescriptionSave = () => {
    setModalStep("supplierType");
  };

  // Answer Supplier Type question
  const handleAwerSupplierType = (type) => {
    setVendorType(type);
    setNextButtonDisabled(false);
    setModalStep("packModules");
  };

  // Save pack to database
  const handleSave = async () => {
    try {
      let newPack = {
        pack_name: packName,
        pack_description: packDescription,
        pack_type: packType,
        vendor_type: vendorType,
        modules: modules
          .filter((module) => module.active)
          .map((module) => module.topicId),
      };
      const response = await axiosInstance.post("/api/packs/create", newPack);
      updatePack(response.data.pack.id, response.data.pack);
      navigate(`/packs/${response.data.pack.id}`);
      onClose();
    } catch (error) {
      // Handle any errors
      console.error("Error creating new pack:", error);
    }
  };

  const renderPackTypeStep = () => {
    return (
      <div className="modal-body">
        <div className="question">
          <h2>What are you working on?</h2>
        </div>
        <div className="content horizontal">
          <div className="grid-container">
            {packTypeOptions.map(({ value, label, icon }) => (
              <div
                key={value}
                className={`grid-item ${packType === value ? "active" : ""}`}
                onClick={() => handleAnswerPackType(value)}
              >
                <span className="icon">{icon}</span>
                <span>{label}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const renderPackNameStep = () => {
    return (
      <div className="modal-body">
        <div className="question">
          <h2>What should we call it?</h2>
        </div>
        <div className="content vertical">
          <input
            type="text"
            value={packName}
            onChange={(e) => handlePackNameChange(e.target.value)}
            placeholder="e.g.: Virtual Card"
          />
          <button
            className="button button-blue"
            onClick={handlePackNameSave}
            disabled={nextButtonDisabled}
          >
            That's it
          </button>
        </div>
      </div>
    );
  };

  const renderPackDescriptionStep = () => {
    return (
      <div className="modal-body">
        <div className="question">
          <h2>Write a short description</h2>
        </div>
        <div className="content vertical">
          <textarea
            value={packDescription}
            onChange={(e) => handlePackDescriptionChange(e.target.value)}
            placeholder="e.g.: The best new way to make payments online"
            rows={4}
          />
          <button
            className="button button-blue"
            onClick={handlePackDescriptionSave}
            disabled={nextButtonDisabled}
          >
            That's it
          </button>
        </div>
      </div>
    );
  };

  const renderPackModulesStep = () => {
    return (
      <div className="modal-body">
        <div className="question">
          <h2>Which modules should be enabled for this Pack? </h2>
          <p>You can change this later.</p>
        </div>
        <div className="content vertical">
          <div className="list-container">
            {modules &&
              modules.map(({ topicId, name, active }) => (
                <div
                  key={topicId}
                  className="list-item"
                  onClick={() => handlePackModulesChange(topicId)}
                >
                  <input
                    type="radio"
                    value={topicId}
                    checked={active}
                    onChange={handlePackModulesChange}
                  />
                  <div>{name}</div>
                </div>
              ))}
          </div>
          <button
            className="button button-blue"
            onClick={handlePackModulesSave}
            disabled={nextButtonDisabled}
          >
            Continue
          </button>
        </div>
      </div>
    );
  };

  const renderPackReviewStep = () => {
    return (
      <div className="modal-body">
        <div className="question">
          <h2>Summary</h2>
        </div>
        <div className="content vertical">
          <div className="summary-box">
            <div className="summary-header">
              <div>
                <span className="icon">
                  {packTypeOptions.find(({ value }) => value === packType).icon}
                </span>
                <span className="label">
                  {
                    packTypeOptions.find(({ value }) => value === packType)
                      .label
                  }
                </span>
              </div>
              <div>
                {/* <span className='label'>{vendorTypeOptions.find(({ value }) => value === vendorType).label}</span> */}
                {/* <span className='icon'>{vendorTypeOptions.find(({ value }) => value === vendorType).icon}</span> */}
              </div>
            </div>
            <h3>{packName}</h3>
            <p>{packDescription}</p>
            <div className="label-container">
              {modules &&
                modules
                  .filter((module) => module.active)
                  .map(({ topicId, name }) => (
                    <div key={topicId} className="label-item">
                      {name}
                    </div>
                  ))}
            </div>
          </div>
          <button className="button button-blue" onClick={handleSave}>
            Create Pack
          </button>
        </div>
      </div>
    );
  };

  const renderSupplierNameStep = () => {
    return (
      <div className="modal-body">
        <div className="question">
          <h2>What is their name?</h2>
        </div>
        <div className="content vertical">
          <input
            type="text"
            value={packName}
            onChange={(e) => handlePackNameChange(e.target.value)}
            placeholder="e.g.: Amazon Web Services"
          />
          <button
            className="button button-blue"
            onClick={handlePackNameSave}
            disabled={nextButtonDisabled}
          >
            That's it
          </button>
        </div>
      </div>
    );
  };

  const renderSupplierDescriptionStep = () => {
    return (
      <div className="modal-body">
        <div className="question">
          <h2>Describe them in your own words</h2>
        </div>
        <div className="content vertical">
          <textarea
            value={packDescription}
            onChange={(e) => handleSupplierDescriptionChange(e.target.value)}
            placeholder="e.g.: We use AWS for our cloud infrastructure"
            rows={4}
          />
          <button
            className="button button-blue"
            onClick={handleSupplierDescriptionSave}
            disabled={nextButtonDisabled}
          >
            That's it
          </button>
        </div>
      </div>
    );
  };

  const renderSupplierTypeStep = () => {
    return (
      <div className="modal-body">
        <div className="question">
          <h2>What type of Supplier is this?</h2>
        </div>
        <div className="content horizontal">
          <div className="grid-container">
            {vendorTypeOptions.map(({ value, label, icon }) => (
              <div
                key={value}
                className={`grid-item small ${packType === value ? "active" : ""}`}
                onClick={() => handleAwerSupplierType(value)}
              >
                <span className="icon">{icon}</span>
                <span className="label">{label}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const renderSupplierReviewStep = () => {
    return (
      <div className="modal-body">
        <div className="question">
          <h2>Summary</h2>
        </div>
        <div className="content vertical">
          <div className="summary-box">
            <div className="summary-header">
              <div>
                <span className="icon">
                  {packTypeOptions.find(({ value }) => value === packType).icon}
                </span>
                <span className="label">
                  {
                    packTypeOptions.find(({ value }) => value === packType)
                      .label
                  }
                </span>
              </div>
              <div>
                <span className="label">
                  {
                    vendorTypeOptions.find(({ value }) => value === vendorType)
                      .label
                  }
                </span>
                <span className="icon">
                  {
                    vendorTypeOptions.find(({ value }) => value === vendorType)
                      .icon
                  }
                </span>
              </div>
            </div>
            <h3>{packName}</h3>
            <p>{packDescription}</p>
            <div className="label-container">
              {modules &&
                modules
                  .filter((module) => module.active)
                  .map(({ topicId, name }) => (
                    <div key={topicId} className="label-item">
                      {name}
                    </div>
                  ))}
            </div>
          </div>
          <button className="button button-blue" onClick={handleSave}>
            Create Supplier Pack
          </button>
        </div>
      </div>
    );
  };

  const renderStep = () => {
    switch (modalStep) {
      case "packType":
        return renderPackTypeStep();
      case "packName":
        return renderPackNameStep();
      case "packDescription":
        return renderPackDescriptionStep();
      case "packModules":
        return renderPackModulesStep();
      case "packReview":
        return renderPackReviewStep();
      case "supplierName":
        return renderSupplierNameStep();
      case "supplierDescription":
        return renderSupplierDescriptionStep();
      case "supplierType":
        return renderSupplierTypeStep();
      case "supplierReview":
        return renderSupplierReviewStep();
    }
  };

  return (
    <div className="new-pack-modal-background" onClick={onClose}>
      <div className="new-pack-modal" onClick={handleModalContentClick}>
        <div className="modal-header">
          {onClose && (
            <button
              className="rounded-md px-4 py-2 text-sm font-semibold text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-neon-100 hover:ring-neon-400"
              onClick={onClose}
            >
              Close
            </button>
          )}
        </div>
        {renderStep()}
        <div className="modal-footer"></div>
      </div>
    </div>
  );
};

export default NewPack;
