import React, { useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import { PacksContext } from "../../context/PacksContext";
import {
  packLifecycleOptions,
  packRiskOptions,
  packTypeOptions,
  packApprovalOptions,
  vendorTypeOptions,
  vendorCostOptions,
} from "../../utils";
import {
  BanknotesIcon,
  BuildingLibraryIcon,
  BuildingOfficeIcon,
  CalendarDaysIcon,
  FlagIcon,
  GlobeAltIcon,
  LockClosedIcon,
  MapPinIcon,
  PencilSquareIcon,
  RocketLaunchIcon,
  Squares2X2Icon,
  TrophyIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { useCountries } from "../../hooks/useCountries";

const KeyInformation = ({
  setDialogContent,
  setEditDialogOpen,
  setApprovalsSlideOverOpen,
}) => {
  const { packId } = useParams();
  const { packs } = useContext(PacksContext);
  const { countries, getCountryName } = useCountries({ country: "GB" });

  const handleEditClick = ({ code, label, value, icon }) => {
    setDialogContent({
      code: code,
      label: label,
      value: value,
      icon: icon,
    });
    setEditDialogOpen(true);
  };

  const info = useMemo(() => {
    const risk = packRiskOptions.find(
      (risk) => risk.value === packs[packId]?.risk,
    );
    const lifecycle = packLifecycleOptions.find(
      (lifecycle) => lifecycle.value === packs[packId]?.lifecycle,
    );
    const approval = packApprovalOptions.find(
      (approval) => approval.value === packs[packId]?.approvalStatus,
    );
    const packType = packTypeOptions.find(
      (type) => type.value === packs[packId]?.type,
    );
    const RiskIcon = risk?.heroIcon;
    const PackTypeIcon = packType?.icon3;

    let newInfoList = [
      {
        code: "derivedRisk",
        label: "Derived Risk",
        value: risk?.label,
        icon: RiskIcon ? <RiskIcon className="h-5 w-5 text-gray-700" /> : null,
      },
      {
        code: "lifecycle",
        label: "Lifecycle",
        value: lifecycle?.label,
        icon: <RocketLaunchIcon className="h-5 w-5 text-gray-700" />,
      },
      {
        label: "Approval",
        value: approval?.label,
        icon: <TrophyIcon className="h-5 w-5 text-gray-700" />,
        func: () => setApprovalsSlideOverOpen(true),
      },
      {
        code: "owner",
        label: "Owner",
        value: packs[packId]?.owner?.name || "No owner",
        icon: <UserIcon className="h-5 w-5 text-gray-700" />,
      },
      {
        code: "type",
        label: "Type",
        value: packType?.label,
        icon: PackTypeIcon ? (
          <PackTypeIcon className="h-5 w-5 text-gray-700" />
        ) : null,
      },
    ];
    return newInfoList;
  }, [packs]);

  const supplierInfo = useMemo(() => {
    const vendorType = vendorTypeOptions.find(
      (vendor) => vendor.value === packs[packId]?.vendorType,
    );
    const cost = vendorCostOptions.find(
      (cost) => cost.value === packs[packId]?.vendorCost,
    );
    const contractSignDate =
      packs[packId]?.contractSignDate &&
      !isNaN(new Date(packs[packId].contractSignDate))
        ? new Date(packs[packId].contractSignDate).toLocaleDateString()
        : "NA";
    const contractExpiryDate =
      packs[packId]?.contractExpiryDate &&
      !isNaN(new Date(packs[packId].contractExpiryDate))
        ? new Date(packs[packId].contractExpiryDate).toLocaleDateString()
        : "NA";

    const VendorTypeIcon = vendorType?.heroIcon || Squares2X2Icon;

    let newSupplierInfoList = [
      {
        code: "supplierType",
        label: "Vendor Type",
        value: vendorType?.label || "NA",
        icon: VendorTypeIcon ? (
          <VendorTypeIcon className="h-5 w-5 text-gray-700" />
        ) : null,
      },
      {
        code: "country",
        label: "Country",
        value: getCountryName(packs[packId]?.country) || "NA",
        icon: <FlagIcon className="h-5 w-5 text-gray-700" />,
      },
      {
        code: "website",
        label: "Website",
        value: packs[packId]?.vendorWebsite || "NA",
        icon: <GlobeAltIcon className="h-5 w-5 text-gray-700" />,
      },
      {
        code: "cost",
        label: "Cost",
        value: cost?.label || "NA",
        icon: <BanknotesIcon className="h-5 w-5 text-gray-700" />,
      },
      {
        code: "signDate",
        label: "Contract Signed",
        value: contractSignDate,
        icon: <PencilSquareIcon className="h-5 w-5 text-gray-700" />,
      },
      {
        code: "expiryDate",
        label: "Contract Expires",
        value: contractExpiryDate,
        icon: <CalendarDaysIcon className="h-5 w-5 text-gray-700" />,
      },
      {
        code: "contractTerm",
        label: "Contract Term",
        value: packs[packId]?.contractTerm || "NA",
        icon: <LockClosedIcon className="h-5 w-5 text-gray-700" />,
      },
      {
        code: "vatNumber",
        label: "VAT Number",
        value: packs[packId]?.vendorVatNumber || "NA",
        icon: <BuildingLibraryIcon className="h-5 w-5 text-gray-700" />,
      },
      {
        code: "registrationNumber",
        label: "Registration Number",
        value: packs[packId]?.vendorRegistrationNumber || "NA",
        icon: <BuildingOfficeIcon className="h-5 w-5 text-gray-700" />,
      },
      {
        code: "registeredAddress",
        label: "Registered Address",
        value: packs[packId]?.vendorRegisteredAddress || "NA",
        icon: <MapPinIcon className="h-5 w-5 text-gray-700" />,
      },
    ];
    return newSupplierInfoList;
  }, [packs, countries]);

  return (
    <>
      <div className="space-y-4 rounded-xl bg-white p-6 shadow">
        <h2 className="text-xl font-semibold leading-7 text-gray-900">
          Key Info
        </h2>
        <div>
          <dl className="mt-2 divide-y divide-gray-200 border-b border-t border-gray-200">
            {packs &&
              packId &&
              packs[packId] &&
              info &&
              info.map((item, index) => (
                <div
                  key={index}
                  className="group flex cursor-pointer justify-between py-3 text-sm font-medium"
                  tabIndex={0} // TODO: fix div vs button elements for links
                  onClick={() => {
                    if (item.func) {
                      item.func();
                    } else {
                      handleEditClick({
                        code: item.code,
                        label: item.label,
                        value: item.value,
                        icon: item.icon,
                      });
                    }
                  }}
                >
                  <dt className="flex items-center text-gray-500">
                    <span>{item.icon}</span>
                    <span className="ml-1">{item.label}</span>
                  </dt>
                  <dd className="text-right text-gray-900 group-hover:text-neon-500">
                    {item.value}
                  </dd>
                </div>
              ))}
          </dl>
        </div>
      </div>
      {packs[packId]?.type === "supplier" && (
        <div className="space-y-4 rounded-xl bg-white p-6 shadow">
          <h2 className="text-xl font-semibold leading-7 text-gray-900">
            Supplier Info
          </h2>
          <div>
            <dl className="mt-2 divide-y divide-gray-200 border-b border-t border-gray-200">
              {packs &&
                packId &&
                packs[packId] &&
                supplierInfo &&
                supplierInfo.map((item, index) => (
                  <div
                    key={index}
                    className="group flex cursor-pointer justify-between py-3 text-sm font-medium"
                    tabIndex={0} // TODO: fix div vs button elements for links
                    onClick={() => {
                      if (item.func) {
                        item.func();
                      } else {
                        handleEditClick({
                          code: item.code,
                          label: item.label,
                          value: item.value,
                          icon: item.icon,
                        });
                      }
                    }}
                  >
                    <dt className="flex items-center text-gray-500">
                      <span>{item.icon}</span>
                      <span className="ml-1">{item.label}</span>
                    </dt>
                    <dd className="text-right text-gray-900 group-hover:text-neon-500">
                      {item.value}
                    </dd>
                  </div>
                ))}
            </dl>
          </div>
        </div>
      )}
    </>
  );
};

export default KeyInformation;
