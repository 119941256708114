import React from "react";

export const Heading1 = ({ index, item }) => {
  return (
    <div key={index}>
      <h1 className="my-10 text-4xl font-semibold text-gray-900">
        {item.content}
      </h1>
    </div>
  );
};

export const Heading2 = ({ index, item }) => {
  return (
    <div key={index}>
      <h2 className="mb-4 mt-8 border-b-2 border-b-gray-200 pb-2 text-2xl font-medium text-gray-600">
        {item.content}
      </h2>
    </div>
  );
};

export const Heading3 = ({ index, item }) => {
  return (
    <div key={index}>
      <h3 className="my-1 text-lg font-bold uppercase text-gray-800">
        {item.content}
      </h3>
    </div>
  );
};

export const Heading4 = ({ index, item }) => {
  return (
    <div key={index}>
      <h4 className="my-1 font-bold text-gray-800">{item.content}</h4>
    </div>
  );
};

export const Section = ({ index, item }) => {
  // The title in a "section" should be formatted similarly to a "heading4"
  return (
    <div key={index} className="text-base">
      <h3 className="my-1 font-bold text-gray-800">{item.title}</h3>
      <p>{item.content}</p>
    </div>
  );
};

export const Qanda = ({ index, item }) => {
  let answer_label = item.questionOptions?.find(
    (option) => option.value === item.answer,
  )?.label;
  switch (item.questionType) {
    case "int_options":
      return (
        <div key={index} className="mb-5 text-base">
          <div className="my-1 text-gray-800">{item.question}</div>
          <div className="ml-0 border-l-4 border-l-gray-400 pl-4">
            {answer_label}
          </div>
        </div>
      );
    case "text_options":
      return (
        <div key={index} className="mb-5 text-base">
          <div className="my-1 text-gray-800">
            {item.question}
            <span
              className="ml-2 inline-flex items-center rounded-md bg-neon-50 px-2 py-1
            text-xs font-medium text-neon-600 ring-1 ring-inset
            ring-neon-500/10"
            >
              {answer_label}
            </span>
          </div>
        </div>
      );
    default:
      return (
        <div key={index} className="mb-5 text-base">
          <div className="textå-gray-800 my-1">{item.question}</div>
          <div className="ml-0 border-l-4 border-l-gray-400 pl-4">
            {item.answer}
          </div>
        </div>
      );
  }
};

export const Paragraph = ({ index, item }) => {
  return (
    <p key={index} className="text-base">
      {item.content}
    </p>
  );
};

export const Metadata = ({ index, item }) => {
  return (
    <p key={index} className="text-sm font-extralight text-gray-400">
      {item.content}
    </p>
  );
};

export const IndicatorsTable = ({ index, item }) => {
  return (
    <div
      key={index}
      className="-mx-4 mt-4 rounded ring-1 ring-gray-300 sm:mx-0"
    >
      <table className="w-full table-fixed">
        <thead>
          <tr className="divide-x divide-gray-300">
            {item.header.map((cellText, cellTextIndex) => (
              <th
                key={cellTextIndex}
                scope="col"
                className="pt-3.5 text-center text-sm font-medium text-gray-900"
              >
                {cellText}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr className="divide-x divide-gray-300">
            {item.row.map((cellData, cellDataIndex) => (
              <td
                key={cellDataIndex}
                className="relative pb-4 text-center text-lg font-semibold"
              >
                <div className="text-gray-900">{cellData}</div>
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export const RiskTable = ({ index, item }) => {
  return (
    <div
      key={index}
      className="-mx-4 mt-4 rounded ring-1 ring-gray-200 sm:mx-0"
    >
      <table className="w-full table-fixed">
        <thead>
          <tr>
            <th
              scope="col"
              className="bg-green-300 py-3.5 text-center font-medium text-green-900"
            >
              Low
            </th>
            <th
              scope="col"
              className="bg-yellow-300 py-3.5 text-center font-medium text-yellow-900"
            >
              Medium
            </th>
            <th
              scope="col"
              className="bg-red-300 py-3.5 text-center font-medium text-red-900"
            >
              High
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="divide-x divide-gray-200">
            {Object.values(item.content).map((cellData, cellDataIndex) => (
              <td key={cellDataIndex} className="pb-4 text-center">
                <ul className="mt-3 text-gray-900">
                  {cellData.map((cell, cellIndex) => (
                    <li key={cellIndex}>{cell}</li>
                  ))}
                </ul>
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};
