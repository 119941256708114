import React, { Fragment, useContext, useEffect, useState } from "react";
import axiosInstance from "../../axiosInstance";
import AuthContext from "../../context/AuthContext";
import { PacksContext } from "../../context/PacksContext";
import AutoResizingTextarea from "../ui/AutoResizingTextarea/AutoResizingTextarea";
import { endorsementStatusLabel } from "../../utils";
import { ApprovalStatus } from "../../constants";

const EditApproval = ({ currentModule, setCurrentModule, selectedItem, setSelectedItem }) => {
  const { user } = useContext(AuthContext);
  const { packs, selectedPack, setPacks, updateModule } =
    useContext(PacksContext);
  const [answerInput, setAnswerInput] = useState(selectedItem.comment || "");
  const [backendMessage, setBackendMessage] = useState("");

  const saveApproval = async ({ approvalId, moduleId, status, comment }) => {
    try {
      const response = await axiosInstance.post("/api/approvals/submit", {
        approvalId: approvalId,
        moduleId: moduleId,
        status: status,
        comment: comment,
      });

      setSelectedItem(response.data.approval);
      // let thisModule = packs[selectedPack.id].modules.find(
      //   (m) => m.id === selectedItem.moduleId,
      // );
      let updatedApprovals = currentModule.approvals.map((a) => {
        return a.id === approvalId ? response.data.approval : a;
      });

      let updatedModule = {
        ...response.data.module,
        approvals: updatedApprovals,
      };
      setCurrentModule(updatedModule);
      updateModule({
        packId: selectedPack.id,
        moduleId: selectedItem.moduleId,
        details: updatedModule,
        },
      );
      // updateModule({
      //   packId: selectedPack.id,
      //   moduleId: selectedItem.moduleId,
      //   details: {
      //     ...response.data.module,
      //     approvals: updatedApprovals,
      //   },
      // });
    } catch (error) {
      console.error("Error updating approval:", error);
    }
  };

  const remindApprover = async ({ moduleId, approvalId }) => {
    try {
      const response = await axiosInstance.post("/api/approvals/request", {
        moduleId: moduleId,
        approvalId: approvalId,
      });
      setBackendMessage(response.data);
    } catch (error) {
      setBackendMessage(error.response.data.msg);
      console.error("Error updating approval:", error.response.data.msg);
    }
  };

  const currentUserOwner = () => {
    return (
      <div>
        <h2 className="text-left text-lg font-semibold text-gray-800">
          Your Review
        </h2>
        <p className="mt-2 text-sm text-gray-800">
          Taking everything into account, what's your view on this module?
        </p>
        <AutoResizingTextarea value={answerInput} onChange={setAnswerInput} />
        <div className="flex w-full space-x-2">
          <button
            type="button"
            className="flex-1 rounded-md bg-red-300 px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
            onClick={() =>
              saveApproval({
                approvalId: selectedItem.id,
                moduleId: selectedItem.moduleId,
                status: ApprovalStatus.REJECTED,
                comment: answerInput,
              })
            }
          >
            Oppose
          </button>
          <button
            type="button"
            className="flex-1 rounded-md bg-green-600 px-3 py-3 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
            onClick={() =>
              saveApproval({
                approvalId: selectedItem.id,
                moduleId: selectedItem.moduleId,
                status: ApprovalStatus.APPROVED,
                comment: answerInput,
              })
            }
          >
            Endorse
          </button>
        </div>
      </div>
    );
  };

  const currentUserNotOwner = () => {
    if (selectedItem.status === 0) {
      return (
        <Fragment>
          <div className="flex items-center space-x-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-6 w-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>

            <h2 className="text-left text-lg font-semibold text-gray-800">
              {selectedItem.user.name}'s Review
            </h2>
          </div>
          <p className="my-2 text-sm text-gray-800">
            We're still waiting on {selectedItem.user.name} to review this
            module.
          </p>
          <div className="flex w-full space-x-2">
            <button
              type="button"
              className="mr-auto mt-4 rounded-md bg-amber-600 px-4 py-2.5 text-sm font-semibold text-white hover:bg-amber-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-600 active:bg-amber-700"
              onClick={() =>
                remindApprover({
                  moduleId: selectedItem.moduleId,
                  approvalId: selectedItem.id,
                })
              }
            >
              Ping them on Slack
            </button>
          </div>
          {backendMessage != "" && (
            <p className="mt-3 font-bold text-gray-500">{backendMessage}</p>
          )}
        </Fragment>
      );
    }
    if (selectedItem.status === 1) {
      return (
        <Fragment>
          <h2 className="text-left text-lg font-semibold text-gray-800">
            {selectedItem.user.name}{" "}
            {endorsementStatusLabel(selectedItem.status).toLocaleLowerCase()}{" "}
            this module.
          </h2>
          <p className="mt-2 text-sm text-gray-800">
            Last updated on {selectedItem.updatedOn.split("T")[0]}.
          </p>
        </Fragment>
      );
    }
  };

  return (
    <div className="mx-3 rounded-xl bg-paper-dark p-10">
      {user.id === selectedItem.user.id
        ? currentUserOwner()
        : currentUserNotOwner()}
    </div>
  );
};

export default EditApproval;
