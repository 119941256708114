import React, { useContext, useCallback } from "react";
import { PacksContext } from "../../context/PacksContext";
import DraftStatusMenu from "./DraftStatusMenu";
import ViewRiskQanda from "./ViewRiskQanda";
import ViewQanda from "./ViewQanda";
import ViewApproval from "./ViewApproval";
import ViewNotes from "./ViewNotes";
import ViewApprovalPlaceholder from "./ViewApprovalPlaceholder";
import ModalActions from "./ModalActions";

const RightPane = ({
  // moduleId,
  currentModule,
  setCurrentModule,
  qandas,
  setLeftPaneViewMode,
  setShowLeftPane,
  selectedItem,
  setSelectedItem,
  handleSaveModule,
}) => {
  const { packs, selectedPack } = useContext(PacksContext);
  // const [currentModule, setCurrentModule] = useState(
  //   packs[selectedPack.id].modules.find((module) => module.id === moduleId),
  // );

  // useEffect(() => {
  //   setCurrentModule(
  //     packs[selectedPack.id].modules.find((module) => module.id === moduleId),
  //   );
  // }, [moduleId, packs, selectedPack]);

  const handleItemSelected = useCallback(
    (item, viewMode) => {
      setSelectedItem(item);
      setLeftPaneViewMode(viewMode);
      setShowLeftPane(true);
    },
    [setSelectedItem, setLeftPaneViewMode, setShowLeftPane],
  );

  let riskQanda = qandas.find((obj) => obj.code === "risk");

  return (
    <div className="flex w-screen max-w-md flex-col">
      <div className="flex justify-between px-4 pt-6">
        <h1 className="px-3 text-2xl font-bold">{currentModule.name}</h1>
        <DraftStatusMenu
          currentModule={currentModule}
          handleSaveModule={handleSaveModule}
        />
      </div>
      <div className="space-y-4 px-4 pb-36 pt-6">
        {riskQanda && (
          <ViewRiskQanda
            riskQanda={riskQanda}
            handleClick={handleItemSelected}
          />
        )}
        {qandas.filter((q) => q.category === "assessment").length > 0 && (
          <div>
            <h2 className="px-3 font-semibold">Assessment</h2>
            <ul>
              {qandas
                .filter((q) => q.category === "assessment")
                .map((qanda) => (
                  <ViewQanda
                    key={qanda.questionId}
                    qanda={qanda}
                    selectedItem={selectedItem}
                    handleClick={handleItemSelected}
                  />
                ))}
            </ul>
          </div>
        )}
        {qandas.filter((q) => q.category === "monitoring").length > 0 && (
          <div>
            <h2 className="px-3 font-semibold">Monitoring</h2>
            <ul>
              {qandas
                .filter((q) => q.category === "monitoring")
                .map((qanda) => (
                  <ViewQanda
                    key={qanda.questionId}
                    qanda={qanda}
                    selectedItem={selectedItem}
                    handleClick={handleItemSelected}
                  />
                ))}
            </ul>
          </div>
        )}
        {([0, 1, 2].includes(currentModule.status) ||
          currentModule.approvals.length > 0) && (
          <div className="px-3">
            <h2 className="font-semibold">Reviewers</h2>
            <ul>
              {[0, 1].includes(currentModule.status) ? (
                <ViewApprovalPlaceholder currentModule={currentModule} />
              ) : (
                currentModule.approvals.map((a) => (
                  <ViewApproval
                    key={a.id}
                    currentModule={currentModule}
                    approval={a}
                    handleClick={handleItemSelected}
                  />
                ))
              )}
            </ul>
          </div>
        )}
        <div>
          <h2 className="px-3 font-semibold">Notes</h2>
          <ul>
            <ViewNotes
              currentModule={currentModule}
              handleClick={handleItemSelected}
            />
          </ul>
        </div>
        <div className="p-3 text-right text-xs opacity-10">
          {currentModule.id}
        </div>
      </div>
      <div className="flex justify-center">
        <ModalActions
          currentModule={currentModule}
          setCurrentModule={setCurrentModule}
          qandas={qandas}
        />
      </div>
    </div>
  );
};

export default RightPane;
