import React, { useMemo } from "react";
import { useTable, useSortBy } from "react-table";

const TodosTable = ({ todos, handleTodoClicked }) => {
  const data = useMemo(() => [...todos], [todos]);

  const columns = React.useMemo(
    () => [
      {
        Header: "All tasks",
        accessor: "title",
        sortable: true,
        Cell: ({ cell: { value } }) => (
          <span className="text-sm text-gray-700">{value}</span>
        ),
      },
      {
        Header: "Pack",
        accessor: "packName",
        sortable: true,
        Cell: ({ cell: { value } }) => (
          <span className="text-sm text-gray-700">{value}</span>
        ),
      },
    ],
    [],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);
  return (
    <div className="mt-6 rounded-xl bg-white shadow">
      <table
        {...getTableProps()}
        className="min-w-full divide-y divide-gray-300"
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className="px-4 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
                >
                  {column.render("Header")}
                  {column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="divide-y divide-gray-200">
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className="cursor-pointer hover:bg-gray-50"
                onClick={() => handleTodoClicked(row.original)}
              >
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-900"
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TodosTable;
