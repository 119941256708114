import React from "react";
import { RadioGroup } from "@headlessui/react";
import { classNames } from "../../utils";

const OptionsInput = ({ options, selected, setSelected }) => {
  return (
    <RadioGroup className={"mt-4"} value={selected} onChange={setSelected}>
      <div className="flex justify-between space-x-4">
        {options.map((option) => (
          <RadioGroup.Option
            key={option.value}
            value={option.value}
            className={({ active }) =>
              classNames(
                active
                  ? "border-neon-600 ring-2 ring-neon-600"
                  : "border-gray-300",
                selected == option.value
                  ? "border-neon-600 ring-2 ring-neon-300"
                  : "",
                "relative flex flex-1 cursor-pointer flex-col justify-evenly rounded-lg border bg-paper-light px-6 py-4 text-center focus:outline-none",
              )
            }
          >
            {({ active, checked }) => (
              <>
                <RadioGroup.Label as="span">{option.label}</RadioGroup.Label>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};

export default OptionsInput;
