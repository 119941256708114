import React, { useState, useEffect, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import axiosInstance from "../../axiosInstance";
import { PacksContext } from "../../context/PacksContext";
import PackHeader from "../../components/Pack/PackHeader/PackHeader";
import ReportModal from "./ReportModal";
import "./PackReports.css";

const PackReports = () => {
  const { packId } = useParams();
  const [packReports, setPackReports] = useState(null);
  const [selectedReport, setSelectedReport] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const { packs, selectPack, fetchPacksList } = useContext(PacksContext);

  useEffect(() => {
    // Get the packs list from the API if we don't have it yet, or if its zero length
    if (packs.length === 0) {
      console.log("Fetching packs list in PackReports");
      fetchPacksList();
      selectPack(packId);
    }
  }, [packs, packId]);

  useEffect(() => {
    let isMounted = true;
    const fetchPackReports = async () => {
      if (isMounted) setIsLoading(true);
      try {
        const response = await axiosInstance.get(
          `/api/packs/${packId}/reports`,
        );
        if (isMounted) {
          setPackReports(response.data);
          // if (selectedPack?.id != packId) {
          //     selectPack(packId, response.data.title);
          // }
        }
      } catch (error) {
        if (isMounted) setError(error.message);
      } finally {
        if (isMounted) setIsLoading(false);
      }
    };
    if (packId) fetchPackReports();
    return () => {
      isMounted = false;
    }; // Cleanup function to prevent memory leaks
  }, [packId]);

  const openModal = (report) => {
    setSelectedReport(report);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  if (isLoading)
    return (
      <div>
        <PackHeader />
        {/* Loading content... */}
      </div>
    );

  if (error) return <div>Error: {error}</div>;

  return (
    <div className="reports">
      <PackHeader />
      <div className="card-container">
        {packReports.reports &&
          packReports.reports.map((report) => (
            <div
              key={report.id}
              className={report.active ? "card" : "card inactive"}
              onClick={() => report.active && openModal(report)}
            >
              <div className="card-header">{report.title}</div>
              <div className="card-content">
                <p className="report-description">{report.description}</p>
              </div>
              {!report.active && <div className="card-footer">Coming soon</div>}
            </div>
          ))}
      </div>
      {isModalOpen && (
        <ReportModal
          packName={packReports.packName}
          report={selectedReport}
          isOpen={isModalOpen}
          onClose={closeModal}
        />
      )}
    </div>
  );
};

export default PackReports;
