import React, { useContext, useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { PacksContext } from "../../context/PacksContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/free-solid-svg-icons";

const Stakeholders = () => {
  const { packId } = useParams();
  const { packs, selectedPack } = useContext(PacksContext);
  const [stakeholders, setStakeholders] = useState([]);

  useEffect(() => {
    let newStakeholders = {};

    packs[packId]?.modules?.forEach((module) => {
      module.approvals.forEach((approval) => {
        let userId = approval.user.id;
        if (newStakeholders[userId]) {
          newStakeholders[userId].modules.push({
            id: module.id,
            name: module.name,
            status: approval.status,
            comment: approval.comment,
          });
        } else {
          newStakeholders[userId] = {
            id: approval.user.id,
            name: approval.user.name,
            email: approval.user.email,
            image: approval.user.image512,
            modules: [
              {
                id: module.id,
                name: module.name,
                status: approval.status,
                comment: approval.comment,
              },
            ],
          };
        }
      });
    });

    setStakeholders(Object.values(newStakeholders));
  }, [packs]);

  // Empty state if no stakeholders
  if (stakeholders.length == 0) {
    return (
      <div className="space-y-4 rounded-xl bg-white px-5 py-2 shadow">
        <div className="flex items-center space-x-10 p-6">
          <FontAwesomeIcon icon={faUsers} size="4x" className="text-gray-400" />
          <div>
            <div className="text-sm font-medium text-gray-600">
              No stakeholders yet
            </div>
            <div className="text-sm font-light text-gray-600">
              Add stakeholders to this pack to gather input from different teams
              on their areas of expertise.
            </div>
            <Link to={`/packs/${selectedPack.id}/settings`}>
              <button
                type="button"
                className="mt-3 rounded-md bg-blue-50 px-4 py-2.5 text-sm font-semibold text-neon-600 shadow-sm hover:bg-blue-100"
              >
                Add stakeholders
              </button>
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-4 rounded-xl bg-white p-6 shadow">
      <div className="flex justify-between">
        <h2 className="text-xl font-semibold leading-7 text-gray-900">
          Stakeholders
        </h2>
        <Link to={`/packs/${selectedPack.id}/settings`}>
          <button
            type="button"
            className="rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            Edit stakeholders
          </button>
        </Link>
      </div>
      <div className="divide-y">
        {packs &&
          packId &&
          packs[packId] &&
          stakeholders.map((stakeholder) => (
            <div key={stakeholder.id} className="flex space-x-3 pb-3 pt-3">
              <img
                src={stakeholder.image}
                alt=""
                className="h-20 w-20 rounded-lg"
              />
              <div className="flex flex-1 flex-col">
                <div>
                  <h3 className="text-sm font-semibold text-gray-900">
                    {stakeholder.name}
                  </h3>
                  <div className="text-xs font-light">{stakeholder.email}</div>
                </div>
                <div className="mt-3 flex flex-wrap">
                  {stakeholder.modules.map((module) => (
                    <span
                      key={module.id}
                      className="mb-2 mr-1 inline-flex cursor-default items-center rounded-md bg-gray-100 px-1.5 py-0.5 text-xs font-medium text-gray-600"
                    >
                      {module.name}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Stakeholders;
