import React from "react";
import "./Slate.css";

const Slate = ({ slateTitle, slateButton, children }) => {
  return (
    <div className="slate">
      <div className="slate-header">
        <h2 className="text-base font-semibold leading-7 text-slate-600">
          {slateTitle}
        </h2>
        {slateButton && slateButton}
      </div>
      <div className="slate-body">{children}</div>
    </div>
  );
};

export default Slate;
