import React, { useState, useEffect, Fragment } from "react";
import axiosInstance from "../../axiosInstance";
import ItemSelector from "./ItemSelector";
import OptionsInput from "./OptionsInput";
import { conditionOperators, packConditionParameters } from "../../utils";

const CreateCondition = ({
  topics,
  setTopics,
  questionId,
  scope,
  condition,
  setIsVisible,
}) => {
  const [topicQuestions, setTopicQuestions] = useState(null);
  const [conditionScope, setConditionScope] = useState("pack");
  const [conditionTopic, setConditionTopic] = useState("");
  const [conditionQuestion, setConditionQuestion] = useState("");
  const [conditionPackParam, setConditionPackParam] = useState("");
  const [conditionTargetId, setConditionTargetQuestionId] = useState("");
  const [conditionOperator, setConditionOperator] = useState(null);
  const [conditionValue, setConditionValue] = useState(null);

  const scopes = [
    { value: "pack", label: "Pack" },
    { value: "question", label: "Question" },
  ];

  useEffect(() => {
    setConditionPackParam(packConditionParameters[0]);
  }, []);

  useEffect(() => {
    if (scope) {
      setConditionScope(scopes.find((s) => s.value === scope).value);
    }
  }, [scope]);

  useEffect(() => {
    if (scope == "pack" && condition !== undefined) {
      setConditionPackParam(
        packConditionParameters.find((p) => p.id === condition.target),
      );
    } else if (scope == "pack" && condition === undefined) {
      setConditionPackParam(packConditionParameters[0]);
    }
  }, [condition]);

  useEffect(() => {
    if (topics) {
      if (scope == "question" && condition !== undefined) {
        setConditionTopic({
          id: condition.target.topicId,
          name: topics.find((t) => t.id === condition.target.topicId).name,
        });
      } else {
        setConditionTopic({
          id: topics[0].id,
          name: topics[0].name,
        });
      }
    }
  }, [topics]);

  useEffect(() => {
    if (conditionTopic) {
      setTopicQuestions(
        topics?.find((topic) => topic.id === conditionTopic.id)?.questions,
      );
    }
  }, [conditionTopic]);

  useEffect(() => {
    if (topicQuestions) {
      if (condition !== undefined) {
        setConditionQuestion({
          ...topicQuestions.find((q) => q.id === condition.target.id),
          name: condition.target.text,
        });
      } else {
        setConditionQuestion({
          ...topicQuestions[0],
          name: topicQuestions[0].text,
        });
      }
    }
  }, [topicQuestions]);

  useEffect(() => {
    if (condition) {
      setConditionOperator(condition.operator);
      setConditionValue(condition.value);
    }
  }, [condition]);

  const handleSaveCondition = async ({
    questionId,
    targetQuestionId,
    operator,
    value,
  }) => {
    try {
      let newCondition = {};
      if (conditionScope == "pack") {
        newCondition = {
          scope: "pack",
          questionId: questionId,
          targetId: conditionPackParam.id,
          value: value,
        };
      } else if (conditionQuestion.type == "int_options") {
        newCondition = {
          scope: "question",
          questionId: questionId,
          targetId: targetQuestionId,
          operator: operator,
          value: value,
        };
      }
      const response = await axiosInstance.post(
        "/api/questions/conditions/create",
        newCondition,
      );
      setTopics(
        topics.map((t) => {
          return {
            ...t,
            questions: t.questions.map((q) => {
              if (q.id === questionId) {
                return {
                  ...response.data.question,
                };
              }
              return q;
            }),
          };
        }),
      );
      setIsVisible(false);
    } catch (error) {
      console.error("Error creating condition:", error);
    }
  };

  return (
    <div className="space-y-4 text-gray-900">
      <div className="text-xl font-semibold">
        {condition === undefined ? "New condition" : "Edit condition"}
      </div>
      <div className="block text-sm font-medium leading-6 text-gray-900">
        Condition is dependant on...
      </div>
      {condition === undefined && (
        <OptionsInput
          options={scopes}
          selected={conditionScope}
          setSelected={setConditionScope}
        />
      )}
      Condition Scope: {conditionScope}
      {conditionScope == "pack" && (
        <Fragment>
          <ItemSelector
            label={"Parameter"}
            data={packConditionParameters}
            selected={conditionPackParam}
            setSelected={setConditionPackParam}
          />
          {conditionPackParam.id == "assessment" && (
            <Fragment>
              <div className="block text-sm font-medium leading-6 text-gray-900">
                Answer
              </div>
              <OptionsInput
                options={conditionPackParam.options}
                selected={conditionValue}
                setSelected={setConditionValue}
              />
            </Fragment>
          )}
        </Fragment>
      )}
      {conditionScope == "question" && (
        <Fragment>
          <ItemSelector
            label={"Module"}
            data={topics.map((t) => ({
              id: t.id,
              name: t.name,
            }))}
            selected={conditionTopic}
            setSelected={setConditionTopic}
          />
          {conditionQuestion !== "" && (
            <ItemSelector
              label={"Question"}
              data={topicQuestions.map((q) => ({
                ...q,
                name: q.text,
              }))}
              selected={conditionQuestion}
              setSelected={setConditionQuestion}
            />
          )}
          {conditionQuestion.type == "int_options" && (
            <Fragment>
              <div className="block text-sm font-medium leading-6 text-gray-900">
                Criteria
              </div>
              <OptionsInput
                options={conditionOperators}
                selected={conditionOperator}
                setSelected={setConditionOperator}
              />
              <div className="block text-sm font-medium leading-6 text-gray-900">
                Answer
              </div>
              <OptionsInput
                options={conditionQuestion.options}
                selected={conditionValue}
                setSelected={setConditionValue}
              />
            </Fragment>
          )}
        </Fragment>
      )}
      <div className="my-3 mt-5 flex w-full pt-5">
        <button
          className="flex-1 rounded-md bg-white px-3 py-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          onClick={() => setIsVisible(false)}
        >
          Cancel
        </button>
        <button
          className="ml-3 flex-1 rounded-md bg-neon-600 px-3 py-3 text-sm font-semibold text-white shadow-sm hover:bg-neon-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-neon-600"
          onClick={() => {
            handleSaveCondition({
              questionId: questionId,
              targetQuestionId: conditionQuestion.id,
              operator: conditionOperator,
              value: conditionValue,
            });
          }}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default CreateCondition;
