import React from "react";
import { classNames } from "../../utils";

const ViewQanda = ({ qanda, selectedItem, handleClick }) => {
  const OptionsAnswer = ({ qanda }) => {
    let answeredOption = qanda.options.find(
      (option) => option.value === qanda.answer,
    );
    return <div>{answeredOption.label}</div>;
  };

  const BooleanAnswer = ({ qanda }) => {
    let answeredOption = qanda.options.find(
      (option) => Boolean(option.value) === Boolean(qanda.answer),
    );
    return <div>{answeredOption.label}</div>;
  };

  const renderNaAnswer = () => {
    return (
      <div className="text-xs font-light italic">
        <div className="text-gray-500">Not Applicable</div>
      </div>
    );
  };

  const renderAnswerContent = () => {
    switch (qanda.type) {
      case "text":
        return <div>{qanda.answer}</div>;
      case "text_options":
        return <OptionsAnswer qanda={qanda} />;
      case "int_options":
        return <OptionsAnswer qanda={qanda} />;
      case "boolean":
        return <BooleanAnswer qanda={qanda} />;
      default:
        return null;
    }
  };

  return (
    <div
      className={classNames(
        (qanda.answer === null || qanda.answer === undefined) && !qanda.isNa
          ? selectedItem.questionId === qanda.questionId
            ? "mx-3 mt-2 border-2 border-solid border-neon-200 bg-neon-200/5 p-6 text-center text-xs font-light italic"
            : "mx-3 mt-2 border-2 border-dashed border-gray-300 p-6 text-center text-xs font-light italic hover:border-neon-400 hover:bg-neon-200/10"
          : selectedItem.questionId === qanda.questionId
            ? "mx-3 border-2 border-neon-200 bg-neon-200/5 p-6"
            : "p-3 hover:border-neon-400 hover:bg-neon-200/10",
        "cursor-pointer rounded-lg text-sm",
      )}
      onClick={() => handleClick(qanda, "qandas")}
    >
      <dt className="text-sm font-medium text-gray-500">{qanda.question}</dt>
      <dd className="mt-1 text-sm text-gray-900">
        {" "}
        {qanda.answer !== null &&
          qanda.answer !== undefined &&
          renderAnswerContent()}
        {qanda.isNa && renderNaAnswer()}
      </dd>
    </div>
  );
};

export default ViewQanda;
