import React, { Fragment, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import { useCountries } from "../../../hooks/useCountries";
import { PacksContext } from "../../../context/PacksContext";
import clsx from "clsx";

const CountryDialog = ({ handleSave, updatePack, handleSuccess }) => {
  const { countries, getCountryName } = useCountries({ country: "GB" });
  const { packs } = useContext(PacksContext);
  const { packId } = useParams();
  const [newCountry, setNewCountry] = useState(packs[packId].country);
  const [query, setQuery] = useState("");

  useEffect(() => {
    setNewCountry(
      countries.find((country) => country.value === packs[packId].country),
    );
  }, [packs, packId, countries]);

  const filteredCountries =
    query === ""
      ? countries
      : countries.filter((country) => {
          return country.label.toLowerCase().includes(query.toLowerCase());
        });

  const handleSaveClick = () => {
    handleSave(
      packId,
      { country: newCountry.value },
      updatePack,
      handleSuccess,
    );
  };

  return (
    <Fragment>
      <div className="my-3">
        <Combobox
          value={newCountry?.value}
          onChange={setNewCountry}
          onClose={() => setQuery("")}
        >
          <ComboboxInput
            className={clsx(
              "w-full rounded-lg border-none bg-black/5 py-1.5 pl-3 pr-8 text-sm/6 text-gray-900",
              "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-black/25",
            )}
            aria-label="Country"
            displayValue={(country) => newCountry?.label}
            onChange={(event) => setQuery(event.target.value)}
          />
          <ComboboxButton className="group absolute inset-y-0 right-0 px-2.5">
            <ChevronDownIcon className="size-4 fill-black/60 group-data-[hover]:fill-black" />
          </ComboboxButton>
          <ComboboxOptions
            anchor="bottom"
            className="w-[var(--input-width)] rounded-xl border border-black/5 bg-white p-1 [--anchor-gap:var(--spacing-1)] empty:hidden"
          >
            {filteredCountries.map((country) => (
              <ComboboxOption
                key={country.value}
                value={country}
                className="group flex cursor-default select-none items-center gap-2 rounded-lg px-3 py-1.5 data-[focus]:bg-white/10"
              >
                <CheckIcon className="invisible size-4 fill-black group-data-[selected]:visible" />
                <div className="text-sm/6 text-gray-900">{country.label}</div>
              </ComboboxOption>
            ))}
          </ComboboxOptions>
        </Combobox>
      </div>
      <button
        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 active:bg-gray-100 sm:col-start-1 sm:mt-0"
        onClick={handleSaveClick}
      >
        Save
      </button>
    </Fragment>
  );
};

export default CountryDialog;
