import React from "react";

const SIZE = 200;
const STROKE_WIDTH = 20;
const RADIUS = SIZE / 2 - STROKE_WIDTH / 2;
const CIRCUMFERENCE = 2 * Math.PI * RADIUS;

function CircularProgressBar({ progress }) {
  const strokeDashoffset = CIRCUMFERENCE - (progress / 100) * CIRCUMFERENCE;

  return (
    <svg width={SIZE} height={SIZE} transform="rotate(-90)">
      <circle
        stroke="lightgray"
        fill="transparent"
        strokeWidth={STROKE_WIDTH}
        r={RADIUS}
        cx={SIZE / 2}
        cy={SIZE / 2}
      />
      <circle
        stroke="currentColor"
        fill="transparent"
        strokeWidth={STROKE_WIDTH}
        strokeLinecap="round"
        strokeDasharray={CIRCUMFERENCE}
        strokeDashoffset={strokeDashoffset}
        r={RADIUS}
        cx={SIZE / 2}
        cy={SIZE / 2}
      />
      <text
        x="50%"
        y="50%"
        textAnchor="middle"
        stroke="currentColor"
        strokeWidth="3px"
        dy=".3em"
        fontSize="40"
        transform={`rotate(90, ${SIZE / 2}, ${SIZE / 2})`}
      >
        {Math.round(progress)}%
      </text>
    </svg>
  );
}

export default CircularProgressBar;
