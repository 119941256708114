import { Fragment } from "react";
import {
  CheckIcon,
  ClipboardDocumentListIcon,
  ArrowRightIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline";
import clsx from "clsx";
import CircularProgressBar from "./CircularProgressBar";

function QandasCard({
  cardEnabled,
  qandasComplete,
  qandasStats,
  modulesInfo,
  setIsModalOpen,
}) {
  const InitialAssessmentCardPending = () => {
    return (
      <div className="rounded-lg bg-white p-6 shadow">
        <div className="text-lg font-semibold leading-7 text-gray-900">
          <h2>Initial Assessment</h2>
        </div>
        <div className="flex justify-between">
          <div className="mr-5 mt-6 space-y-3 text-sm text-gray-500">
            <p>
              Let's answer some initial questions to help us understand what
              you're trying to achieve. All questions can be changed later, so
              don't worry about getting everything right. We just need a
              starting point.
            </p>
            <p>
              This assessment has{" "}
              {modulesInfo.reduce(
                (acc, module) => acc + module.initialQuestionnaire.total,
                0,
              )}{" "}
              questions to answer from these modules.
            </p>
            <div className="my-3">
              {modulesInfo
                .filter((module) => module.initialQuestionnaire.total > 0)
                .map((module, index) => (
                  <span
                    key={index}
                    className={clsx(
                      module.initialQuestionnaire.pending > 0
                        ? "bg-yellow-100 text-yellow-700"
                        : "bg-green-100 text-green-700",
                      "mb-1 mr-2 inline-flex items-center rounded-md  px-2 py-1 text-xs font-medium",
                      "cursor-default",
                    )}
                  >
                    {module.initialQuestionnaire.pending === 0 ? (
                      <CheckIcon className="mr-1 h-4 w-4" />
                    ) : (
                      <ArrowRightIcon className="mr-1 h-4 w-4" />
                    )}
                    {module.name}
                  </span>
                ))}
            </div>
            <button
              className="mt-6 flex items-center rounded bg-neon-500 px-10 py-3 font-bold text-white hover:bg-neon-700"
              onClick={() => setIsModalOpen(true)}
            >
              <ClipboardDocumentListIcon className="mr-3 h-6 w-6" />
              {qandasStats.totalQuestions == qandasStats.pendingQuestions
                ? "Start assessment"
                : "Continue assessment"}
            </button>
          </div>
          {qandasStats.percentageComplete > 0 && (
            <div className="mr-10 text-neon-500">
              <CircularProgressBar progress={qandasStats.percentageComplete} />
            </div>
          )}
        </div>
      </div>
    );
  };

  const InitialAssessmentCardDone = () => {
    return (
      <div className="rounded-lg bg-white p-6 shadow">
        <div className="text-lg font-semibold leading-7 text-gray-900">
          <h2>Initial Assessment</h2>
        </div>
        <div className="flex justify-between">
          <div className="mr-5 mt-6 space-y-3 text-sm text-gray-500">
            <div className="inline-flex items-center space-x-3 rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
              <CheckIcon className="inline-block size-6 text-green-500" />{" "}
              <span>Assessment Complete</span>
              <button
                className="text-green-600 hover:text-green-900"
                onClick={() => setIsModalOpen(true)}
              >
                <PencilSquareIcon className="size-5" />
              </button>
            </div>
            <p>You've answered all the assessment questions. </p>
            <p>
              Your pack will have{" "}
              {modulesInfo.reduce(
                (acc, module) => acc + module.postQuestionnaire.pending,
                0,
              )}{" "}
              additional questions to answer in the next stage, covering the
              following modules.
            </p>
            <div className="my-3">
              {modulesInfo
                .filter((module) => module.postQuestionnaire.pending > 0)
                .map((module, index) => (
                  <span
                    key={index}
                    className={clsx(
                      "mb-2 mr-2 inline-flex items-center rounded-md bg-gray-100 px-2  py-1 text-xs font-medium text-gray-700",
                      "cursor-default",
                    )}
                  >
                    {module.name}
                  </span>
                ))}
            </div>
          </div>
          <div className="mr-10 text-neon-500">
            <CircularProgressBar progress={qandasStats.percentageComplete} />
          </div>
        </div>
      </div>
    );
  };

  // Show card with laoding spinner if qandasStats is not yet fetched
  if (!qandasStats) {
    return (
      <div className="rounded-lg bg-white p-6 shadow">
        <div className="text-lg font-semibold leading-7 text-gray-900">
          <h2>Initial Assessment</h2>
        </div>
        <div className="mt-6 text-sm text-gray-500">
          <p>Loading...</p>
        </div>
      </div>
    );
  }

  if (!cardEnabled) return null;

  return (
    <Fragment>
      {qandasComplete ? (
        <InitialAssessmentCardDone />
      ) : (
        <InitialAssessmentCardPending />
      )}
    </Fragment>
  );
}

export default QandasCard;
