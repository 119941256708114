import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { classNames } from "../../../utils";

export default function FilterDropdown({
  filterState,
  setFilterState,
  baseOption,
  options,
}) {
  //   let active = filterState;
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="group inline-flex w-full justify-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold text-gray-900 hover:text-neon-500">
          {filterState === "all"
            ? baseOption.label
            : options.find((option) => option.value === filterState).label}
          <ChevronDownIcon
            className="-mr-1 h-5 w-5 text-gray-400 group-hover:text-neon-500"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <a
                  onClick={() => setFilterState(baseOption.value)}
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "block px-4 py-2 text-sm",
                    "cursor-pointer",
                  )}
                >
                  Clear filter
                </a>
              )}
            </Menu.Item>
          </div>
          <div className="py-1">
            {options.map((option) => (
              <Menu.Item key={option.value}>
                {() => (
                  <a
                    onClick={() => setFilterState(option.value)}
                    className={classNames(
                      filterState == option.value
                        ? "bg-gray-100 text-gray-900"
                        : "text-gray-700",
                      "flex cursor-pointer px-4 py-2 text-sm",
                    )}
                  >
                    {option.icon && (
                      <div className="mr-2 w-[15px]">{option.icon}</div>
                    )}
                    <div className="ml-1"> {option.label}</div>
                  </a>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
