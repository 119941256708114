import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "../../axiosInstance";
import { PacksContext } from "../../context/PacksContext";
import PackHeader from "../../components/Pack/PackHeader/PackHeader";
import "./PackTimeline.css";

const PackTimeline = () => {
  const { packId } = useParams();
  const [packTimeline, setPackTimeline] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedModule, setSelectedModule] = useState(null);
  const { packs, fetchPacksList, selectPack } = useContext(PacksContext);

  useEffect(() => {
    // Get the packs list from the API if we don't have it yet, or if its zero length
    if (packs.length === 0) {
      console.log("Fetching packs list in PackTimeline");
      fetchPacksList();
      selectPack(packId);
    }
  }, [packs, packId]);

  useEffect(() => {
    let isMounted = true;
    const fetchPackTimeline = async () => {
      if (isMounted) setIsLoading(true);
      try {
        const response = await axiosInstance.get(
          `/api/packs/${packId}/timeline`,
        );
        if (isMounted) {
          setPackTimeline(response.data);
          // if (selectedPack?.id != packId) {
          //     selectPack(packId, response.data.title);
          // }
        }
      } catch (error) {
        if (isMounted) setError(error.message);
      } finally {
        if (isMounted) setIsLoading(false);
      }
    };
    if (packId) fetchPackTimeline();
    return () => {
      isMounted = false;
    }; // Cleanup function to prevent memory leaks
  }, [packId]);

  const openModal = (module) => {
    setSelectedModule(module);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  if (isLoading)
    return (
      <div>
        <PackHeader />
        {/* Loading content... */}
      </div>
    );
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="timeline flex flex-grow flex-col">
      <PackHeader />
      <div className="list-container">
        {packTimeline &&
          packTimeline.map((event) => (
            <div key={event.id} className="list-item">
              <span className="event-name">{event.description}</span>
              <div className="event-details">
                <span className="event-user">{event.user || "System"}</span>
                <span className="event-date">{event.date}</span>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default PackTimeline;
