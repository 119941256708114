import React, { useContext } from "react";
import { useParams, Link } from "react-router-dom";
import { PacksContext } from "../../context/PacksContext";
import { packRiskOptions } from "../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBolt } from "@fortawesome/free-solid-svg-icons";

const ModuleRiskList = ({ modules, risk }) => (
  <ul className="mt-3">
    {modules
      .filter((module) => module.risk === risk)
      .map((module) => (
        <li key={module.id} className="mt-1 text-center text-xs font-light">
          {module.name}
        </li>
      ))}
  </ul>
);

const PackRisk = () => {
  const { packId } = useParams();
  const { packs, selectedPack } = useContext(PacksContext);

  // Empty state if no pack risk
  if (packs[packId]?.risk == null) {
    return (
      <div className="space-y-4 rounded-xl bg-white px-5 py-2 shadow">
        <div className="flex items-center space-x-10 p-6">
          <FontAwesomeIcon icon={faBolt} size="4x" className="text-gray-400" />
          <div>
            <div className="text-sm font-medium text-gray-600">
              No Pack risk yet
            </div>
            <div className="text-sm font-light text-gray-600">
              As you answer more questions in the modules of this pack we'll be
              able to show you the overall risk of this pack.
            </div>
            <Link to={`/packs/${selectedPack.id}/modules`}>
              <button
                type="button"
                className="mt-3 rounded-md bg-blue-50 px-4 py-2.5 text-sm font-semibold text-neon-600 shadow-sm hover:bg-blue-100"
              >
                Go to modules
              </button>
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-4 rounded-xl bg-white p-6 shadow">
      <div className="flex justify-between">
        <h2 className="text-xl font-semibold leading-7 text-gray-900">Risk</h2>
        <Link to={`/packs/${selectedPack.id}/modules`}>
          <button
            type="button"
            className="rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            Go to modules
          </button>
        </Link>
      </div>
      <div>
        <h3 className="mb-2 text-xs font-bold uppercase text-gray-500">
          Overall
        </h3>
        <div className="text-sm font-light text-gray-900">
          This pack is rated as{" "}
          {packRiskOptions
            .find((risk) => risk.value === packs[packId]?.risk)
            ?.label.toLowerCase()}{" "}
          risk overall. This is calculated by looking at the highest risk rating
          across all modules in the pack.
        </div>
      </div>
      <div>
        <h3 className="mb-2 text-xs font-bold uppercase text-gray-500">
          Modules
        </h3>
        <div className="grid grid-cols-3 gap-4">
          <div>
            <div className="flex min-h-20 flex-col justify-center space-y-1 rounded bg-green-100 p-5 text-center text-green-700 ring-1 ring-green-200">
              <div className="text-4xl">
                {" "}
                {
                  packs[packId]?.modules.filter((module) => module.risk === 1)
                    .length
                }
              </div>
              <div className="text-xs font-semibold uppercase">Low</div>
            </div>
            <ModuleRiskList modules={packs[packId]?.modules} risk={1} />
          </div>
          <div>
            <div className="flex min-h-20 flex-col justify-center space-y-1 rounded bg-amber-100 p-5 text-center text-amber-700 ring-1 ring-amber-200">
              <div className="text-4xl">
                {" "}
                {
                  packs[packId]?.modules.filter((module) => module.risk === 2)
                    .length
                }
              </div>
              <div className="text-xs font-semibold uppercase">Medium</div>
            </div>
            <ModuleRiskList modules={packs[packId]?.modules} risk={2} />
          </div>
          <div>
            <div className="flex min-h-20 flex-col justify-center space-y-1 rounded bg-red-100 p-5 text-center text-red-700 ring-1 ring-red-200">
              <div className="text-4xl">
                {
                  packs[packId]?.modules.filter((module) => module.risk === 3)
                    .length
                }
              </div>
              <div className="text-xs font-semibold uppercase">High</div>
            </div>
            <ModuleRiskList modules={packs[packId]?.modules} risk={3} />
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default PackRisk;
