import React, { useState, Fragment, useEffect } from "react";
// import { useLocation } from "react-router-dom";
import {
  XMarkIcon,
  ArrowDownTrayIcon,
  PaperAirplaneIcon,
  ArrowPathIcon,
} from "@heroicons/react/24/outline";
import { Dialog, Transition } from "@headlessui/react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import axiosInstance from "../../axiosInstance";
import PdfDocument from "./pdfRenderUtils";
import {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Section,
  Qanda,
  Paragraph,
  Metadata,
  IndicatorsTable,
  RiskTable,
} from "./webRenderUtils";

const ReportOverlay = ({ packId, isOpen, setIsOpen, reportType }) => {
  // const location = useLocation();
  // const reportType = location.state?.reportType;
  const [reportItems, setReportItems] = useState([]);

  const fetchReportContent = async () => {
    try {
      const response = await axiosInstance.get("/api/reports/content", {
        params: { type: reportType, id: packId },
      });
      setReportItems(response.data);
    } catch (error) {
      console.error("Error fetching report content:", error);
    }
  };
  useEffect(() => {
    isOpen && fetchReportContent();
  }, [isOpen]);

  function renderReportItem(item, index) {
    switch (item.type) {
      case "heading1":
        return <Heading1 key={index} item={item} />;
      case "heading2":
        return <Heading2 key={index} item={item} />;
      case "heading3":
        return <Heading3 key={index} item={item} />;
      case "heading4":
        return <Heading4 key={index} item={item} />;
      case "paragraph":
        return <Paragraph key={index} item={item} />;
      case "metadata":
        return <Metadata key={index} item={item} />;
      case "indicatorsTable":
        return <IndicatorsTable key={index} item={item} />;
      case "riskTable":
        return <RiskTable key={index} item={item} />;
      case "section":
        return <Section key={index} item={item} />;
      case "qanda":
        return <Qanda key={index} item={item} />;
      default:
        return null;
    }
  }

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setIsOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          />
        </Transition.Child>

        {/* Full-screen scrollable container */}
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          {/* Container to center the panel */}
          <div className="mb-32 mt-20 flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-5xl sm:px-24 sm:py-4">
                <div
                  id="overlayHeader"
                  className="my-8 flex justify-between text-sm font-light text-gray-400"
                >
                  <div>
                    <XMarkIcon
                      className="block h-6 w-6 cursor-pointer hover:text-gray-900"
                      aria-hidden="true"
                      onClick={() => setIsOpen(false)}
                    />
                  </div>
                  <div
                    className="flex cursor-pointer flex-col items-center space-y-3 hover:text-gray-900"
                    onClick={() => fetchReportContent()}
                  >
                    <div>Pack Report</div>
                    {/* <ArrowPathIcon
                      className="block h-6 w-6 cursor-pointer hover:text-gray-900"
                      aria-hidden="true"
                    /> */}
                  </div>
                  <div className="flex space-x-5">
                    {/* <PDFDownloadLink
                      document={<PdfDocument data={reportItems} />}
                      fileName="download.pdf"
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? (
                          "Preparing document..."
                        ) : (
                          <ArrowDownTrayIcon
                            className="block h-6 w-6 cursor-pointer hover:text-gray-900"
                            aria-hidden="true"
                          />
                        )
                      }
                    </PDFDownloadLink>
                    <PaperAirplaneIcon
                      className="block h-6 w-6 cursor-pointer hover:text-gray-900"
                      aria-hidden="true"
                    /> */}
                  </div>
                </div>
                <div id="reportContent" className="mb-24 mt-8 space-y-3">
                  {reportItems
                    ? reportItems.map((item, index) => (
                        <div key={index}>{renderReportItem(item, index)}</div>
                      ))
                    : "Loading..."}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ReportOverlay;
