import { useEffect, useContext, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { PacksContext } from "../context/PacksContext";

const titleMap = {
  "/": "Packs",
  "/home": "Home",
  "/tasks": "Tasks",
  "/packs": "Packs",
  "/reports": "Reports",
  "/settings": "Settings",
};

const usePageTitle = () => {
  const location = useLocation();
  const { packId } = useParams();
  const { packs } = useContext(PacksContext);
  const [pageTitle, setPageTitle] = useState("");
  const [showBackArrow, setShowBackArrow] = useState(null);

  useEffect(() => {
    if (location.pathname.startsWith("/packs/")) {
      const packName = packs[packId]?.name || "Unknown Pack";
      setPageTitle(packName);
      setShowBackArrow(true);
    } else {
      const title = titleMap[location.pathname] || "Default Title";
      setPageTitle(title);
      setShowBackArrow(false);
      document.title = title;
    }
  }, [location, packId, packs]);

  return { pageTitle, showBackArrow };
};

export default usePageTitle;
