import { RadioGroup } from "@headlessui/react";
import { Dialog } from "@headlessui/react";
import {
  CursorArrowRaysIcon,
  EnvelopeOpenIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import React, { useState, useEffect, useContext } from "react";
import axiosInstance from "../../../axiosInstance";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleExclamation,
  faHeartPulse,
  faFlag,
  faKey,
  faGavel,
} from "@fortawesome/free-solid-svg-icons";
import { PacksContext } from "../../../context/PacksContext";
import {
  packRiskOptions,
  packLifecycleOptions,
  packTypeOptions,
  approvalStatusLabel,
} from "../../../utils";
import PackOwnerSelector from "./PackOwner";
import PackLifecycleSelector from "./PackLifecycleSelector";
import PackRiskDialog from "./PackRiskDialog";
import PackApprovalPercentageDialog from "./PackApprovalPercentageDialog";
import PackApprovals from "../PackApprovals/PackApprovals";
import PackTypeSelector from "./PackTypeSelector";

const PackIndicators = ({ pack }) => {
  let [dialogOpen, setDialogOpen] = useState(false);
  let [slideoverOpen, setSlideoverOpen] = useState(false);
  let [selectedIndicator, setSelectedIndicator] = useState(null);
  const { packs, selectedPack, updatePack, isLoading, error } =
    useContext(PacksContext);

  const [selectedPackTypeObj, setSelectedPackTypeObj] = useState(
    packTypeOptions.find((option) => option.value === pack.type),
  );
  const [selectedPackLifecycleObj, setSelectedPackLifecycleObj] = useState(
    packLifecycleOptions.find((option) => option.value === pack.lifecycle),
  );
  const [selectedPackRiskObj, setSelectedPackRiskObj] = useState(
    packRiskOptions.find((option) => option.value === pack.risk),
  );

  useEffect(() => {
    setSelectedPackTypeObj(
      packTypeOptions.find((option) => option.value === pack.type),
    );
    setSelectedPackLifecycleObj(
      packLifecycleOptions.find((option) => option.value === pack.lifecycle),
    );
    setSelectedPackRiskObj(
      packRiskOptions.find((option) => option.value === pack.risk),
    );
  }, [pack]);

  const savePackIndicators = async ({
    newPackType,
    newPackLifecycle,
    newPackRisk,
    newPackOwnerSlackUserId,
  }) => {
    try {
      let updatedPack = {
        ...packs[selectedPack.id],
        type: newPackType || pack.type,
        lifecycle:
          newPackLifecycle !== undefined ? newPackLifecycle : pack.lifecycle,
        risk: newPackRisk !== undefined ? newPackRisk : pack.risk,
        ownerSlackUserId:
          newPackOwnerSlackUserId !== undefined
            ? newPackOwnerSlackUserId
            : null,
      };
      const response = await axiosInstance.put(
        `/api/packs/${updatedPack.id}/update`,
        updatedPack,
      );

      updatePack(updatedPack.id, response.data);
    } catch (error) {
      console.error("Error updating pack:", error);
    }

    setDialogOpen(false);
  };

  const indicators = [
    // {
    //   id: "packType",
    //   name: "Type",
    //   value: selectedPackTypeObj.label,
    //   faIcon: selectedPackTypeObj.icon2,
    //   icon: selectedPackTypeObj.icon,
    // },
    {
      id: "packLifecycle",
      name: "Lifecycle",
      value: selectedPackLifecycleObj.label,
      faIcon: faHeartPulse,
      icon: EnvelopeOpenIcon,
    },
    {
      id: "packRisk",
      name: "Derived Risk",
      value: selectedPackRiskObj.label,
      faIcon: faCircleExclamation,
      icon: CursorArrowRaysIcon,
    },
    // {
    //   id: "packApprovalPercentage",
    //   name: "Approved Modules",
    //   value: `${Math.round((pack.modules.filter((module) => module.status === 3).length / pack.modules.length) * 100)}%`,
    //   faIcon: faFlag,
    //   icon: UsersIcon,
    // },
    {
      id: "packOwner",
      name: "Owner",
      value: pack.owner?.name || "No owner set",
      faIcon: faKey,
      icon: UsersIcon,
    },
    {
      id: "packApproval",
      name: "Approval",
      value:
        approvalStatusLabel(pack.approvals[0]?.status) || "No approval set",
      faIcon: faGavel,
      icon: EnvelopeOpenIcon,
    },
  ];

  if (isLoading) return <div></div>;
  if (error) return <div>Error: {error}</div>;

  const handleIndicatorClick = (indicatorId) => {
    if (indicatorId === "packApproval") {
      setSlideoverOpen(true);
    } else {
      setDialogOpen(true);
    }
    setSelectedIndicator(indicatorId);
  };

  const renderDialog = () => {
    switch (selectedIndicator) {
      case "packType":
        return (
          <PackTypeSelector
            packType={selectedPackTypeObj.value}
            handleSave={savePackIndicators}
            dialogOpen={dialogOpen}
            setDialogOpen={setDialogOpen}
          />
        );
      case "packLifecycle":
        return (
          <PackLifecycleSelector
            packLifecycle={selectedPackLifecycleObj.value}
            handleSave={savePackIndicators}
            dialogOpen={dialogOpen}
            setDialogOpen={setDialogOpen}
          />
        );
      case "packRisk":
        return (
          <PackRiskDialog
            handleSave={savePackIndicators}
            dialogOpen={dialogOpen}
            setDialogOpen={setDialogOpen}
          />
        );
      case "packApprovalPercentage":
        return (
          <PackApprovalPercentageDialog
            numApprovedModules={
              pack.modules.filter((module) => module.status === 3).length
            }
            numTotalModules={pack.modules.length}
            dialogOpen={dialogOpen}
            setDialogOpen={setDialogOpen}
          />
        );
      case "packOwner":
        return (
          <PackOwnerSelector
            packOwner={pack.owner}
            handleSave={savePackIndicators}
            dialogOpen={dialogOpen}
            setDialogOpen={setDialogOpen}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <div>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
          {indicators.map((indicator) => (
            <div
              key={indicator.id}
              className="cursor-pointer overflow-hidden rounded-lg bg-white px-4 py-5 shadow hover:bg-gray-50 sm:p-6"
              onClick={() => handleIndicatorClick(indicator.id)}
            >
              <dt>
                <div className="absolute rounded-md bg-neon-400 p-3">
                  {/* <indicator.icon className="h-6 w-6 text-white" aria-hidden="true" /> */}
                  <FontAwesomeIcon
                    className="h-6 w-6 text-white"
                    icon={indicator.faIcon}
                  />
                </div>
                <p className="ml-16 truncate text-sm font-medium text-gray-500">
                  {indicator.name}
                </p>
              </dt>
              <dd className="ml-16 flex items-baseline">
                <p className="text-lg font-semibold text-gray-900">
                  {indicator.value}
                </p>
              </dd>
            </div>
          ))}
        </dl>
      </div>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
          <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
            {renderDialog()}
          </Dialog.Panel>
        </div>
      </Dialog>
      <PackApprovals
        pack={pack}
        open={slideoverOpen}
        setOpen={setSlideoverOpen}
      />
    </div>
  );
};

export default PackIndicators;
