import * as amplitude from "@amplitude/analytics-browser";

export const initializeAmplitude = () => {
  amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY, {
    serverZone: "EU",
    defaultTracking: true,
  });
};

export const identifyAmplitudeUser = (userId) => {
  amplitude.setUserId(userId);
};
