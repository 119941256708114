import React, { useState, useEffect, useContext } from "react";
import axiosInstance from "../../axiosInstance";
import QuestionsModal from "../../components/Settings/QuestionsModal";
import { ArrowLeftIcon } from "@heroicons/react/20/solid";

const QuestionSettings = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [topics, setTopics] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  useEffect(() => {
    // Fetch all company questions
    const fetchQuestions = async () => {
      try {
        const response = await axiosInstance.get("/api/questions/list");
        setTopics(response.data);
      } catch (error) {
        console.error("Error fetching questions:", error);
      }
    };
    fetchQuestions();
  }, []);

  //   useEffect(() => {
  //     // Fetch all Slack channels in a company workspace
  //     const fetchSlackChannels = async () => {
  //       // setIsLoading(true);
  //       try {
  //         const response = await axiosInstance.get(`/api/channels/list`);
  //         setChannels(response.data);
  //       } catch (err) {
  //         // setError(err.message);
  //       } finally {
  //         // setIsLoading(false);
  //       }
  //     };
  //     fetchSlackChannels();
  //   }, []);

  //   const handleCompanyChannelSelected = async (channelId) => {
  //     try {
  //       const response = await axiosInstance.put(`/api/company/channel`, {
  //         channel: channelId,
  //       });

  //       setCompanyChannel(response.data);
  //     } catch (error) {
  //       console.error("Error updating company channel:", error);
  //     }
  //   };

  return (
    <>
      <div className="my-5 flex items-center">
        <button
          type="button"
          className="inline-flex items-center text-sm font-medium text-neon-600 hover:text-neon-500"
          // onClick={closeModal}
        >
          <ArrowLeftIcon className="h-5 w-5" aria-hidden="true" />
          <span className="capitalize">Back to all settings</span>
        </button>
      </div>

      <div className="rounded-lg bg-white px-8 py-8 shadow-sm ring-1 ring-gray-200">
        <h2 className="text-xl font-semibold leading-7 text-slate-600">
          Questions
        </h2>
        <div className="mt-5">
          <div className="flex items-center justify-between">
            <h3 className="text-base leading-6 text-gray-900">
              Manage your questions and answers
            </h3>
          </div>
        </div>
        <div>
          {topics &&
            topics.map((topic) => (
              <div key={topic.code} className="mt-5">
                <h4 className="text-xl font-semibold text-gray-900">
                  {topic.name}
                </h4>
                <table className="w-full table-auto">
                  <tbody>
                    {topic.questions.map((question) => (
                      <tr key={question.id} className="mt-2">
                        <td className="w-full border px-4 py-2 text-base font-medium text-gray-900 hover:bg-paper-light">
                          {question.text}
                        </td>
                        <td className="border px-4 py-2">
                          <button
                            type="button"
                            className="text-neon-600 hover:text-neon-500"
                            onClick={() => {
                              setSelectedQuestion(question);
                              setIsOpen(true);
                            }}
                          >
                            Edit
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}
        </div>
        <QuestionsModal
          open={isOpen}
          setOpen={setIsOpen}
          questionId={selectedQuestion?.id}
          topics={topics}
          setTopics={setTopics}
        />
      </div>
    </>
  );
};

export default QuestionSettings;
