import React, { Fragment } from "react";
import ProgressBar from "./QuestionArea/ProgressBar";
import QuestionActions from "./QuestionArea/QuestionActions";
import EditQanda from "./EditQanda";
import ViewHelper from "./ViewHelper";
import EditApproval from "./EditApproval";
import EditNotes from "./EditNotes";

const LeftPane = ({
  currentModule,
  setCurrentModule,
  qandas,
  setQandas,
  viewMode,
  selectedItem,
  setSelectedItem,
  setShowLeftPane,
  handleSaveModule,
}) => {
  return (
    <div className="flex w-screen max-w-xl flex-col">
      <div className="space-y-4 px-7 pb-36 pt-8">
        <ProgressBar qandas={qandas} />
        <QuestionActions
          qandas={qandas}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
        />
        {viewMode === "qandas" && (
          <Fragment>
            <EditQanda
              selectedItem={selectedItem}
              qandas={qandas}
              setQandas={setQandas}
            />
            <ViewHelper selectedItem={selectedItem} />
          </Fragment>
        )}
        {viewMode === "approvals" && (
          <EditApproval
            currentModule={currentModule}
            setCurrentModule={setCurrentModule}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
          />
        )}
        {viewMode === "notes" && (
          <EditNotes
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            handleSaveModule={handleSaveModule}
          />
        )}
        {viewMode === "" && (
          <div>
            <h1 className="font-semibold">select an answer...</h1>
          </div>
        )}
      </div>
    </div>
  );
};

export default LeftPane;
