import React, { Fragment, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { RadioGroup, Radio, Label } from "@headlessui/react";
import { classNames, packLifecycleOptions } from "../../../utils";
import { PackLifecycle } from "../../../constants";
import { PacksContext } from "../../../context/PacksContext";

const LifecycleDialog = ({ handleSave, updatePack, handleSuccess }) => {
  const { packs } = useContext(PacksContext);
  const { packId } = useParams();
  const [newPackLifecycle, setNewPackLifecycle] = useState(
    packs[packId].lifecycle,
  );
  return (
    <Fragment>
      <RadioGroup
        value={newPackLifecycle}
        onChange={setNewPackLifecycle}
        className="my-6"
      >
        <Label className="sr-only">Choose a pack lifecycle stage</Label>
        <div className="grid grid-cols-3 gap-3 sm:grid-cols-3">
          {packLifecycleOptions
            .filter(
              (lifecycle) =>
                lifecycle.value !== PackLifecycle.INITIAL_ASSESSMENT,
            )
            .map((lifecycle) => (
              <Radio
                key={lifecycle.value}
                value={lifecycle.value}
                className={({ active, checked }) =>
                  classNames(
                    active ? "ring-2 ring-neon-400 ring-offset-2" : "",
                    checked
                      ? "bg-neon-400 text-white hover:bg-neon-300"
                      : "bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50",
                    "flex items-center justify-center rounded-md px-3 py-4 text-sm font-semibold uppercase sm:flex-1",
                    "cursor-pointer focus:outline-none",
                  )
                }
              >
                <Label as="span">
                  {lifecycle.icon} {lifecycle.label}
                </Label>
              </Radio>
            ))}
        </div>
      </RadioGroup>
      <button
        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
        onClick={() =>
          handleSave(
            packId,
            { lifecycle: newPackLifecycle },
            updatePack,
            handleSuccess,
          )
        }
      >
        Save
      </button>
    </Fragment>
  );
};

export default LifecycleDialog;
