import React, { useMemo } from "react";
import { useTable, useSortBy } from "react-table";
import { classNames } from "../../utils";
import {
  packRiskOptions,
  packLifecycleOptions,
  packTypeOptions,
  packApprovalOptions,
} from "../../utils";
import { PackLifecycle } from "../../constants";

const reportNames = {
  packBasic: "Pack Report",
  packDetailed: "Detailed Pack Report",
  packSummary: "Summary Pack Report",
  packRisk: "Risk Pack Report",
  packAction: "Action Pack Report",
  packCompliance: "Compliance Pack Report",
  packModule: "Module Pack Report",
  packQuestionnaire: "Questionnaire Pack Report",
};

const ReportsTable = ({
  reports,
  setSelectedId,
  setSelectedType,
  setShowOverlay,
}) => {
  const data = useMemo(() => [...reports], [reports]);
  const columns = useMemo(
    () => [
      {
        Header: "Title",
        accessor: "title",
        sortable: true,
        Cell: ({ cell: { value } }) => (
          <span className="text-base font-semibold text-gray-700">{value}</span>
        ),
      },
      {
        Header: "Type",
        accessor: "type",
        sortable: true,
        Cell: ({ cell: { value } }) => {
          let packType = packTypeOptions.find(
            (option) => option.value === value,
          );
          let IconComponent = packType.icon3;
          return (
            <div className="flex text-sm text-gray-700">
              <span className="opacity-50">
                <IconComponent className="h-5 w-5" />
              </span>
              <span className="ml-2 font-semibold">{packType.label}</span>
            </div>
          );
        },
      },
      {
        Header: "Risk",
        accessor: "risk",
        sortable: true,
        Cell: ({ cell: { value } }) => {
          return (
            <span
              className={classNames(
                value === 1 && "text-green-700",
                value === 2 && "text-yellow-600",
                value === 3 && "text-red-700",
                "text-sm font-semibold text-gray-700",
              )}
            >
              {packRiskOptions.find((option) => option.value === value).label}
            </span>
          );
        },
      },
      {
        Header: "Lifecycle",
        accessor: "lifecycle",
        sortable: true,
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          let packLifecycle = original.lifecycle;
          return (
            <span
            // className={classNames(
            //   "inline-flex items-center rounded-full px-3 py-1 text-sm font-semibold ring-1 ring-inset",
            //   packLifecycle === PackLifecycle.INITIAL_ASSESSMENT &&
            //     "bg-red-50 text-red-600 ring-red-500/10",
            //   packLifecycle === PackLifecycle.NOT_STARTED &&
            //     "bg-red-50 text-red-600 ring-red-500/10",
            //   packLifecycle === PackLifecycle.IN_PROGRESS &&
            //     "bg-blue-50 text-blue-600 ring-blue-500/10",
            //   packLifecycle === PackLifecycle.LIVE &&
            //     "bg-green-50 text-green-600 ring-green-500/10",
            //   packLifecycle === PackLifecycle.ON_HOLD &&
            //     "bg-yellow-50 text-yellow-600 ring-yellow-500/10",
            //   packLifecycle === PackLifecycle.ENDED &&
            //     "bg-gray-50 text-gray-600 ring-gray-500/10",
            // )}
            >
              {
                packLifecycleOptions.find(
                  (option) => option.value === packLifecycle,
                ).label
              }
            </span>
          );
        },
      },

      {
        Header: "Approval",
        accessor: "approvalStatus",
        sortable: true,
        Cell: ({ cell: { value } }) => (
          <span className="text-sm font-semibold text-gray-700">
            {packApprovalOptions.find((option) => option.value === value).label}
          </span>
        ),
      },
    ],
    [],
  );

  const handleRowClicked = (id, type) => {
    setSelectedId(id);
    setSelectedType(type);
    setShowOverlay(true);
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);
  return (
    <div className="mt-6 rounded-xl bg-white shadow">
      <table
        {...getTableProps()}
        className="min-w-full divide-y divide-gray-300"
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className="px-4 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
                >
                  {column.render("Header")}
                  {column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="divide-y divide-gray-200">
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className="cursor-pointer hover:bg-gray-50"
                onClick={() =>
                  handleRowClicked(row.original.id, row.original.reportType)
                }
              >
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-900"
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ReportsTable;
