import React from "react";
import axiosInstance from "../../../axiosInstance";
import debounce from "lodash.debounce";

const SearchBox = ({ setSearchResults, setShowSuggestions }) => {
  // Function to fetch channels from Slack API
  const fetchUsers = async (value) => {
    try {
      const response = await axiosInstance.get("/api/users/search", {
        params: { query: value },
      });
      const sortedData = response.data
        .sort((a, b) => b.match_score - a.match_score)
        .slice(0, 5);
      setSearchResults(sortedData);
      setShowSuggestions(false);
      console.log("Fetched users:", response.data);
    } catch (error) {
      console.error("Error fetching users in search box:", error);
    }
  };

  // Debounced function to handle input changes
  const debouncedFetchUsers = debounce((value) => {
    if (value.length > 0) {
      fetchUsers(value);
    } else {
      setShowSuggestions(true);
    }
  }, 300); // Waits 300ms after the last keystroke to make the API call

  return (
    <div className="mt-2">
      <input
        className="w-full rounded-md border-0 bg-white py-2 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-neon-600 sm:text-sm sm:leading-6"
        placeholder="Find a stakeholder by name..."
        onChange={(e) => debouncedFetchUsers(e.target.value)}
      />
    </div>
  );
};

export default SearchBox;
