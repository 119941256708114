import React, { useContext, useEffect } from "react";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import HeaderV3 from "../../components/Header/HeaderV3";
import SidebarV2 from "../../components/Sidebar/SidebarV2";
// import Sidebar from "../../components/Sidebar/Sidebar";

import AuthContext from "../../context/AuthContext";

const AppLayoutV3 = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!isAuthenticated && location.pathname !== "/login") {
      navigate("/login");
    }
  }, [isAuthenticated, navigate, location.pathname]);

  if (!isAuthenticated) {
    return <div>Authentication pending...</div>;
  }

  return (
    <div className="flex">
      {/* <Sidebar /> */}
      <SidebarV2 />
      <div className="flex h-screen w-screen flex-col lg:pl-60">
        <HeaderV3 />
        <div className="mt-4 flex max-w-7xl flex-grow px-8 pb-20 lg:gap-x-16">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AppLayoutV3;
