import React, { useEffect, useContext, useState, Fragment } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { PacksContext } from "../context/PacksContext";
import { PackLifecycle } from "../constants";
import PackHeader from "../components/Pack/PackHeader/PackHeader";
import AboutPack from "../components/PackOverview/AboutPack";
import PackTasks from "../components/PackOverview/PackTasks";
import KeyInformation from "../components/PackOverview/KeyInformation";
import Stakeholders from "../components/PackOverview/Stakeholders";
import PackRisk from "../components/PackOverview/PackRisk";
import PackApprovals from "../components/Pack/PackApprovals/PackApprovals";
import EditDialog from "../components/PackOverview/KeyInformationDialogs/EditDialog";

const PackOverview = () => {
  const navigate = useNavigate();
  const { packId } = useParams();
  const { packs, fetchPacksList, fetchPackModules, selectPack } =
    useContext(PacksContext);
  const [isQandasModalOpen, setIsQandasModalOpen] = useState(false);
  const [isApprovalsModalOpen, setIsApprovalsModalOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState(null);

  useEffect(() => {
    // Get the packs list from the API if we don't have it yet, or if its zero length
    if (packs.length === 0) {
      fetchPacksList();
      selectPack(packId);
    } else if (
      Object.keys(packs).length > 0 &&
      packs[packId] &&
      !packs[packId].modules
    ) {
      fetchPackModules(packId);
    }
  }, [packs, packId]);

  useEffect(() => {
    if (
      packs &&
      packs[packId] &&
      packs[packId].lifecycle === PackLifecycle.INITIAL_ASSESSMENT
    ) {
      navigate(`/packs/${packId}/questionnaire`);
    }
  }, [packs, packId]);

  if (!packs[packId]?.modules) {
    return null;
  }

  return (
    <div className="flex flex-1 flex-col space-y-6">
      <PackHeader />
      <div className="flex space-x-6">
        <div className="flex-1 space-y-6">
          <AboutPack
            setDialogContent={setDialogContent}
            setEditDialogOpen={setEditDialogOpen}
          />
          <PackRisk />
          <Stakeholders />
        </div>
        <div className="w-1/3 space-y-6">
          <PackTasks />
          <KeyInformation
            setDialogContent={setDialogContent}
            setEditDialogOpen={setEditDialogOpen}
            setApprovalsSlideOverOpen={setIsApprovalsModalOpen}
          />
        </div>
      </div>
      <EditDialog
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        dialogContent={dialogContent}
      />
      <PackApprovals
        pack={packs[packId]}
        open={isApprovalsModalOpen}
        setOpen={setIsApprovalsModalOpen}
      />
    </div>
  );
};

export default PackOverview;
