import React, { useState, Fragment, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "../../../axiosInstance";
import { PacksContext } from "../../../context/PacksContext";
import SearchBox from "./SearchBox";
import CarouselCard from "./CarouselCard";
import Placeholder from "./Placeholder";
import Stakeholder from "./Stakeholder";
import Module from "./Module";

function StakeholdersCard({ cardEnabled, moduleModalOpen, packType }) {
  const { packId } = useParams();
  const { selectedPack } = useContext(PacksContext);
  const [allSuggestions, setAllSuggestions] = useState([]);
  const [allModules, setAllModules] = useState([]);
  const [selectedStakeholders, setSelectedStakeholders] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(true);
  const [orphanModules, setOrphanModules] = useState([]);
  const [userCarousel, setUserCarousel] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [allowSave, setAllowSave] = useState(false);
  const [enableEdit, setEnableEdit] = useState(false);
  const [hasModuleModalBeenOpened, setHasModuleModalBeenOpened] =
    useState(false);

  // API call to fetch stakeholder data
  const fetchInitialData = async () => {
    try {
      const response = await axiosInstance.get(
        "/api/questionnaire/stakeholders",
        {
          params: { packId: selectedPack.id },
        },
      );
      setAllModules(response.data.modules);
      setAllSuggestions(response.data.suggestions);
      setSelectedStakeholders(response.data.stakeholders);
      console.log("✅ Stakeholders data:", response.data);
    } catch (error) {
      console.error("Error fetching stakeholders data:", error);
    }
  };

  // Set flag when module modal is opened, so that we only fetch the data after it's been closed (not just initiated)
  useEffect(() => {
    if (moduleModalOpen) {
      setHasModuleModalBeenOpened(true);
    }
  }, [moduleModalOpen]);

  // Fetch card data on initial load or when pack type changes
  useEffect(() => {
    if (selectedPack.id) fetchInitialData();
  }, [cardEnabled, packType]);

  // Fetch card data when module modal is closed
  useEffect(() => {
    if (!moduleModalOpen && hasModuleModalBeenOpened) fetchInitialData();
  }, [moduleModalOpen, hasModuleModalBeenOpened]);

  // Update user carousel based on search results and suggestions
  useEffect(() => {
    if (showSuggestions) {
      // Only show users that are not already selected as stakeholders
      let filteredSuggestions = allSuggestions
        .filter(
          (suggestion) =>
            !selectedStakeholders.find(
              (stakeholder) =>
                stakeholder.slackUserId === suggestion.slackUserId,
            ),
        )
        .slice(0, 5);
      setUserCarousel(filteredSuggestions);
    } else {
      setUserCarousel(searchResults);
    }
  }, [searchResults, showSuggestions, allSuggestions, selectedStakeholders]);

  useEffect(() => {
    updateOrphanModules();
  }, [allModules]);

  // Add user to selected stakeholders
  const handleAddUser = (user) => {
    // Add a user from the user carousel (either a suggestion or a search result) to the selected stakeholders
    if (
      selectedStakeholders.find(
        (stakeholder) => stakeholder.slackUserId === user.slackUserId,
      )
    ) {
      return;
    }
    let updatedStakeholders = [
      ...selectedStakeholders,
      { ...user, modules: [] },
    ];
    setSelectedStakeholders(updatedStakeholders);
  };

  const updateOrphanModules = () => {
    let orphanModules = allModules.filter((module) => {
      return !selectedStakeholders.some((stakeholder) =>
        stakeholder.modules.some((stakeholderModule) => {
          return stakeholderModule.id === module.id;
        }),
      );
    });
    setOrphanModules(orphanModules);
  };

  const handleSaveStakeholders = async (latestStakeholders) => {
    try {
      let data = {
        packId: packId,
        stakeholders: latestStakeholders,
      };
      const response = await axiosInstance.post(
        "/api/questionnaire/stakeholders",
        data,
      );
      setAllModules(response.data.modules);
      setAllSuggestions(response.data.suggestions);
      setSelectedStakeholders(response.data.stakeholders);
      setAllowSave(false);
      setEnableEdit(false);
    } catch (error) {
      console.error("Error:", error);
      //   setIsError(true);
      //   setErrorMessage(error.response.data.msg);
    }
  };

  const handleDragStart = (e, moduleId, sourceStakeholderId) => {
    setIsDragging(true);
    e.dataTransfer.setData("moduleId", moduleId);
    sourceStakeholderId &&
      // We only have stakeholderId when dragging moudles out of a stakeholder
      e.dataTransfer.setData("sourceStakeholderId", sourceStakeholderId);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, targetStakeholderId) => {
    e.preventDefault();
    // Dragged module
    let draggedModule = allModules.find(
      (module) => module.id === e.dataTransfer.getData("moduleId"),
    );
    // Source stakeholder
    let sourceStakeholder = selectedStakeholders.find(
      (stakeholder) =>
        stakeholder.slackUserId ===
        e.dataTransfer.getData("sourceStakeholderId"),
    );
    if (sourceStakeholder) {
      sourceStakeholder.modules = sourceStakeholder.modules.filter(
        (module) => module.id !== draggedModule.id,
      );
    }
    // Target stakeholder
    let targetStakeholder = selectedStakeholders.find(
      (stakeholder) => stakeholder.slackUserId === targetStakeholderId,
    );
    if (targetStakeholder) {
      targetStakeholder.modules.push(draggedModule);
    }
    setIsDragging(false);
    updateOrphanModules();
    setAllowSave(true);
  };

  const handleRemoveModule = (moduleId, stakeholderId) => {
    let updatedStakeholders = selectedStakeholders.map((stakeholder) => {
      if (stakeholder.slackUserId === stakeholderId) {
        stakeholder.modules = stakeholder.modules.filter(
          (module) => module.id !== moduleId,
        );
      }
      return stakeholder;
    });
    setSelectedStakeholders(updatedStakeholders);
    updateOrphanModules();
    setAllowSave(true);
  };

  if (!cardEnabled) {
    return <Placeholder />;
  }

  return (
    <div className="rounded-lg bg-white p-6 shadow">
      <div className="text-lg font-semibold leading-7 text-gray-900">
        <h2>Stakeholders</h2>
      </div>
      <div className="mt-6 text-sm text-gray-500">
        <p>
          Who's the domain expert for each module? These people will be asked to
          review the modules after you've filled them. Drag the modules to their
          respective stakeholder.
        </p>
      </div>
      {enableEdit && (
        <Fragment>
          <h3 className="mt-6 text-sm font-semibold text-gray-900">
            Add stakeholders
          </h3>
          <SearchBox
            setSearchResults={setSearchResults}
            setShowSuggestions={setShowSuggestions}
          />
          <div className="mt-4 text-sm text-gray-500">
            {showSuggestions
              ? "Suggested stakeholders"
              : userCarousel.length > 0
                ? "Search results"
                : "No results found"}
          </div>
          <div className="mt-4 flex space-x-3 px-1">
            {userCarousel.map((user) => (
              <CarouselCard
                key={user.slackUserId}
                user={user}
                handleAddUser={handleAddUser}
              />
            ))}
          </div>
        </Fragment>
      )}
      {/* STAKEHOLDERS */}
      {selectedStakeholders.length > 0 && (
        <Fragment>
          {enableEdit && (
            <h3 className="mt-6 text-sm font-semibold text-gray-900">
              Allocate modules to stakeholders
            </h3>
          )}
          <div className="mt-3 grid grid-cols-5 gap-4">
            {selectedStakeholders.map((stakeholder) => (
              <Stakeholder
                key={stakeholder.slackUserId}
                stakeholder={stakeholder}
                isDragging={isDragging}
                handleDragStart={handleDragStart}
                handleDragOver={handleDragOver}
                handleDrop={handleDrop}
                handleRemoveModule={handleRemoveModule}
                enableEdit={enableEdit}
              />
            ))}
          </div>
          {/* MODULES */}
          {enableEdit && (
            <div
              className="group mt-3 flex min-h-24 flex-col items-center justify-center rounded-xl border-2 border-dashed border-gray-400 p-4"
              onDrop={(e) => handleDrop(e)}
              onDragOver={(e) => handleDragOver(e)}
            >
              {orphanModules.length > 0 && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="mb-3 h-8 w-8 text-gray-400 opacity-50 group-hover:animate-bounce group-hover:opacity-100"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m4.5 15.75 7.5-7.5 7.5 7.5"
                  />
                </svg>
              )}
              <div className="space-y-2">
                {orphanModules.map((module) => (
                  <Module
                    key={module.id}
                    module={module}
                    handleDragStart={handleDragStart}
                  />
                ))}
              </div>
            </div>
          )}
        </Fragment>
      )}
      {enableEdit ? (
        <div className="mt-6 flex items-center justify-end space-x-2">
          <button
            className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            onClick={() => {
              setEnableEdit(false);
              setAllowSave(false);
            }}
          >
            Cancel
          </button>
          {allowSave ? (
            <button
              className="rounded bg-neon-500 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-neon-800 hover:bg-neon-700"
              onClick={() => handleSaveStakeholders(selectedStakeholders)}
            >
              Save stakeholders
            </button>
          ) : (
            <button
              className="rounded bg-gray-300 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-400 hover:bg-gray-400"
              disabled={true}
            >
              Save stakeholders
            </button>
          )}
        </div>
      ) : (
        <div className="mt-6 flex justify-end">
          <button
            className="rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            onClick={() => setEnableEdit(true)}
          >
            Edit stakeholders
          </button>
        </div>
      )}
    </div>
  );
}

export default StakeholdersCard;
