import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFaceLaughBeam,
  faFaceFrownOpen,
} from "@fortawesome/free-regular-svg-icons";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { classNames } from "../../utils";

const ViewRiskQanda = ({ riskQanda, handleClick }) => {
  const renderRisk = () => {
    if (riskQanda.isNa) {
      return <span>Risk non applicable</span>;
    }
    switch (riskQanda.answer) {
      case 1:
        return (
          <Fragment>
            <FontAwesomeIcon icon={faFaceLaughBeam} className="mr-2" />
            <span>Low Risk</span>
          </Fragment>
        );
      case 2:
        return (
          <Fragment>
            <FontAwesomeIcon icon={faFaceFrownOpen} className="mr-2" />
            <span>Medium Risk</span>
          </Fragment>
        );
      case 3:
        return (
          <Fragment>
            <FontAwesomeIcon icon={faTriangleExclamation} className="mr-2" />
            <span>High Risk</span>
          </Fragment>
        );
      default:
        return "Risk unknown";
    }
  };

  return (
    <div
      className={classNames(
        riskQanda.isNa
          ? "border-l-8 border-l-gray-500 bg-gray-300 text-xs font-medium text-gray-800 hover:border-gray-600"
          : riskQanda.answer === 1
            ? "border-l-8 border-l-green-500 bg-green-300 text-xs font-medium text-green-800 hover:border-green-600"
            : riskQanda.answer === 2
              ? "border-l-8 border-l-amber-400 bg-amber-200 text-sm font-medium text-amber-700 hover:border-amber-500"
              : riskQanda.answer === 3
                ? "border-l-8 border-l-red-400 bg-red-200 text-base font-medium text-red-700 hover:border-red-500"
                : "border-2 border-dashed border-gray-300 text-center text-xs font-medium text-gray-800 hover:border-gray-400",
        "mx-3 cursor-pointer rounded-lg p-4",
      )}
      onClick={() => handleClick(riskQanda, "qandas")}
    >
      {renderRisk()}
    </div>
  );
};

export default ViewRiskQanda;
