import React, { useContext, useState } from "react";
import { PacksContext } from "../../context/PacksContext";
import AutoResizingTextarea from "../ui/AutoResizingTextarea/AutoResizingTextarea";

const EditNotes = ({ selectedItem, setSelectedItem, handleSaveModule }) => {
  const { packs, selectedPack, setPacks, updateModule } =
    useContext(PacksContext);
  const [notes, setNotes] = useState(selectedItem.notes || "");

  return (
    <div className="mx-3 flex flex-col rounded-xl bg-paper-dark p-10">
      <h2 className="text-left text-lg font-semibold text-gray-800">
        Edit Notes
      </h2>
      <AutoResizingTextarea value={notes} onChange={setNotes} />
      <button
        className="ml-auto rounded-md bg-neon-600 px-4 py-2.5 text-sm font-semibold text-white hover:bg-neon-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-neon-600 active:bg-neon-700"
        onClick={() => handleSaveModule({ ...selectedItem, notes })}
      >
        Save
      </button>
    </div>
  );
};

export default EditNotes;
