import React from "react";
import "./GenericModal.css";

const Modal = ({ isOpen, title, children, onClose, onDelete, onSave }) => {
  if (!isOpen) return null;

  const handleModalContentClick = (event) => {
    event.stopPropagation();
  };

  return (
    <div className="generic-modal-background" onClick={onClose}>
      <div className="generic-modal" onClick={handleModalContentClick}>
        <div className="modal-header px-3">
          <h2 className="text-base font-semibold leading-7 text-white">
            {title}
          </h2>
        </div>
        <div className="modal-body px-3">{children}</div>
        <div className="modal-footer px-3">
          {onDelete && (
            <button className="button button-delete" onClick={onDelete}>
              Delete
            </button>
          )}
          <div className="modal-footer-spacer"></div>
          {onClose && (
            <button
              type="button"
              onClick={onClose}
              className="rounded-md bg-white px-4 py-2.5 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-300 active:bg-gray-300"
            >
              Cancel
            </button>
          )}
          {onSave && (
            <button
              className="ml-2 rounded-md bg-neon-600 px-4 py-2.5 text-sm font-semibold text-white hover:bg-neon-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-neon-600 active:bg-neon-700"
              onClick={onSave}
            >
              Save answer
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
