export const ApprovalType = {
  APPROVAL: 1,
  ENDORSEMENT: 2,
};

export const ApprovalStatus = {
  UNANSWERED: 0,
  APPROVED: 1,
  REJECTED: 2,
  NEUTRAL: 3,
};

export const PackLifecycle = {
  NOT_STARTED: 0,
  INITIAL_ASSESSMENT: 5,
  IN_PROGRESS: 1,
  LIVE: 2,
  ON_HOLD: 3,
  ENDED: 4,
};

export const ModuleStatus = {
  EMPTY: 0,
  DRAFT: 1,
  SUBMITTED_NOT_REVIEWED: 2,
  SUBMITTED_APPROVED: 3,
  SUBMITTED_REJECTED: 4,
  RESUBMITTED_NOT_REVIEWED: 5,
  SUBMITTED_REVIEWED: 6,
};
