import React, { useContext } from "react";
import axiosInstance from "../axiosInstance";

export const updatePackData = async (
  packId,
  newValues,
  updatePack,
  handleSuccess,
) => {
  try {
    const response = await axiosInstance.post("/api/packs/edit", {
      id: packId,
      newValues: newValues,
    });
    // updatePack(selectedPack.id, response.data.pack);
    // onClose();
    updatePack(response.data.pack.id, response.data.pack);
    handleSuccess();
  } catch (error) {
    console.error("Error:", error);
  }
};
