import React, { useState, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGavel } from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../../../axiosInstance";
import { PacksContext } from "../../../context/PacksContext";
import AuthContext from "../../../context/AuthContext";
import { ApprovalStatus } from "../../../constants";
import { classNames } from "../../../utils";

const PackApprovalActions = ({ pack }) => {
  const { updatePack } = useContext(PacksContext);
  const { user } = useContext(AuthContext);
  const [backendMessage, setBackendMessage] = useState("");
  const [approvalDelay, setApprovalDelay] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);

  const handleSubmitApproval = async ({ packId, approvalId, newStatus }) => {
    try {
      const response = await axiosInstance.post("/api/approvals/submit", {
        packId: packId,
        approvalId: approvalId,
        newStatus: newStatus,
      });
      updatePack(response.data.pack.id, response.data.pack);
    } catch (error) {
      console.error("Error updating pack approval:", error.response.data.msg);
    }
  };

  const handleApprovalClicked = async ({ packId, approvalId, newStatus }) => {
    setApprovalDelay(true);
    const id = setTimeout(() => {
      handleSubmitApproval({ packId, approvalId, newStatus });
      setApprovalDelay(false);
    }, 3000);
    setTimeoutId(id);
  };

  const handleCancel = () => {
    clearTimeout(timeoutId);
    setApprovalDelay(false);
  };

  const handleRequestReview = async ({ packId, approvalId }) => {
    try {
      const response = await axiosInstance.post("/api/approvals/request", {
        packId: packId,
        approvalId: approvalId,
      });
      setBackendMessage(response.data);
    } catch (error) {
      setBackendMessage(error.response.data.msg);
      console.error("Error requesting pack review:", error.response.data.msg);
    }
  };

  const noApproverPlaceholder = () => {
    return (
      <div className="flex w-full flex-col">
        <div className="mt-1 text-center text-base font-medium text-red-900 opacity-40">
          No approver assigned to this pack. You can do this in the settings
          tab.
        </div>
      </div>
    );
  };

  const currentUserApprover = () => {
    return (
      <div className="flex flex-col items-center">
        <div className="flex items-center space-x-2">
          <FontAwesomeIcon icon={faGavel} className="h-6 w-6 opacity-60" />
          <h2 className="text-left text-lg font-semibold text-gray-800">
            Your Approval
          </h2>
        </div>
        <div className="my-4 text-center text-sm text-gray-800">
          <span>
            {pack.approvals[0].status == ApprovalStatus.APPROVED
              ? "This pack is currently approved. You can change the approval status at any time."
              : pack.approvals[0].status == ApprovalStatus.REJECTED
                ? "This pack is currently rejected. You can approve it at any time."
                : "This pack hasn't been reviewed yet. You can approve or reject it at any time."}
          </span>
        </div>
        {!approvalDelay ? (
          <div className="flex w-full space-x-2">
            <button
              type="button"
              className={classNames(
                pack.approvals[0].status === ApprovalStatus.APPROVED
                  ? "opacity-20 hover:opacity-100"
                  : "",
                "flex-1 rounded-md bg-red-600 px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 active:bg-red-700",
              )}
              disabled={pack.approvals[0].status === ApprovalStatus.REJECTED}
              onClick={() =>
                handleApprovalClicked({
                  packId: pack.id,
                  approvalId: pack.approvals[0].id,
                  newStatus: ApprovalStatus.REJECTED,
                })
              }
            >
              Reject
            </button>
            <button
              type="button"
              className={classNames(
                pack.approvals[0].status === ApprovalStatus.REJECTED
                  ? " opacity-20 hover:opacity-100"
                  : "",
                "flex-1 rounded-md bg-green-600 px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 active:bg-green-700",
              )}
              disabled={pack.approvals[0].status === ApprovalStatus.APPROVED}
              onClick={() =>
                handleApprovalClicked({
                  packId: pack.id,
                  approvalId: pack.approvals[0].id,
                  newStatus: ApprovalStatus.APPROVED,
                })
              }
            >
              Approve
            </button>
          </div>
        ) : (
          <div className="flex w-full flex-col">
            <button
              type="button"
              onClick={handleCancel}
              disabled={!approvalDelay}
              className="mt-2 rounded-md bg-amber-600 px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-amber-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-600 active:bg-amber-700"
            >
              Cancel
            </button>
          </div>
        )}
        <div className="relative mt-1 h-4 w-full overflow-hidden rounded-md">
          <div
            className={classNames(
              approvalDelay
                ? "w-0 bg-neon-400  duration-3000"
                : "w-full bg-transparent duration-0",
              "h-full transition-width ease-linear",
            )}
          />
        </div>
      </div>
    );
  };

  const currentUserNotApprover = () => {
    return (
      <div className="flex flex-col items-center">
        <div className="flex items-center space-x-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="h-6 w-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
            />
          </svg>
          <h2 className="text-left text-lg font-semibold text-gray-800">
            {pack.approvals[0].user.name} Approval
          </h2>
        </div>
        <p className="mt-6 text-center text-sm text-gray-800">
          {pack.approvals[0].status == ApprovalStatus.APPROVED ? (
            <span>
              This pack is currently approved. If you'd like{" "}
              {pack.approvals[0].user.name} to review it again we can send them
              a message on Slack.
            </span>
          ) : pack.approvals[0].status == ApprovalStatus.REJECTED ? (
            <span>
              This pack is currently rejected and can only be reviwed by{" "}
              {pack.approvals[0].user.name}. We can ask them to review this pack
              again.
            </span>
          ) : (
            <span>
              This pack can only be reviwed by {pack.approvals[0].user.name}.
              But we can ping them a message on Slack on your behalf.
            </span>
          )}
        </p>
        <button
          type="button"
          className={classNames(
            pack.approvals[0].status === ApprovalStatus.APPROVED
              ? "bg-green-600 hover:bg-green-500 focus-visible:outline-green-600 active:bg-green-700"
              : pack.approvals[0].status === ApprovalStatus.REJECTED
                ? "bg-red-600 hover:bg-red-500 focus-visible:outline-red-600 active:bg-red-700"
                : "bg-amber-600 hover:bg-amber-500 focus-visible:outline-amber-600 active:bg-amber-700",
            "mt-4 w-full rounded-md px-4 py-2.5 text-sm font-semibold text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2",
          )}
          onClick={() =>
            handleRequestReview({
              packId: pack.id,
              approvalId: pack.approvals[0].id,
            })
          }
        >
          {pack.approvals[0].status == ApprovalStatus.UNANSWERED
            ? "Ask them to review this pack"
            : "Ask them to review it again"}
        </button>
        {backendMessage != "" && (
          <p className="mt-3 font-bold text-gray-500">{backendMessage}</p>
        )}
      </div>
    );
  };

  const renderButton = () => {
    if (pack.approvals.length === 0) {
      return noApproverPlaceholder();
    } else if (pack.approvals[0].user.id === user.id) {
      return currentUserApprover();
    } else if (pack.approvals[0].user.id !== user.id) {
      return currentUserNotApprover();
    } else {
      return null;
    }
  };

  return (
    <div className="fixed bottom-0 flex w-full max-w-md flex-col border-t border-gray-300 bg-paper-dark px-6 py-8">
      {renderButton()}
    </div>
  );
};

export default PackApprovalActions;
