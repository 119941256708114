import React, { useState, useEffect, useContext } from "react";
import axiosInstance from "../../axiosInstance";
import Cookies from "js-cookie";
import Select from "react-select";
import GenericModal from "../../components/ui/GenericModal/GenericModal";
import Slate from "../../components/ui/Slate/Slate";
import "./Settings.css";

const Settings = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [channels, setChannels] = useState(null);
  const [topics, setTopics] = useState(null);
  const [companyChannel, setCompanyChannel] = useState(null);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    // Fetch all company topics and the company main channel
    const fetchTopics = async () => {
      try {
        const response = await axiosInstance.get("/api/topics/list");
        setTopics(response.data);
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };
    const fetchCompanyChannel = async () => {
      try {
        const response = await axiosInstance.get("/api/company/channel");
        setCompanyChannel(response.data);
      } catch (error) {
        console.error("Error fetching company channel:", error);
      }
    };
    fetchTopics(); // List of company topics and respective channels
    fetchCompanyChannel(); // Company channel for default communications
  }, []);

  useEffect(() => {
    // Fetch all Slack channels in a company workspace
    const fetchSlackChannels = async () => {
      // setIsLoading(true);
      try {
        const response = await axiosInstance.get(`/api/channels/list`);
        setChannels(response.data);
      } catch (err) {
        // setError(err.message);
      } finally {
        // setIsLoading(false);
      }
    };
    fetchSlackChannels();
  }, []);

  const findChannelName = (channelId) => {
    const channel = channels.find((channel) => channel.value === channelId);
    return channel ? `# ${channel.label}` : "";
  };

  const handleCompanyChannelSelected = async (channelId) => {
    try {
      const response = await axiosInstance.put(`/api/company/channel`, {
        channel: channelId,
      });

      setCompanyChannel(response.data);
    } catch (error) {
      console.error("Error updating company channel:", error);
    }
  };

  const handleTopicChannelSelected = async (topic, channelId) => {
    try {
      let updatedTopic = {
        ...topic,
        channel: channelId,
      };
      const response = await axiosInstance.put(
        `/api/topics/update`,
        updatedTopic,
      );

      setTopics(response.data);
    } catch (error) {
      console.error("Error updating topic:", error);
    }
  };

  const handleExport = async ({ exportType }) => {
    try {
      const exportEndpoints = {
        packs: "export-packs",
        answers: "export-answers",
      };
      const response = await axiosInstance({
        url: `/api/${exportEndpoints[exportType]}`,
        method: "GET",
        responseType: "blob",
      });
      // Retrieve the CSV data
      const file = new Blob([response.data], { type: "text/csv" });

      // Create a link element, use it to download the blob, and remove it once done
      const fileURL = URL.createObjectURL(file);
      const fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", "export.csv"); // Set the file name
      document.body.appendChild(fileLink);
      fileLink.click();

      // Cleanup
      URL.revokeObjectURL(fileURL);
      document.body.removeChild(fileLink);
    } catch (error) {
      // setError(error.message);
    } finally {
      // setIsLoading(false);
    }
  };

  return (
    <div className="settings">
      <Slate
        slateTitle="Slack Channels"
        slateButton={
          <button
            className="rounded-md bg-white px-6 py-3 text-sm font-semibold   text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            onClick={() => openModal()}
          >
            Edit
          </button>
        }
      >
        <div className="mt-1 text-sm leading-6 text-gray-500">
          Receive instant alerts for pack creations and lifecycle updates,
          module risk rating adjustments, and module status changes (approval,
          approval pending, or rejection). Choose your ideal Slack channel for
          these notifications. By default, they're sent to the company-wide
          channel. Prefer a different channel for your team? Simply set it as
          your override, and all related notifications will be directed there
          instead
        </div>
        <h4 className="mt-6 text-base font-semibold leading-7 text-gray-900">
          🏢 Company wide
        </h4>
        {channels && (
          <div className="mb-6 list-item">
            <div className="title font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
              Company default channel
            </div>
            <div className="slack-channel text-gray-900">
              {findChannelName(companyChannel)}
            </div>
          </div>
        )}
        <h4 className="text-base font-semibold leading-7 text-gray-900">
          🧑🏼‍💻 Team specific overrides
        </h4>
        {channels &&
          topics &&
          topics.map((topic) => (
            <div key={topic.id} className="list-item">
              <div className="title font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                {topic.name}
              </div>
              <div className="slack-channel text-gray-900">
                {findChannelName(topic.channelId)}
              </div>
            </div>
          ))}
      </Slate>
      <Slate slateTitle="Download Data">
        <div className="flex items-center justify-between pt-5">
          <h3 className="text-base leading-6 text-gray-900">
            Export your Packs as a CSV. This includes information about each
            pack like name, derived risk, and lifecycle.
          </h3>
          <div className="mt-3 sm:ml-4 sm:mt-0">
            <button
              type="button"
              className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={(e) => {
                handleExport({ exportType: "packs" });
              }}
            >
              Download Packs
            </button>
          </div>
        </div>
        <div className="flex items-center justify-between pt-5">
          <h3 className="text-base leading-6 text-gray-900">
            Export your module answers as a CSV. This includes information like
            assessments, risk ratings, and monitoring cadence.
          </h3>
          <div className="mt-3 sm:ml-4 sm:mt-0">
            <button
              type="button"
              className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={(e) => {
                handleExport({ exportType: "answers" });
              }}
            >
              Download Answers
            </button>
          </div>
        </div>
      </Slate>
      {isModalOpen && (
        <GenericModal
          isOpen={isModalOpen}
          onClose={closeModal}
          title="Edit Slack Channels"
        >
          <div className="modal-section">
            <h4>Company wide default</h4>
            <div className="channel-group">
              <span className="channel-purpose">
                Company channel for default communications
              </span>
              <Select
                className="select select-channel"
                options={channels}
                value={channels.find(
                  (option) => option.value === companyChannel,
                )}
                onChange={(selectedOption) =>
                  handleCompanyChannelSelected(selectedOption.value)
                }
              />
            </div>
            <h4>Module specific</h4>
            {channels &&
              topics &&
              topics.map((topic) => (
                <div key={topic.id} className="list-item">
                  <div className="title">{topic.name}</div>
                  <Select
                    className="select select-channel"
                    options={channels}
                    value={channels.find(
                      (option) => option.value === topic.channelId,
                    )}
                    onChange={(selectedOption) =>
                      handleTopicChannelSelected(topic, selectedOption.value)
                    }
                  />
                </div>
              ))}
          </div>
        </GenericModal>
      )}
    </div>
  );
};

export default Settings;
