import React, { useContext, useState } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import {
  ChartPieIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UserIcon,
  Cog6ToothIcon,
  ArrowLeftEndOnRectangleIcon,
} from "@heroicons/react/24/outline";
import AuthContext from "../../context/AuthContext";
import { PacksContext } from "../../context/PacksContext";
import { classNames } from "../../utils";
import logoImage from "./logo64.png";

const CustomNavLink = ({ item }) => {
  const location = useLocation();
  return (
    <NavLink
      to={item.to || "#"}
      onClick={item.func || null}
      className={({ isActive, isPending }) =>
        classNames(
          isActive ? "text-neon-600" : "text-gray-700 hover:text-neon-600",
          "group flex gap-x-3 rounded-md pb-3 pl-2 text-sm font-semibold leading-6",
        )
      }
    >
      <item.icon
        className={classNames(
          location.pathname.startsWith(item.to)
            ? "text-neon-600"
            : "text-gray-400 group-hover:text-neon-600",
          "h-6 w-6 shrink-0",
        )}
        aria-hidden="true"
      />
      {item.label}
    </NavLink>
  );
};

const SidebarV2 = () => {
  const navigate = useNavigate();
  const { user, logout } = useContext(AuthContext);
  const { selectPack } = useContext(PacksContext);
  const [logoutHover, setLogoutHover] = useState(false);

  const goHome = () => {
    selectPack(null, null);
    navigate("/tasks");
  };

  const topNav = [
    { label: "Tasks", icon: HomeIcon, to: "/tasks" },
    { label: "Packs", icon: FolderIcon, to: "/packs" },
    // { label: "Insights", icon: ChartPieIcon, to: "/insights" },
    { label: "Reports", icon: DocumentDuplicateIcon, to: "/reports" },
  ];
  const bottomNav = [
    { label: "Settings", icon: Cog6ToothIcon, to: "/settings" },
  ];

  return (
    <div className="hidden lg:fixed lg:inset-y-0 lg:z-0 lg:flex lg:w-60 lg:flex-col">
      <div className="flex grow flex-col gap-y-3 overflow-y-auto border-r border-gray-200 bg-white px-6 pt-6">
        <div className="-ml-2 flex h-16 shrink-0 items-center">
          <img
            className="h-12 w-auto cursor-pointer"
            src={logoImage}
            alt="Company logo"
            onClick={goHome}
          />
        </div>
        <nav className="flex flex-1 flex-col">
          <ul role="list" className="flex flex-1 flex-col gap-y-7">
            <li>
              <ul role="list" className="-mx-2 space-y-1">
                {topNav.map((item) => (
                  <li key={item.label}>
                    <CustomNavLink item={item} />
                  </li>
                ))}
              </ul>
            </li>
            <li className="mt-auto">
              <ul role="list" className="space-y-1">
                {bottomNav.map((item) => (
                  <li key={item.label}>
                    <CustomNavLink item={item} />
                  </li>
                ))}
                <div className="flex items-center justify-between pb-5">
                  <div className="flex items-center">
                    <div
                      className={classNames(
                        logoutHover ? "opacity-20" : "opacity-100",
                        "flex h-9 w-9 items-center transition duration-1000 ease-in-out",
                      )}
                    >
                      {user.imageUrl ? (
                        <img
                          className="rounded-full"
                          src={user.imageUrl}
                          alt=""
                        />
                      ) : (
                        <UserIcon
                          className="h-8 w-8 rounded-full p-1 text-gray-500 ring-1 ring-gray-300"
                          aria-hidden="true"
                        />
                      )}
                    </div>
                    <div
                      className={classNames(
                        logoutHover ? "text-red-600" : "text-gray-900",
                        "pointer-events-none ml-3 text-sm font-semibold leading-6 transition duration-1000 ease-in-out",
                      )}
                    >
                      {logoutHover ? "Logout" : user.name}
                    </div>
                  </div>
                  <ArrowLeftEndOnRectangleIcon
                    title="Logout"
                    onClick={logout}
                    onMouseEnter={() => setLogoutHover(true)}
                    onMouseLeave={() => setLogoutHover(false)}
                    className={
                      "h-6 w-6 shrink-0 cursor-pointer text-gray-400 hover:text-red-600"
                    }
                    aria-hidden="true"
                  />
                </div>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default SidebarV2;
