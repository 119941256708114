import { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import OAuthRedirectHandler from "./pages/Login/OAuthRedirectHandler";
import Login from "./pages/Login/Login";
// import AppLayout from './layouts/AppLayout/AppLayout';
import AppLayoutMinimal from "./layouts/AppLayoutMinimal/AppLayoutMinimal";
import AppLayoutV3 from "./layouts/AppLayoutV3/AppLayoutV3";
import WelcomeView from "./pages/WelcomeView/WelcomeView";
import PackInfo from "./pages/PackInfo/PackInfo";
import PackOverview from "./pages/PackOverview";
import PackModules from "./pages/PackModules/PackModules";
import PackTimeline from "./pages/PackTimeline/PackTimeline";
import PackSettings from "./pages/PackSettings/PackSettings";
import Packs from "./pages/Packs/Packs";
import Reports from "./pages/Reports";
import Home from "./pages/Home/Home";
import Todos from "./pages/Todos";
import Settings from "./pages/Settings/Settings";
import QuestionSettings from "./pages/Settings/QuestionSettings";
import PackReports from "./pages/PackReports/PackReports";
import { ReportPage } from "./components/Reports/pdfRenderUtils";
import PackDocuments from "./pages/PackDocuments/PackDocuments";
import InitialQuestionnaire from "./pages/InitialQuestionnaire";
import { QandasProvider } from "./context/QandasContext";
import { PacksProvider } from "./context/PacksContext";
import login from "./context/AuthContext";
import { initializeAmplitude } from "./amplitudeUtils";
import axiosInstance from "./axiosInstance";
import "./App.css";

const LocalAuth = () => {
  const navigate = useNavigate();
  useEffect(() => {
    // Send the code to the backend to exchange for a JWT
    const getLocalToken = async () => {
      try {
        const response = await axiosInstance.get("/admin/auth/local");
        // If the backend responds positively, navigate to the homepage
        login();
        navigate("/");
      } catch (error) {
        // Handle errors, like showing a message or redirecting to the login page
        console.error("Error exchanging code for token:", error);
        // navigate('/login');
      }
    };

    getLocalToken();
  }, [navigate]);

  return (
    <div className="login-page">
      <div className="login-container">
        <p className="login-text">Local Auth</p>
      </div>
    </div>
  );
};

function App() {
  useEffect(() => {
    initializeAmplitude();
  }, []);

  const RedirectToTasks = () => {
    const navigate = useNavigate();

    useEffect(() => {
      navigate("/tasks");
    }, [navigate]);

    return null;
  };

  return (
    <Router>
      <div className="app-container">
        <PacksProvider>
          <QandasProvider>
            <Routes>
              {/* Public Routes */}
              <Route path="/login" element={<Login />} />
              <Route path="/auth/local" element={<LocalAuth />} />
              <Route
                path="/oauth/callback"
                element={<OAuthRedirectHandler />}
              />
              {/* ... other public routes ... */}

              {/* Protected Routes */}
              {/* <Route path="/" element={<AppLayout />}> */}
              {/* <Route path="/" element={<AppLayoutMinimal />}> */}
              <Route path="/" element={<AppLayoutV3 />}>
                {/* <Route index element={<WelcomeView />} /> */}
                {/* <Route index element={<Packs />} /> */}
                <Route index element={<RedirectToTasks />} />
                <Route path="packs/:packId" element={<PackOverview />} />
                <Route
                  path="packs/:packId/questionnaire"
                  element={<InitialQuestionnaire />}
                />
                <Route path="packs/:packId/info" element={<PackOverview />} />
                <Route path="packs/:packId/modules" element={<PackModules />} />
                <Route
                  path="packs/:packId/documents"
                  element={<PackDocuments />}
                />
                <Route path="packs/:packId/reports" element={<PackReports />} />
                <Route path="report-page" element={<ReportPage />} />
                <Route
                  path="packs/:packId/timeline"
                  element={<PackTimeline />}
                />
                <Route
                  path="packs/:packId/settings"
                  element={<PackSettings />}
                />
                <Route path="packs" element={<Packs />} />
                <Route path="home" element={<Home />} />
                <Route path="tasks" element={<Todos />} />
                <Route path="reports" element={<Reports />} />
                <Route path="settings" element={<Settings />} />
                <Route
                  path="settings/questions"
                  element={<QuestionSettings />}
                />
                {/* ... other protected routes ... */}
              </Route>
            </Routes>
          </QandasProvider>
        </PacksProvider>
      </div>
    </Router>
  );
}
export default App;
