import React, { useState, useContext, useEffect, Fragment } from "react";
import axiosInstance from "../../axiosInstance";
import { PacksContext } from "../../context/PacksContext";

const ModalActions = ({ currentModule, setCurrentModule, qandas, onSubmit }) => {
  const { updateModule } = useContext(PacksContext);
  const [readyToSubmit, setReadyToSubmit] = useState(false);

  useEffect(() => {
    setReadyToSubmit(
      qandas.filter((q) => q.answer === null && !q.isNa && q.required)
        .length === 0,
    );
  }, [qandas]);

  const handleSubmitForReview = async (moduleId) => {
    try {
      const response = await axiosInstance.post(`/api/modules/submit`, {
        module_id: moduleId,
      });
      let updatedModule = response.data.find((m) => m.id === moduleId);
      setCurrentModule(updatedModule);
      updateModule({
        packId: updatedModule.packId,
        moduleId: updatedModule.id,
        details: updatedModule,
      });
    } catch (error) {
      console.error("Error sending module for review:", error);
    }
  };

  const selfReviewButtonDisabled = () => {
    return (
      <div className="flex w-full flex-col">
        <button
          type="button"
          className="flex-1 rounded-md bg-green-600 px-3 py-3 text-sm font-semibold text-white opacity-30 shadow-sm"
          disabled={true}
        >
          Confirm module is finished
        </button>
        <div className="mt-1 text-xs font-medium italic text-gray-900 opacity-40">
          Answer all questions before you can flag this module as finished.
        </div>
      </div>
    );
  };

  const selfReviewButtonEnabled = () => {
    return (
      <div className="flex w-full flex-col">
        <button
          type="button"
          className="flex-1 rounded-md bg-green-600 px-3 py-3 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
          onClick={() => handleSubmitForReview(currentModule.id)}
        >
          Confirm module is finished
        </button>
        <div className="mt-1 text-xs font-medium italic text-gray-900 opacity-40">
          Because there's no stakeholders assigned to review this module you can
          flag it as finished.
        </div>
      </div>
    );
  };

  const submitButtonDisabled = () => {
    return (
      <div className="flex w-full flex-col">
        <button
          type="button"
          className="flex-1 rounded-md bg-green-600 px-3 py-3 text-sm font-semibold text-white opacity-30 shadow-sm"
          disabled={true}
        >
          Submit for Review
        </button>
        <div className="mt-1 text-xs font-medium italic text-gray-900 opacity-40">
          Answer all questions before you can submit this module for review.
        </div>
      </div>
    );
  };

  const submitButtonEnabled = () => {
    return (
      <Fragment>
        <button
          type="button"
          className="flex-1 rounded-md bg-green-600 px-3 py-3 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
          onClick={() => handleSubmitForReview(currentModule.id)}
        >
          Submit for Review
        </button>
        <div className="mt-1 text-xs font-medium italic text-gray-900 opacity-40">
          Once submitted, we'll notify the reviewers.
        </div>
      </Fragment>
    );
  };

  if ([2, 3, 4, 5, 6].includes(currentModule.status)) {
    return null;
  }
  return (
    <div className="fixed bottom-0 flex w-full max-w-md flex-col border-t border-gray-300 bg-paper-dark px-6 py-8">
      {currentModule.approvals.length > 0
        ? !readyToSubmit
          ? submitButtonDisabled()
          : readyToSubmit
            ? submitButtonEnabled()
            : null
        : !readyToSubmit
          ? selfReviewButtonDisabled()
          : readyToSubmit
            ? selfReviewButtonEnabled()
            : currentModule.status === 2 // covers where a stakeholder was added after the module was submitted (so its already in review)
              ? selfReviewButtonEnabled()
              : null}
    </div>
  );
};

export default ModalActions;
