import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { PacksContext } from "../../context/PacksContext";
import { PencilIcon } from "@heroicons/react/24/outline";

const AboutPack = ({ setDialogContent, setEditDialogOpen }) => {
  const { packId } = useParams();
  const { packs } = useContext(PacksContext);

  const handleEditClick = ({ code, label, value }) => {
    setDialogContent({
      code: code,
      label: label,
      value: value,
    });
    setEditDialogOpen(true);
  };

  return (
    <div className="space-y-4 rounded-xl bg-white p-6 shadow">
      <h2 className="text-xl font-semibold leading-7 text-gray-900">About</h2>
      <div className="group">
        <div className="flex items-start">
          <h3 className="mb-2 text-xs font-bold uppercase text-gray-500">
            Description
          </h3>
          <button
            className="ml-3 hidden items-center rounded-sm text-xs font-semibold text-neon-500 group-hover:flex"
            onClick={() =>
              handleEditClick({
                code: "description",
                label: "Description",
                value: packs[packId]?.description,
              })
            }
          >
            <PencilIcon className="h-3 w-3" />
            <span className="ml-1">Edit</span>
          </button>
        </div>
        <div className="text-sm font-light text-gray-900">
          {packs[packId]?.description ? (
            packs[packId]?.description
          ) : (
            <span className="text-xs font-extralight text-gray-400">
              No description
            </span>
          )}
        </div>
      </div>
      <div className="group">
        <div className="flex items-start">
          <h3 className="mb-2 text-xs font-bold uppercase text-gray-500">
            Target Customer
          </h3>
          <button
            className="ml-3 hidden items-center rounded-sm text-xs font-semibold text-neon-500 group-hover:flex"
            onClick={() =>
              handleEditClick({
                code: "targetCustomer",
                label: "Target Customer",
                value: packs[packId]?.targetCustomer,
              })
            }
          >
            <PencilIcon className="h-3 w-3" />
            <span className="ml-1">Edit</span>
          </button>
        </div>
        <div className="text-sm font-light text-gray-900">
          {packs[packId]?.targetCustomer ? (
            packs[packId]?.targetCustomer
          ) : (
            <span className="text-xs font-extralight text-gray-400">
              No target customer
            </span>
          )}
        </div>
      </div>
      <div className="group">
        <div className="flex items-start">
          <h3 className="mb-2 text-xs font-bold uppercase text-gray-500">
            Notes
          </h3>
          <button
            className="ml-3 hidden items-center rounded-sm text-xs font-semibold text-neon-500 group-hover:flex"
            onClick={() =>
              handleEditClick({
                code: "notes",
                label: "Notes",
                value: packs[packId]?.notes,
              })
            }
          >
            <PencilIcon className="h-3 w-3" />
            <span className="ml-1">Edit</span>
          </button>
        </div>
        <div className="text-sm font-light text-gray-900">
          {packs[packId]?.notes ? (
            packs[packId]?.notes
          ) : (
            <span className="text-xs font-extralight text-gray-400">
              No notes
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default AboutPack;
