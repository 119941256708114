import React, { useContext, useState, useEffect } from "react";
import axiosInstance from "../../../axiosInstance";
import { PacksContext } from "../../../context/PacksContext";
import ViewQanda from "../../ModuleModal/ViewQanda";

const QuestionnairePane = ({
  qandas,
  isOpen,
  setLeftPaneViewMode,
  setShowLeftPane,
  selectedItem,
  setSelectedItem,
  setIsOpen,
}) => {
  const { packs, updatePack, selectedPack } = useContext(PacksContext);
  const [allAnswered, setAllAnswered] = useState(false);

  useEffect(() => {
    const updateQuestionnaireStatus = async () => {
      try {
        const response = await axiosInstance.get("/api/questionnaire/status", {
          params: { packId: selectedPack.id },
        });
        let updatedPack = {
          ...packs[selectedPack.id],
          questionnaireStatus: response.data,
        };
        updatePack(selectedPack.id, updatedPack);
      } catch (error) {
        console.error("Error fetching questionnaire status in Pane:", error);
      }
    };
    if (selectedPack.id && isOpen === false) updateQuestionnaireStatus();
  }, [isOpen]);

  const handleItemSelected = (item, viewMode) => {
    setSelectedItem(item);
    setLeftPaneViewMode(viewMode);
    setShowLeftPane(true);
  };

  return (
    <div className="flex w-screen max-w-md flex-col">
      <div className="flex justify-between px-4 pt-6">
        <h1 className="px-3 text-2xl font-bold">Initial Assessment</h1>
      </div>
      <div className="space-y-4 px-4 pb-36 pt-6">
        <div>
          <h2 className="px-3 font-semibold">Questions</h2>
          <ul>
            {qandas.map((qanda) => (
              <ViewQanda
                key={qanda.questionId}
                qanda={qanda}
                selectedItem={selectedItem}
                handleClick={handleItemSelected}
              />
            ))}
          </ul>
        </div>
      </div>
      {allAnswered && (
        <div className="fixed bottom-0 flex w-full max-w-md flex-col border-t border-gray-300 bg-paper-dark px-6 py-8">
          <div className="flex w-full flex-col space-y-5">
            <div className="flex items-end justify-center space-x-4 text-sm font-medium text-gray-700">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="h-9 w-9"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 0 1 9 9v.375M10.125 2.25A3.375 3.375 0 0 1 13.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 0 1 3.375 3.375M9 15l2.25 2.25L15 12"
                  />
                </svg>
              </div>
              <div className="text-2xl">All done!</div>
            </div>
            <div className="text-center">You have answered all questions.</div>
            <button
              type="button"
              className="flex-1 rounded-md bg-green-600 px-3 py-3 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
              onClick={() => setIsOpen(false)}
            >
              Continue
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default QuestionnairePane;
