import React, { Fragment, useState, useContext, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import ConditionCard from "./ConditionCard";
import CreateCondition from "./CreateCondition";

export default function QuestionsModal({
  open,
  setOpen,
  questionId,
  topics,
  setTopics,
}) {
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [createNew, setCreateNew] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(null);

  useEffect(() => {
    if ((topics, questionId)) {
      setCurrentQuestion(
        topics
          .map((topic) => topic.questions)
          .flat()
          .find((q) => q.id === questionId),
      );
    }
  }, [topics, questionId]);

  if (!questionId) {
    // console.log("No question selected", questionId);
    return null;
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-100"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-100"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                      <button
                        type="button"
                        className="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={() => setOpen(false)}
                      >
                        <span className="absolute -inset-2.5" />
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="h-full w-screen max-w-2xl overflow-y-auto bg-paper p-8">
                    <div className="space-y-6 pb-16">
                      <h2 className="text-2xl font-bold text-gray-900">
                        Edit question
                      </h2>
                      <div id="question">
                        <div className="my-6 text-xl font-semibold ">
                          Question
                        </div>
                        <div className="mt-2 rounded-sm border-l-8 border-l-neon-400 pl-4 text-lg italic">
                          "{currentQuestion?.text}"
                        </div>
                      </div>
                      <div id="conditions">
                        <div className="my-5 flex justify-between">
                          <div className="text-xl font-semibold">
                            Conditions
                          </div>
                          <button
                            className="rounded-md bg-neon-600 px-2 py-1 text-white hover:bg-neon-500"
                            onClick={() => setCreateNew(true)}
                          >
                            Add new
                          </button>
                        </div>
                        {createNew && (
                          <div className="my-3 flex flex-col rounded-md bg-paper-darker px-5 py-8">
                            <CreateCondition
                              topics={topics}
                              setTopics={setTopics}
                              questionId={questionId}
                              setIsVisible={setCreateNew}
                            />
                          </div>
                        )}
                        <div className="mt-3 space-y-4">
                          {currentQuestion &&
                            currentQuestion.conditions &&
                            currentQuestion.conditions.pack &&
                            currentQuestion.conditions.pack.map((c) => (
                              <ConditionCard
                                key={c.condition_id}
                                topics={topics}
                                setTopics={setTopics}
                                questionId={questionId}
                                conditionScope="pack"
                                condition={c}
                              />
                            ))}
                          {currentQuestion &&
                            currentQuestion.conditions &&
                            currentQuestion.conditions.question &&
                            currentQuestion.conditions.question.map((c) => (
                              <ConditionCard
                                key={c.condition_id}
                                topics={topics}
                                setTopics={setTopics}
                                questionId={questionId}
                                conditionScope="question"
                                condition={c}
                              />
                            ))}
                        </div>
                      </div>
                      <div>
                        {isError && (
                          <div className="mb-4 rounded-md text-sm text-red-600">
                            <ExclamationCircleIcon className="mr-2 inline-block h-5 w-5" />

                            {errorMessage}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
