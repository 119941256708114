import { useEffect, useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { classNames } from "../../../utils";

const OptionsInput = ({ selectedItem, handleSaveAnswer }) => {
  const [selected, setSelected] = useState(selectedItem.answer);

  useEffect(() => {
    setSelected(selectedItem.answer);
  }, [selectedItem]);

  return (
    <RadioGroup
      key={selectedItem.questionId}
      className={"mt-4"}
      value={selected}
      onChange={setSelected}
    >
      <div className="space-y-4">
        {selectedItem.options.map((option) => (
          <RadioGroup.Option
            key={option.value}
            value={option.value}
            onClick={() =>
              handleSaveAnswer({ item: selectedItem, answer: option.value })
            }
            className={({ active }) =>
              classNames(
                active
                  ? "border-neon-600 ring-2 ring-neon-600"
                  : "border-gray-300",
                selected == option.value
                  ? "border-neon-200 ring-1 ring-neon-200"
                  : "",
                "relative block cursor-pointer rounded-lg border bg-paper-light px-6 py-4 focus:outline-none sm:flex sm:justify-between",
              )
            }
          >
            {({ active, checked }) => (
              <>
                <RadioGroup.Label as="span">{option.label}</RadioGroup.Label>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};

export default OptionsInput;
