import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from "@heroicons/react/20/solid";

const QuestionActions = ({ qandas, selectedItem, setSelectedItem }) => {
  const skipQuestion = (skipNum) => {
    let suggestedQuestions = qandas
      .filter((qanda) => qanda.answer === null && qanda.code != "notes")
      .sort((a, b) => a.questionId - b.questionId);
    let currentQuestionIndex = suggestedQuestions.findIndex(
      (qanda) => qanda.questionId === selectedItem.questionId,
    );
    if (currentQuestionIndex === suggestedQuestions.length - 1) {
      let nextQuestion = suggestedQuestions[0];
      setSelectedItem(nextQuestion);
    } else if (currentQuestionIndex + skipNum < 0) {
      let nextQuestion = suggestedQuestions[suggestedQuestions.length - 1];
      setSelectedItem(nextQuestion);
    } else {
      let nextQuestion = suggestedQuestions[currentQuestionIndex + skipNum];
      setSelectedItem(nextQuestion);
    }
  };

  return (
    <div className="px-16 pb-6 text-center">
      <nav className="flex items-center justify-between">
        <div className="-mt flex w-0 flex-1">
          <button
            onClick={() => skipQuestion(-1)}
            className="inline-flex items-center border-t-2 border-transparent pr-1 text-sm font-medium text-gray-500 hover:text-gray-700"
            disabled={
              qandas.filter((qanda) => qanda.answer === null).length > 1
                ? false
                : true
            }
          >
            <ArrowLongLeftIcon
              className="mr-3 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            Back
          </button>
        </div>
        <div className="-mt-px flex w-0 flex-1 justify-end">
          <button
            onClick={() => skipQuestion(1)}
            className="inline-flex items-center border-t-2 border-transparent pl-1 text-sm font-medium text-gray-500 hover:text-gray-700"
            disabled={
              qandas.filter((qanda) => qanda.answer === null).length > 1
                ? false
                : true
            }
          >
            Skip
            <ArrowLongRightIcon
              className="ml-3 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </button>
        </div>
      </nav>
    </div>
  );
};

export default QuestionActions;
