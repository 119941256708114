import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { UserIcon } from "@heroicons/react/24/outline";
import { PacksContext } from "../../context/PacksContext";
import PackHeader from "../../components/Pack/PackHeader/PackHeader";
import Slate from "../../components/ui/Slate/Slate";
import {
  TableRowUsers,
  TableRowText,
} from "../../components/PackSettings/TableRow";
import OwnerModal from "../../components/PackSettings/OwnerModal";
import ApproverModal from "../../components/PackSettings/ApproverModal";
import StakeholdersModal from "../../components/PackSettings/StakeholdersModal";
import DangerZone from "./DangerZone";
import TypeModal from "../../components/PackSettings/TypeModal";
import { packTypeOptions, classNames } from "../../utils";

const PackSettings = () => {
  const { packId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [selectedModule, setSelectedModule] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOwnersModalOpen, setIsOwnersModalOpen] = useState(false);
  const [isApproverModalOpen, setIsApproverModalOpen] = useState(false);
  const [isTypeModalOpen, setIsTypeModalOpen] = useState(false);
  const { packs, selectedPack, fetchPacksList, fetchPackModules, selectPack } =
    useContext(PacksContext);

  useEffect(() => {
    // Get the packs list from the API if we don't have it yet, or if its zero length
    if (packs.length === 0) {
      console.log("Fetching packs list in PackSettings");
      fetchPacksList();
      selectPack(packId);
    }
  }, [packs, packId]);

  useEffect(() => {
    if (packs && packs[packId] && !packs[packId].modules && !isLoading) {
      console.log("Fetching pack modules in PackSettings");
      fetchPackModules(packId);
    }
  }, [selectedPack]);

  if (isLoading)
    return (
      <div>
        <PackHeader />
        Loading content...
      </div>
    );
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="flex flex-grow flex-col">
      <PackHeader />
      <Slate slateTitle="Pack">
        <div className="inline-block min-w-full py-2 align-middle">
          <table className="min-w-full divide-y divide-gray-300">
            <tbody className="divide-y divide-gray-200 bg-white">
              <TableRowText
                rowName="Type"
                icon={
                  packTypeOptions.find(
                    (option) => option.value === packs[packId]?.type,
                  )?.icon
                }
                text={
                  packTypeOptions.find(
                    (option) => option.value === packs[packId]?.type,
                  )?.label
                }
                handleEdit={setIsTypeModalOpen}
              />
              {packs && (
                <TableRowUsers
                  rowName="Owner"
                  users={packs[packId]?.owner ? [packs[packId].owner] : []}
                  handleEdit={setIsOwnersModalOpen}
                />
              )}
              {packs && (
                <TableRowUsers
                  rowName="Approver"
                  users={packs[packId]?.approvals?.map((a) => a.user)}
                  handleEdit={setIsApproverModalOpen}
                />
              )}
            </tbody>
          </table>
        </div>
        <TypeModal
          pack={packs[packId] ? packs[packId] : {}}
          open={isTypeModalOpen}
          setOpen={setIsTypeModalOpen}
        />
        <OwnerModal
          pack={packs[packId] ? packs[packId] : {}}
          open={isOwnersModalOpen}
          setOpen={setIsOwnersModalOpen}
        />
        <ApproverModal
          pack={packs[packId] ? packs[packId] : {}}
          open={isApproverModalOpen}
          setOpen={setIsApproverModalOpen}
        />
      </Slate>
      <Slate slateTitle="Stakeholders">
        <div className="inline-block min-w-full py-2 align-middle">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="py-3.5 text-left text-base font-semibold text-gray-900"
                >
                  Module
                </th>
                <th
                  scope="col"
                  className="py-3.5 text-left text-base font-semibold text-gray-900"
                >
                  Reviewers
                </th>
                <th scope="col" className="relative py-3.5">
                  <span className="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {packs &&
                packs[packId] &&
                packs[packId].modules &&
                packs[packId].modules.map((module) => (
                  // <TableRow key={module.id} rowName={module.name} users={module.approvals} />
                  <tr key={module.id}>
                    <td className="whitespace-nowrap py-8 text-base text-gray-900">
                      {module.name}
                    </td>
                    <td className="whitespace-nowrap py-5 text-sm">
                      <div className="flex flex-wrap">
                        {module.approvals.map((approval) => (
                          <div key={approval.id} className="mr-5 flex">
                            <div className="h-11 w-11 flex-shrink-0">
                              {approval.user.image_24 ? (
                                <img
                                  className="h-11 w-11 rounded-full"
                                  src={approval.user.image_24}
                                  alt="stakeholder profile picture"
                                />
                              ) : (
                                <div className="rounded-full ring-1 ring-gray-300">
                                  <UserIcon
                                    className="h-11 w-11 p-1 text-gray-500"
                                    aria-hidden="true"
                                  />
                                </div>
                              )}
                            </div>
                            <div className="ml-4">
                              <div className="font-medium text-gray-900">
                                {approval.user.name}
                              </div>
                              <div className="mt-1 text-gray-500">
                                {approval.user.email}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </td>
                    <td className="relative whitespace-nowrap py-5 text-right text-sm font-medium">
                      <button
                        onClick={() => {
                          setSelectedModule(module);
                          setIsModalOpen(true);
                        }}
                        className="text-neon-600 hover:text-neon-900"
                      >
                        Edit<span className="sr-only">, {module.name}</span>
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <StakeholdersModal
          moduleId={selectedModule.id}
          open={isModalOpen}
          setOpen={setIsModalOpen}
        />
      </Slate>
      <DangerZone packId={packId} />
    </div>
  );
};

export default PackSettings;
