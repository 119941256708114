import React from "react";
import {
  ClipboardDocumentCheckIcon,
  UserPlusIcon,
  CheckIcon,
  DocumentTextIcon,
  PaperAirplaneIcon,
} from "@heroicons/react/24/outline";

const iconMapping = {
  finish_questionnaire: ClipboardDocumentCheckIcon,
  add_reviewer: UserPlusIcon,
  answer_qandas: DocumentTextIcon,
  submit_module: PaperAirplaneIcon,
  review_module: CheckIcon,
};

const TopTodo = ({ todo, handleTodoClicked }) => {
  const TodoIcon = iconMapping[todo.type] || CheckIcon;

  return (
    <div
      className="flex cursor-pointer space-x-4 rounded-xl bg-white p-4 shadow ring-1 ring-gray-200 transition duration-200 ease-in-out hover:divide-blue-300 hover:bg-blue-50 hover:ring-1 hover:ring-blue-300"
      onClick={() => handleTodoClicked(todo)}
    >
      <div className="flex items-center">
        <TodoIcon className="h-12 w-12 text-neon-500" />
      </div>
      <div className="flex items-center text-sm font-semibold">
        <div>
          <span>{todo.title}</span>
          <p className="font-light text-gray-500"> {todo.packName}</p>
        </div>
      </div>
    </div>
  );
};

export default TopTodo;
