import React, { Fragment, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { RadioGroup, Label, Radio } from "@headlessui/react";
import { classNames } from "../../../utils";
import { PacksContext } from "../../../context/PacksContext";
import { vendorCostOptions } from "../../../utils";

const CostDialog = ({ handleSave, updatePack, handleSuccess }) => {
  const { packs } = useContext(PacksContext);
  const { packId } = useParams();
  const [newCost, setNewCost] = useState(packs[packId].vendorCost);

  const handleSaveClick = () => {
    handleSave(packId, { vendor_cost: newCost }, updatePack, handleSuccess);
  };

  return (
    <Fragment>
      <RadioGroup value={newCost} onChange={setNewCost} className="my-6">
        <Label className="sr-only"> Choose a type of pack</Label>
        <div className="grid grid-cols-3 gap-3 sm:grid-cols-3">
          {vendorCostOptions.map((cost) => (
            <Radio
              key={cost.value}
              value={cost.value}
              className={({ active, checked }) =>
                classNames(
                  active ? "ring-2 ring-neon-400 ring-offset-2" : "",
                  checked
                    ? "bg-neon-400 text-white hover:bg-neon-300"
                    : "bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50",
                  "flex items-center justify-center rounded-md px-3 py-4 text-sm font-semibold uppercase sm:flex-1",
                  "cursor-pointer focus:outline-none",
                )
              }
            >
              <Label as="span">
                {cost.icon} {cost.label}
              </Label>
            </Radio>
          ))}
        </div>
      </RadioGroup>

      <button
        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 active:bg-gray-100 sm:col-start-1 sm:mt-0"
        onClick={handleSaveClick}
      >
        Save
      </button>
    </Fragment>
  );
};

export default CostDialog;
