import React, { useContext } from "react";
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { updatePackData } from "../../../services/packApi";
import { PacksContext } from "../../../context/PacksContext";
import LifecycleDialog from "./LifecycleDialog";
import DerivedRiskDialog from "./DerivedRiskDialog";
import OwnerDialog from "./OwnerDialog";
import TypeDialog from "./TypeDialog";
import SupplierTypeDialog from "./SupplierTypeDialog";
import WebsiteDialog from "./WebsiteDialog";
import CountryDialog from "./CountryDialog";
import CostDialog from "./CostDialog";
import ExpiryDateDialog from "./ExpiryDateDialog";
import PackDescriptionDialog from "./PackDescription";
import PackTargetCustomer from "./PackTargetCustomer";
import PackNotes from "./PackNotes";
import SignDateDialog from "./SignDateDialog";
import ContractTermDialog from "./ContractTermDialog";
import VatNumberDialog from "./VatNumberDialog";
import RegistrationNumberDialog from "./RegistrationNumberDialog";
import RegisteredAddressDialog from "./RegisteredAddressDialog";

export default function EditDialog({ open, onClose, dialogContent }) {
  const { updatePack } = useContext(PacksContext);
  const renderDialog = () => {
    switch (dialogContent?.code) {
      case "lifecycle":
        return (
          <LifecycleDialog
            handleSave={updatePackData}
            updatePack={updatePack}
            handleSuccess={onClose}
          />
        );
      case "derivedRisk":
        return <DerivedRiskDialog handleSuccess={onClose} />;
      case "owner":
        return <OwnerDialog handleSuccess={onClose} />;
      case "type":
        return (
          <TypeDialog
            handleSave={updatePackData}
            updatePack={updatePack}
            handleSuccess={onClose}
          />
        );
      case "supplierType":
        return (
          <SupplierTypeDialog
            handleSave={updatePackData}
            updatePack={updatePack}
            handleSuccess={onClose}
          />
        );
      case "website":
        return (
          <WebsiteDialog
            handleSave={updatePackData}
            updatePack={updatePack}
            handleSuccess={onClose}
          />
        );
      case "country":
        return (
          <CountryDialog
            handleSave={updatePackData}
            updatePack={updatePack}
            handleSuccess={onClose}
          />
        );
      case "cost":
        return (
          <CostDialog
            handleSave={updatePackData}
            updatePack={updatePack}
            handleSuccess={onClose}
          />
        );
      case "description":
        return (
          <PackDescriptionDialog
            handleSave={updatePackData}
            updatePack={updatePack}
            handleSuccess={onClose}
          />
        );
      case "targetCustomer":
        return (
          <PackTargetCustomer
            handleSave={updatePackData}
            updatePack={updatePack}
            handleSuccess={onClose}
          />
        );
      case "notes":
        return (
          <PackNotes
            handleSave={updatePackData}
            updatePack={updatePack}
            handleSuccess={onClose}
          />
        );

      case "signDate":
        return (
          <SignDateDialog
            handleSave={updatePackData}
            updatePack={updatePack}
            handleSuccess={onClose}
          />
        );
      case "expiryDate":
        return (
          <ExpiryDateDialog
            handleSave={updatePackData}
            updatePack={updatePack}
            handleSuccess={onClose}
          />
        );
      case "contractTerm":
        return (
          <ContractTermDialog
            handleSave={updatePackData}
            updatePack={updatePack}
            handleSuccess={onClose}
          />
        );

      case "vatNumber":
        return (
          <VatNumberDialog
            handleSave={updatePackData}
            updatePack={updatePack}
            handleSuccess={onClose}
          />
        );
      case "registrationNumber":
        return (
          <RegistrationNumberDialog
            handleSave={updatePackData}
            updatePack={updatePack}
            handleSuccess={onClose}
          />
        );
      case "registeredAddress":
        return (
          <RegisteredAddressDialog
            handleSave={updatePackData}
            updatePack={updatePack}
            handleSuccess={onClose}
          />
        );

      default:
        return (
          <div>
            <p className="py-3 text-sm text-gray-500">
              Data edit not supported for this data type.
            </p>
          </div>
        );
    }
  };

  return (
    <Dialog open={open} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
          <DialogTitle
            as="h3"
            className="flex items-center space-x-2 text-base font-semibold leading-6 text-gray-900"
          >
            <span>{dialogContent?.icon}</span>
            <span className="ml-1">{dialogContent?.label}</span>
          </DialogTitle>
          {renderDialog()}
        </DialogPanel>
      </div>
    </Dialog>
  );
}
