import React from "react";

const CarouselCard = ({ user, handleAddUser }) => {
  return (
    <div
      key={user.slackUserId}
      className={
        "group relative flex h-20 cursor-pointer items-center space-x-2 rounded px-4 py-3 ring-1 ring-gray-300 ring-opacity-50"
      }
      onClick={() => {
        handleAddUser(user);
      }}
    >
      <div className="absolute inset-0 flex items-center justify-center rounded bg-neon-500 opacity-0 group-hover:opacity-100">
        <span className="font-bold text-white">Add</span>
      </div>
      <img
        src={user.image24}
        alt={user.name}
        className="h-8 w-8 rounded-full"
      />
      <span className="font-medium text-gray-900">{user.name}</span>
    </div>
  );
};

export default CarouselCard;
