import React from "react";

const ViewHelper = ({ selectedItem }) => {
  if (
    selectedItem == null ||
    selectedItem.helper === undefined ||
    selectedItem.helper === null ||
    selectedItem.helper === ""
  ) {
    return null;
  }
  return (
    <div className="mx-3 flex flex-col rounded-xl bg-paper-dark p-10">
      <div className="text-left text-lg font-semibold text-gray-800">
        How to answer this question
      </div>
      <div className="mt-2 whitespace-pre-line text-sm text-gray-800">
        {selectedItem.helper}
      </div>
    </div>
  );
};

export default ViewHelper;
