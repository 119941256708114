import React, { createContext, useEffect, useState } from "react";

const QandasContext = createContext(undefined);
const QandasDispatchContext = createContext(undefined);

function QandasProvider({ children }) {
  const [qandas, setQandas] = useState([]);
  const [selectedQanda, setSelectedQanda] = useState(null);
  const [showQuestion, setShowQuestion] = useState(false);
  const [loadingQandas, setLoadingQandas] = useState(false);

  return (
    <QandasContext.Provider
      value={{
        qandas,
        setQandas,
        selectedQanda,
        setSelectedQanda,
        showQuestion,
        setShowQuestion,
        loadingQandas,
        setLoadingQandas,
      }}
    >
      <QandasDispatchContext.Provider value={setQandas}>
        {children}
      </QandasDispatchContext.Provider>
    </QandasContext.Provider>
  );
}

export { QandasProvider, QandasContext, QandasDispatchContext };
