import React, { useEffect, useState } from "react";
import AutoResizingTextarea from "../../ui/AutoResizingTextarea/AutoResizingTextarea";

const TextAnswerInput = ({ selectedItem, handleSaveAnswer }) => {
  const [answerInput, setAnswerInput] = useState(selectedItem.answer || "");

  useEffect(() => {
    setAnswerInput(selectedItem.answer || "");
  }, [selectedItem]);

  return (
    <div className="flex flex-col">
      {selectedItem && (
        <AutoResizingTextarea value={answerInput} onChange={setAnswerInput} />
      )}
      <button
        className="ml-auto rounded-md bg-neon-600 px-4 py-2.5 text-sm font-semibold text-white hover:bg-neon-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-neon-600 active:bg-neon-700"
        onClick={() =>
          handleSaveAnswer({ item: selectedItem, answer: answerInput })
        }
      >
        Save answer
      </button>
    </div>
  );
};

export default TextAnswerInput;
