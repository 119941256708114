import { useEffect, useState } from "react";
import axios from "axios";
import { bugsnag } from "../bugsnagUtils";

const useCountries = () => {
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get(
          "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code",
        );
        setCountries(response.data.countries);
      } catch (error) {
        bugsnag.notify(new Error("Can't fetch countries", error));
        console.error("Error fetching countries:", error);
      }
    };
    fetchCountries();
  }, []);

  const getCountryName = (countryCode) => {
    const country = countries.find((option) => option.value === countryCode);
    return country ? country.label : "";
  };

  return { countries, getCountryName };
};

export { useCountries };
