import React, { useState } from "react";
import { Combobox } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import debounce from "lodash.debounce";
import axiosInstance from "../../axiosInstance";
import { classNames } from "../../utils";

function UserSelector({ selectedUser, setSelectedUser }) {
  const [queryResults, setQueryResults] = useState([]);

  // Function to fetch channels from Slack API
  const fetchUsers = async (value) => {
    try {
      const response = await axiosInstance.get("/api/users/search", {
        params: { query: value },
      });
      setQueryResults(response.data);
    } catch (error) {
      console.error("Error fetching users in user selector:", error);
    }
  };

  // Debounced function to handle input changes
  const debouncedFetchUsers = debounce((value) => {
    fetchUsers(value);
  }, 300); // Waits 300ms after the last keystroke to make the API call

  return (
    <Combobox as="div" value={selectedUser} onChange={setSelectedUser}>
      <div className="relative mt-2">
        <Combobox.Input
          className="w-full rounded-md border-0 bg-white py-2 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-neon-600 sm:text-sm sm:leading-6"
          onChange={(event) => debouncedFetchUsers(event.target.value)}
          displayValue={(user) => user?.name}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>

        {queryResults.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {queryResults.map((user) => (
              <Combobox.Option
                key={user.slack_user_id}
                value={user}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-3 pr-9",
                    active ? "bg-neon-600 text-white" : "text-gray-900",
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <div className="flex">
                      <span
                        className={classNames(
                          "truncate",
                          selected && "font-semibold",
                        )}
                      >
                        {user.name}
                      </span>
                      <span
                        className={classNames(
                          "ml-2 truncate text-gray-400",
                          active ? "text-neon-100" : "text-gray-400",
                        )}
                      >
                        @{user.handle}
                      </span>
                    </div>

                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          active ? "text-white" : "text-neon-600",
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}

export default UserSelector;
