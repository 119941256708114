import { UsersIcon } from "@heroicons/react/24/outline";

const ViewApprovalPlaceholder = ({ currentModule }) => {
  const withApprovers = () => {
    const approverNames = currentModule.approvals.map(
      (approval, index, array) => {
        let name = approval.user.name;
        if (index === array.length - 1 && array.length > 1) {
          return " and " + name;
        } else if (index < array.length - 2) {
          return name + ",";
        } else {
          return name;
        }
      },
    );

    return (
      <div className="mt-2 rounded-lg bg-paper-dark p-8 text-center shadow-inner shadow-stone-400/15">
        <div className="flex justify-center space-x-2">
          {currentModule.approvals.map((approval) => (
            <img
              key={approval.user.id}
              className="inline-block h-8 w-8 rounded-full"
              src={approval.user.image_24}
              alt={approval.user.name}
            />
          ))}
        </div>
        <span className="mt-2 block text-sm font-semibold text-gray-500">
          This module will be reviewed by {approverNames.join(" ")} (after it's
          submitted it for review)
        </span>
      </div>
    );
  };

  const withoutApprovers = () => {
    return (
      <div className="mt-2 rounded-lg bg-paper-dark p-8 text-center shadow-inner shadow-stone-400/15">
        <div className="flex justify-center space-x-2">
          <UsersIcon className="h-8 w-8 rounded-full text-gray-400" />
        </div>
        <span className="mt-2 block text-sm font-semibold text-gray-500">
          No stakeholders have been assigned to review this module (you can do
          this in the settings tab)
        </span>
      </div>
    );
  };

  return (
    <div>
      {currentModule.approvals.length > 0
        ? withApprovers()
        : withoutApprovers()}
    </div>
  );
};

export default ViewApprovalPlaceholder;
