import React, { useContext, useState } from "react";
import axiosInstance from "../../axiosInstance";
import {
  PDFViewer,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import { classNames } from "../../utils";
import { PacksContext } from "../../context/PacksContext";
import { render } from "@testing-library/react";

export const PdfHeading1 = ({ item }) => {
  return (
    <View>
      <Text>
        {item.content}
      </Text>
    </View>
  );
};

export const PdfHeading2 = ({ item }) => {
  return (
    <View>
      <Text>
        {item.content}
      </Text>
    </View>
  );
};

export const PdfHeading3 = ({ item }) => {
  return (
    <View>
      <Text>
        {item.content}
      </Text>
    </View>
  );
};

export const PdfHeading4 = ({ item }) => {
  return (
    <View>
      <Text >{item.content}</Text>
    </View>
  );
};

export const PdfSection = ({ item }) => {
  // The title in a "section" should be formatted similarly to a "heading4"
  return (
    <View>
      <Text>{item.title}</Text>
      <Text>{item.content}</Text>
    </View>
  );
};

export const PdfQanda = ({ item }) => {
  let answer_label = item.questionOptions?.find(
    (option) => option.value === item.answer,
  )?.label;
  switch (item.questionType) {
    case "int_options":
      return (
        <View>
          <Text>{item.question}</Text>
          <Text>
            {answer_label}
          </Text>
        </View>
      );
    case "text_options":
      return (
        <View >
          <Text>
            {item.question}
            <Text>
              {answer_label}
            </Text>
          </Text>
        </View>
      );
    default:
      return (
        <View>
          <Text>{item.question}</Text>
          <Text>
            {item.answer}
          </Text>
        </View>
      );
  }
};

export const PdfParagraph = ({ item }) => {
  return <Text>{item.content}</Text>;
};

export const PdfMetadata = ({ item }) => {
  return (
    <View>
      <Text>{item.content}</Text>
    </View>
  );
};

export const PdfIndicatorsTable = ({ index, item }) => {
  return (
    <div
      key={index}
      className="-mx-4 mt-4 rounded ring-1 ring-gray-300 sm:mx-0"
    >
      <table className="min-w-full">
        <thead>
          <tr className="divide-x divide-gray-300">
            {item.header.map((cellText) => (
              <th
                scope="col"
                className="pt-3.5 text-center text-sm font-medium text-gray-900"
              >
                {cellText}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr className="divide-x divide-gray-300">
            {item.row.map((cellData, cellDataIndex) => (
              <td className="relative pb-4 text-center text-lg font-semibold">
                <div className="text-gray-900">{cellData}</div>
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export const PdfRiskTable = ({ index, item }) => {
  return (
    <div
      key={index}
      className="-mx-4 mt-4 rounded ring-1 ring-gray-300 sm:mx-0"
    >
      <table className="min-w-full">
        <thead>
          <tr className="divide-x divide-gray-300">
            <th
              scope="col"
              className="bg-green-200 py-3.5 text-center font-medium text-green-900"
            >
              Low
            </th>
            <th
              scope="col"
              className="bg-yellow-200 py-3.5 text-center font-medium text-yellow-900"
            >
              Medium
            </th>
            <th
              scope="col"
              className="bg-red-200 py-3.5 text-center font-medium text-red-900"
            >
              High
            </th>
            <th
              scope="col"
              className="bg-gray-200 py-3.5 text-center font-medium text-gray-900"
            >
              NA
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="divide-x divide-gray-300">
            {Object.values(item.content).map((cellData, cellDataIndex) => (
              <td className="relative pb-4 text-center">
                <ul className="mt-3 text-gray-900">
                  {cellData.map((cell) => (
                    <li>{cell}</li>
                  ))}
                </ul>
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const PdfDocument = ({ data }) => {
  // This is the rendered PDF document. It can be used in the PDFViewer and PDFDownloadLink components
  const renderPdfReportItem = (item, index) => {
    switch (item.type) {
      case "heading1":
        return <PdfHeading1 key={index} item={item} />;
      case "heading2":
        return <PdfHeading2 key={index} item={item} />;
      case "heading3":
        return <PdfHeading3 key={index} item={item} />;
      case "heading4":
        return <PdfHeading4 key={index} item={item} />;
      case "paragraph":
        return <PdfParagraph key={index} item={item} />;
      case "metadata":
        return <PdfMetadata key={index} item={item} />;
      case "section":
        return <PdfSection key={index} item={item} />;
      case "qanda":
        return <PdfQanda key={index} item={item} />;
      case "indicatorsTable":
        return <PdfIndicatorsTable key={index} item={item} />;
      case "riskTable":
        return <PdfRiskTable key={index} item={item} />;
      default:
        return null;
    }
  };

  return (
    <Document>
      <Page size="A4">
        <View>
          {data.map(renderPdfReportItem)}
        </View>
      </Page>
    </Document>
  );
};

export const ReportPage = () => {
  // This component is to be used only in development due to the way PDFViewer works
  const { packs } = useContext(PacksContext);
  const [reportItems, setReportItems] = useState([]);
  const [selectedPackId, setSelectedPackId] = useState("");
  const fetchReportContent = async () => {
    try {
      const response = await axiosInstance.get("/api/reports/content", {
        params: { type: "packBasic", id: selectedPackId },
      });
      setReportItems(response.data);
      console.log("🤓 Latest report fetched: ", response.data);
    } catch (error) {
      console.error("Error fetching report data:", error);
    }
  };

  return (
    <div>
      <div className="mb-3 flex space-x-3">
        <select
          value={selectedPackId}
          onChange={(e) => setSelectedPackId(e.target.value)}
          className="rounded border border-gray-300 px-4 py-2 font-bold text-gray-600 hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-opacity-50"
        >
          <option value="">Select a pack</option>
          {Object.keys(packs).map((packId) => (
            <option key={packId} value={packId}>
              {packs[packId].name}
            </option>
          ))}
        </select>
        <button
          className={classNames(
            "rounded bg-neon-500 px-4 py-2 font-bold text-white hover:bg-neon-700",
          )}
          onClick={fetchReportContent}
        >
          Fetch report content
        </button>
      </div>
      <PDFViewer style={{ width: "100%", height: "1200px" }}>
        <PdfDocument data={reportItems} />
      </PDFViewer>
    </div>
  );
};

export default PdfDocument;
