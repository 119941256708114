import { PlusIcon } from "@heroicons/react/20/solid";
import React, { useEffect, useState, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxOpen } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { PacksContext } from "../../context/PacksContext";
import {
  packTypeOptions,
  packRiskOptions,
  packLifecycleOptions,
} from "../../utils";
import NewPack from "../../components/NewPack/NewPack";
import NewPackDialog from "../../components/NewPack/NewPackDialog";
import PackFilters from "../../components/Pack/PackFilters/PackFilters";

const Packs = () => {
  const navigate = useNavigate();
  const { packs, isLoading, error, selectPack } = useContext(PacksContext);
  const [packsArray, setPacksArray] = useState([]);
  const [filteredPacks, setFilteredPacks] = useState(packsArray);
  const [isNewPackModalOpen, setIsNewPackModalOpen] = useState(false);
  const [newPackDialog, setNewPackDialog] = useState(false);

  useEffect(() => {
    // Convert packs list (keyed dict) into objects array for easier manipulation
    if (!isLoading && !error) {
      setPacksArray(Object.values(packs));
      // const packsArray = Object.values(packs);
    }
  }, [packs]);

  const packClicked = (packId, packName) => {
    selectPack(packId, packName);
    navigate(`/packs/${packId}`);
  };

  const noSearchResults = () => {
    return (
      <div className="my-10 flex h-60 flex-col items-center justify-center rounded-lg ring-1 ring-gray-300">
        <FontAwesomeIcon icon={faBoxOpen} className="h-12 w-12 text-gray-500" />
        <span className="mb-1 mt-3 text-sm font-semibold text-gray-500">
          No packs found
        </span>
        <span className="text-xs text-gray-500">Try changing your filters</span>
      </div>
    );
  };

  return (
    <div>
      <div className="flex justify-between">
        <div>
          <button
            // onClick={() => setIsNewPackModalOpen(true)}
            onClick={() => setNewPackDialog(true)}
            type="button"
            className="flex rounded-full bg-neon-600 px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-neon-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-neon-600"
          >
            <PlusIcon className="h-5 w-5" aria-hidden="true" />
            <span className="ml-1">New Pack</span>
          </button>
        </div>
        <PackFilters
          packsArray={packsArray}
          setFilteredPacks={setFilteredPacks}
        />
      </div>
      {error && <div>Error: {error}</div>}
      {packsArray.length > 0 && filteredPacks.length === 0 && noSearchResults()}
      <ul
        role="list"
        className="my-7 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4"
      >
        {filteredPacks.map((pack) => (
          <li
            key={pack.id}
            className="group flex h-48 cursor-pointer flex-col divide-y rounded-lg bg-white shadow hover:divide-blue-300 hover:bg-blue-50 hover:ring-1 hover:ring-blue-300"
            onClick={() => packClicked(pack.id, pack.name)}
          >
            <div className="grow justify-between space-x-6 p-5">
              <div className="">
                <div className="flex items-center space-x-3">
                  <h3 className="truncate pb-1 text-xl font-bold text-gray-900 group-hover:text-neon-400">
                    {pack.name}
                  </h3>
                </div>
                <div className="pb-2">
                  <span className="truncate pr-1 text-sm text-gray-400 group-hover:text-neon-200">
                    {
                      packTypeOptions.find(({ value }) => value === pack.type)
                        ?.icon
                    }
                  </span>
                  <span className="truncate text-sm text-gray-400 group-hover:text-neon-200">
                    {
                      packTypeOptions.find(({ value }) => value === pack.type)
                        ?.label
                    }
                  </span>
                </div>
                <div className="line-clamp-3 text-xs">{pack.description}</div>
              </div>
              {/* <img className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300" src={person.imageUrl} alt="" /> */}
            </div>
            <div className="rounded-b-lg px-5 py-3">
              <div className="-mt-px flex justify-between">
                <div className="items-center text-xs font-light uppercase text-gray-700">
                  {pack.risk != 0 &&
                    `${packRiskOptions.find(({ value }) => value === pack.risk)?.label} risk`}
                </div>
                <div className="items-center text-xs font-light uppercase text-gray-700">
                  {
                    packLifecycleOptions.find(
                      ({ value }) => value === pack.lifecycle,
                    )?.label
                  }
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
      {/* <div className="card-container">
                {!isLoading && !error && filteredPacks.map((pack) => (
                    <div
                        key={pack.id}
                        className='card'
                        // className={`pack-title ${selectedPack.id === pack.id ? 'active' : ''}`}
                        onClick={() => packClicked(pack.id, pack.name)}
                    >
                        <div className='card-header'>
                            <span>{pack.name}</span>
                            <div>
                                <span className='pack-type'>{packTypeOptions.find(({ value }) => value === pack.type).icon}</span>
                                <span className='pack-type'>{packTypeOptions.find(({ value }) => value === pack.type).label}</span>
                            </div>
                        </div>
                        <div className='card-content'>
                            {pack.description}
                        </div>
                        <div className='card-footer'>
                            <span>{pack.risk != 0 ? `${packRiskLabel[pack.risk]} risk` : ""}</span>
                            <span>{packLifecycleLabel[pack.lifecycle]}</span>
                        </div>
                    </div>
                ))}
            </div> */}
      {isNewPackModalOpen && (
        <NewPack onClose={() => setIsNewPackModalOpen(false)} />
      )}
      <NewPackDialog isOpen={newPackDialog} onClose={setNewPackDialog} />
    </div>
  );
};

export default Packs;
