import React, { Fragment, useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import { DocumentChartBarIcon } from "@heroicons/react/24/outline";
import { PacksContext } from "../../../context/PacksContext";
import ReportOverlay from "../../Reports/ReportOverlay";
import "./PackHeader.css";

const PackHeader = () => {
  const { selectedPack } = useContext(PacksContext);
  const [showOverlay, setShowOverlay] = useState(false);

  return (
    <div className="pack-nav flex items-center justify-between rounded-lg bg-white text-lg font-bold text-gray-600 shadow shadow-gray-200 ring-1 ring-gray-200">
      <div className="px-7 py-5">
        <NavLink
          className={"mr-7 hover:text-neon-300"}
          to={`/packs/${selectedPack.id}`}
          end
        >
          Overview
        </NavLink>
        <NavLink
          className={"mr-7 hover:text-neon-300 active:text-neon-500"}
          to={`/packs/${selectedPack.id}/modules`}
        >
          Modules
        </NavLink>
        <NavLink
          className={"mr-7 hover:text-neon-300 active:text-neon-500"}
          to={`/packs/${selectedPack.id}/documents`}
        >
          Docs
        </NavLink>
        <NavLink
          className={"mr-7 hover:text-neon-300 active:text-neon-500"}
          to={`/packs/${selectedPack.id}/timeline`}
        >
          Timeline
        </NavLink>
        <NavLink
          className={"mr-7 hover:text-neon-300 active:text-neon-500"}
          to={`/packs/${selectedPack.id}/settings`}
        >
          Settings
        </NavLink>
      </div>
      <div
        className="mr-4 flex cursor-pointer items-center space-x-2 rounded px-4 py-2 hover:bg-slate-100 hover:text-gray-900"
        title="Pack Report"
        onClick={() => setShowOverlay(true)}
      >
        <span>View report</span>
        <DocumentChartBarIcon className="h-6 w-6" />
      </div>
      <ReportOverlay
        packId={selectedPack.id}
        isOpen={showOverlay}
        setIsOpen={setShowOverlay}
        reportType="packBasic"
      />
    </div>
  );
};

export default PackHeader;
