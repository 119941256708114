import React, { useRef, useEffect } from "react";

const AutoResizingTextarea = ({ value, onChange, placeholder }) => {
  const textareaRef = useRef(null);

  const handleResize = () => {
    const textarea = textareaRef.current;
    if (!textarea) return;
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  // Resize when value changes
  useEffect(() => {
    handleResize();
  }, [value]);

  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <div className="auto-resizing-text-area">
      <textarea
        ref={textareaRef}
        value={value}
        placeholder={placeholder || ""}
        onChange={handleChange}
        style={{ height: "auto", overflow: "hidden" }}
        className="my-4 block w-full resize-none rounded-md border-0 bg-transparent p-3 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-neon-600 sm:text-sm sm:leading-6"
      />
    </div>
  );
};

export default AutoResizingTextarea;
