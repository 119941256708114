import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { PacksContext } from "../../context/PacksContext";
import "./Home.css";

const Home = () => {
  return (
    <div className="home">
      <div className="list-container">
        <div className="list-item">
          <span className="event-name">Hello</span>
          <div className="event-details">
            <span className="event-user">World</span>
            <span className="event-date">Home</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
