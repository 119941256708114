import { Fragment, useState, useContext } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon, LockClosedIcon } from "@heroicons/react/24/outline";
import { PlusIcon, ExclamationCircleIcon } from "@heroicons/react/20/solid";
import axiosInstance from "../../axiosInstance";
import UserSelector from "../functional/UserSelector";
import { PacksContext } from "../../context/PacksContext";

function AddAdvisor({ moduleId, setIsOpen }) {
  const { packs, selectedPack, updatePack } = useContext(PacksContext);
  const [selectedUser, setSelectedUser] = useState("");
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleAddReviewer = async () => {
    try {
      let newAdvisor = {
        packId: selectedPack.id,
        moduleId: moduleId,
        approverSlackId: selectedUser.slack_user_id,
      };
      const response = await axiosInstance.post(
        `/api/approvals/create`,
        newAdvisor,
      );
      let advisor = response.data;
      let updatedModules = packs[selectedPack.id].modules.map((module) => {
        if (module.id === moduleId) {
          return {
            ...module,
            approvals: [...module.approvals, advisor],
          };
        } else {
          return module;
        }
      });
      let updatedPack = { ...packs[selectedPack.id], modules: updatedModules };
      updatePack(selectedPack.id, updatedPack);
      setIsOpen(false);
      setIsError(false);
      setErrorMessage("");
    } catch (error) {
      console.error("Error:", error);
      setIsError(true);
      setErrorMessage(error.response.data.msg);
    }
  };

  return (
    <div className="mt-4 rounded-lg bg-paper-dark px-6 py-8">
      <div className="mb-4 text-base font-semibold text-gray-600">
        Pick a new reviewer
      </div>
      {isError && (
        <div className="mb-4 rounded-md text-sm text-red-600">
          <ExclamationCircleIcon className="mr-2 inline-block h-5 w-5" />

          {errorMessage}
        </div>
      )}
      <UserSelector
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
      />
      <div className="mt-3 flex">
        <button
          type="button"
          className="flex-1 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          onClick={() => setIsOpen(false)}
        >
          Cancel
        </button>
        <button
          type="button"
          className="ml-3 flex-1 rounded-md bg-neon-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-neon-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-neon-600"
          onClick={() => {
            handleAddReviewer({
              newPackOwnerSlackUserId: selectedUser.slack_user_id,
            });
          }}
        >
          Add
        </button>
      </div>
    </div>
  );
}

export default function StakeholdersModal({ moduleId, open, setOpen }) {
  const { packs, selectedPack, updatePack } = useContext(PacksContext);
  const [showSearchBox, setShowSearchBox] = useState(false);

  let module = packs[selectedPack.id]?.modules?.find(
    (module) => module.id === moduleId,
  );

  const handleDeleteAdvisor = async (approval) => {
    try {
      const response = await axiosInstance.post(
        `/api/approvals/delete`,
        approval,
      );
      let updatedModules = packs[selectedPack.id].modules.map((module) => {
        if (module.id === moduleId) {
          return {
            ...module,
            approvals: [
              ...module.approvals.filter((a) => a.id !== approval.id),
            ],
          };
        } else {
          return module;
        }
      });
      let updatedPack = { ...packs[selectedPack.id], modules: updatedModules };
      updatePack(selectedPack.id, updatedPack);
    } catch (error) {
      console.error("Error deleting stakeholder:", error);
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-100"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-100"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                      <button
                        type="button"
                        className="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={() => setOpen(false)}
                      >
                        <span className="absolute -inset-2.5" />
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="h-full w-screen max-w-md overflow-y-auto bg-paper p-8">
                    <div className="space-y-6 pb-16">
                      <div>
                        <h2 className="text-2xl font-bold text-gray-900">
                          {module ? module.name : ""}
                        </h2>
                      </div>
                      <div>
                        <h3 className="py-3 font-semibold text-gray-900">
                          Reviewers
                        </h3>
                        <ul
                          role="list"
                          className="mt-2 divide-y divide-gray-300 border-b border-t border-gray-300"
                        >
                          {module &&
                            module.approvals &&
                            module.approvals.map((approval) => (
                              <li
                                key={approval.id}
                                className="flex items-center justify-between py-5"
                              >
                                <div className="flex items-center">
                                  <img
                                    src={approval.user.image_512}
                                    alt=""
                                    className="h-10 w-10 rounded-full"
                                  />
                                  <p className="ml-4 text-base font-medium text-gray-900">
                                    {approval.user.name}
                                  </p>
                                </div>
                                {approval.required ? (
                                  <div className="flex items-center space-x-1">
                                    <LockClosedIcon className="size-3.5 text-gray-500" />
                                    <span className="text-xs font-medium text-gray-500">
                                      Required
                                    </span>
                                  </div>
                                ) : (
                                  <button
                                    type="button"
                                    className="ml-6 rounded-md text-xs font-medium text-red-600 hover:text-red-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                                    onClick={() =>
                                      handleDeleteAdvisor(approval)
                                    }
                                  >
                                    Remove
                                    <span className="sr-only">
                                      {" "}
                                      {approval.user.name}
                                    </span>
                                  </button>
                                )}
                              </li>
                            ))}
                          {!showSearchBox && (
                            <li className="flex items-center justify-between py-5">
                              <button
                                type="button"
                                className="group -ml-1 flex items-center rounded-md p-1 focus:outline-none focus:ring-2 focus:ring-neon-500"
                                onClick={() => setShowSearchBox(true)}
                              >
                                <span className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-dashed border-gray-300 text-gray-400">
                                  <PlusIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                </span>
                                <span className="ml-4 text-base font-medium text-neon-600 group-hover:text-neon-500">
                                  Add reviewer
                                </span>
                              </button>
                            </li>
                          )}
                        </ul>
                        {showSearchBox && (
                          <AddAdvisor
                            moduleId={module.id}
                            setIsOpen={setShowSearchBox}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
