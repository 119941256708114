import React from "react";
import { LockClosedIcon } from "@heroicons/react/24/outline";
import { classNames } from "../../../utils";

const Stakeholder = ({
  stakeholder,
  isDragging,
  handleDragStart,
  handleDragOver,
  handleDrop,
  handleRemoveModule,
  enableEdit,
}) => {
  return (
    <div
      key={stakeholder.slackUserId}
      className={classNames(
        "flex min-h-60 flex-col items-center rounded-md px-1 py-5 ring-1 ring-gray-200 transition duration-300 ease-in-out",
        isDragging && "bg-green-50 ring-green-400",
      )}
      onDragOver={(e) => handleDragOver(e)}
      onDrop={(e) => handleDrop(e, stakeholder.slackUserId)}
    >
      <img
        className="m-3 h-20 w-20 rounded-full"
        src={stakeholder.image512}
        alt={stakeholder.name}
      />
      <div className="flex flex-col items-center">
        <span className="block text-center text-lg font-semibold text-gray-900">
          {stakeholder.name}
        </span>
      </div>
      <div className="mt-2 flex-wrap space-x-1 space-y-1 px-1 text-center">
        {stakeholder.modules
          .sort((a, b) => b.required - a.required)
          .map((module) => (
            <div
              className="inline-flex cursor-default items-center gap-x-0.5 rounded-md bg-neon-50 px-2 py-1 text-center text-xs font-medium text-neon-700 ring-1 ring-inset ring-neon-600/10"
              key={module.id}
              draggable={enableEdit && !module.required}
              onDragStart={(e) =>
                handleDragStart(e, module.id, stakeholder.slackUserId)
              }
            >
              {module.name}
              {enableEdit &&
                (module.required ? (
                  <LockClosedIcon className="h-3.5 w-3.5 text-neon-600" />
                ) : (
                  <button
                    type="button"
                    className="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-neon-600/20"
                    onClick={() =>
                      handleRemoveModule(module.id, stakeholder.slackUserId)
                    }
                  >
                    <span className="sr-only">Remove</span>
                    <svg
                      viewBox="0 0 14 14"
                      className="h-3.5 w-3.5 stroke-neon-600/50 group-hover:stroke-neon-600/75"
                    >
                      <path d="M4 4l6 6m0-6l-6 6" />
                    </svg>
                    <span className="absolute -inset-1" />
                  </button>
                ))}
            </div>
          ))}
      </div>
    </div>
  );
};

export default Stakeholder;
