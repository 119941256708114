import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { moduleStatusOptions, classNames } from "../../utils";

const draftstatusOptions = [
  { value: 0, label: "No status" },
  { value: 1, label: "First Pass" },
  { value: 2, label: "Second Pass" },
  { value: 3, label: "Done" },
];

const DraftStatusMenu = ({ currentModule, handleSaveModule }) => {
  return (
    <div className="flex flex-col justify-center space-y-1 text-center">
      <div className="mr-1 text-sm font-semibold text-gray-500 opacity-50">
        {moduleStatusOptions[currentModule.status]}
      </div>
      {currentModule.status === 1 && (
        <Menu as="div" className="relative inline-block text-left">
          <div>
            <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-paper-dark px-3 py-2 text-xs font-semibold text-gray-500 opacity-50 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-amber-50">
              {currentModule.draftStatus !== undefined &&
                draftstatusOptions.find(
                  (option) => option.value === currentModule.draftStatus,
                ).label}
              <ChevronDownIcon
                className="-mr-1 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-paper-dark shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                {draftstatusOptions.map((option) => (
                  <Menu.Item key={option.value}>
                    {({ active }) => (
                      <div
                        onClick={() =>
                          handleSaveModule({
                            ...currentModule,
                            draftStatus: option.value,
                          })
                        }
                        className={classNames(
                          active
                            ? "bg-amber-100 text-gray-900"
                            : "text-gray-700",
                          "block px-4 py-2 text-sm",
                        )}
                      >
                        {option.label}
                      </div>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      )}
    </div>
  );
};

export default DraftStatusMenu;
