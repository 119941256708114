import { Fragment, useState, useContext } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import axiosInstance from "../../axiosInstance";
import UserSelector from "../functional/UserSelector";
import { PacksContext } from "../../context/PacksContext";

const StakeholderCard = ({ stakeholder }) => {
  return (
    <div className="relative flex space-x-4 rounded bg-paper-dark p-5">
      <img
        className="inline-block h-28 w-28 rounded-md"
        src={stakeholder.image_512}
        alt=""
      />
      <div className="mt-2 flex-col">
        <div className="text-xl font-semibold">
          {stakeholder.name || "No owner set"}
        </div>
        <div className="text-sm">{stakeholder.email}</div>
      </div>
    </div>
  );
};

export default function OwnerModal({ pack, open, setOpen }) {
  const { packs, selectedPack, updatePack } = useContext(PacksContext);
  const [editMode, setEditMode] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSetOwner = async () => {
    try {
      let newOwner = {
        packId: pack.id,
        ownerSlackUserId: selectedUser.slack_user_id,
      };
      const response = await axiosInstance.post("/api/packs/owner", newOwner);
      updatePack(selectedPack.id, response.data.pack);
      setEditMode(false);
      setIsError(false);
      setErrorMessage("");
    } catch (error) {
      console.error("Error:", error);
      setIsError(true);
      setErrorMessage(error.response.data.msg);
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-100"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-100"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                      <button
                        type="button"
                        className="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={() => setOpen(false)}
                      >
                        <span className="absolute -inset-2.5" />
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="h-full w-screen max-w-md overflow-y-auto bg-paper p-8">
                    <div className="space-y-6 pb-16">
                      <h2 className="text-2xl font-bold text-gray-900">
                        Pack Owner
                      </h2>
                      <div>
                        {editMode ? (
                          <div>
                            {isError && (
                              <div className="mb-4 rounded-md text-sm text-red-600">
                                <ExclamationCircleIcon className="mr-2 inline-block h-5 w-5" />

                                {errorMessage}
                              </div>
                            )}
                            <UserSelector
                              selectedUser={selectedUser}
                              setSelectedUser={setSelectedUser}
                            />
                            <button
                              type="button"
                              onClick={() => handleSetOwner()}
                              className="mt-3 w-full flex-1 rounded-md bg-neon-600 px-3 py-3 text-sm font-semibold text-white shadow-sm hover:bg-neon-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-neon-600"
                            >
                              Save
                            </button>
                            <button
                              type="button"
                              onClick={() => {
                                setEditMode(false);
                                setSelectedUser("");
                                setErrorMessage("");
                                setIsError(false);
                              }}
                              className="mt-2 w-full flex-1 rounded-md bg-white px-3 py-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            >
                              Cancel
                            </button>
                          </div>
                        ) : pack.owner ? (
                          <div>
                            <StakeholderCard stakeholder={pack.owner} />
                            <button
                              type="button"
                              onClick={() => setEditMode(true)}
                              className="mt-3 w-full flex-1 rounded-md bg-white px-3 py-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            >
                              Change owner
                            </button>
                          </div>
                        ) : (
                          <div>
                            <div className="text-xl font-semibold">
                              No owner defined
                            </div>
                            <button
                              type="button"
                              onClick={() => setEditMode(true)}
                              className="mt-3 w-full flex-1 rounded-md bg-white px-3 py-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            >
                              Set owner
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
