import React, { useEffect, useContext } from "react";
import {
  useParams,
  useNavigate,
  useLocation,
  useMatch,
} from "react-router-dom";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";

import { PacksContext } from "../../context/PacksContext";
import usePageTitle from "../../hooks/usePageTitle";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pageTitle, showBackArrow } = usePageTitle();
  const { packId } = useParams();
  const { packs, selectPack, selectedPack } = useContext(PacksContext);
  const matchPack = useMatch("/packs/:packId/:subPage");

  useEffect(() => {
    if (matchPack) {
      // console.log("matchPack", matchPack);
      selectPack(matchPack.params.packId);
    }
    if (Object.keys(packs).length > 0 && packId && !pageTitle) {
      const packToSelect = packs[packId];
      if (packToSelect) {
        selectPack(packToSelect.id, packToSelect.name);
      }
    }
  }, [location, matchPack]);

  const goHome = () => {
    navigate(`/packs`);
  };

  return (
    <header className="mx-6 mt-5">
      <div className="max-w-7xl">
        <div className="flex h-16 justify-between">
          <div className="flex">
            <div className="inline-flex items-center">
              {showBackArrow && (
                <ChevronLeftIcon
                  className="-mr-1 h-10 w-10 cursor-pointer text-gray-900"
                  aria-hidden="true"
                  onClick={goHome}
                />
              )}
              <div className="ml-2 min-w-0 flex-1">
                <h1 className="pointer-events-none text-2xl font-semibold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                  {pageTitle}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
