import { useEffect, useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { classNames } from "../../../utils";

const BooleanInput = ({ selectedItem, handleSaveAnswer }) => {
  const [selected, setSelected] = useState(selectedItem.answer);

  useEffect(() => {
    setSelected(selectedItem.answer);
  }, [selectedItem]);

  return (
    <RadioGroup className={"mt-4"} value={selected} onChange={setSelected}>
      <div className="flex space-x-4">
        {selectedItem.options.map((option) => (
          <RadioGroup.Option
            key={option.value}
            value={option.value}
            onClick={() =>
              handleSaveAnswer({ item: selectedItem, answer: option.value })
            }
            className={({ active }) =>
              classNames(
                active
                  ? "border-neon-600 ring-2 ring-neon-600"
                  : "border-gray-300",
                selected == option.value
                  ? "border-neon-200 ring-1 ring-neon-200"
                  : "",
                "relative block flex-1 cursor-pointer rounded-lg border bg-paper-light px-6 py-4 text-center shadow-sm focus:outline-none",
              )
            }
          >
            {({ active, checked }) => (
              <>
                <RadioGroup.Label as="span">{option.label}</RadioGroup.Label>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};

export default BooleanInput;
