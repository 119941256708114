import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "../../axiosInstance";
import PackHeader from "../../components/Pack/PackHeader/PackHeader";
import ModuleModal from "../../components/ModuleModal/ModuleModal";
import { PacksContext } from "../../context/PacksContext";
import ModulesTable from "../../components/PackModules/ModulesTable";

const PackModules = () => {
  const { packId } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedModuleId, setSelectedModuleId] = useState(null);
  const {
    packs,
    selectPack,
    selectedPack,
    fetchPackModules,
    isLoading,
    error,
  } = useContext(PacksContext);

  useEffect(() => {
    let isMounted = false;
    const fetchPackContent = async () => {
      // if (isMounted) setLoading(true);
      try {
        const response = await axiosInstance.get(
          `/api/packs/${packId}/modules`,
        );
        // setPackContent(response.data);
        if (isMounted) {
          // setPackContent(response.data);
          // if (selectedPack?.id != packId) {
          //     selectPack(packId, response.data.title);
          // }
        }
      } catch (error) {
        // if (isMounted) setError(error.message);
      } finally {
        // if (isMounted) setIsLoading(false);
      }
    };
    if (packs && packs[packId] && !packs[packId].modules && !isLoading) {
      console.log("Fetching pack modules in PackModules");
      fetchPackModules(packId);
    }
    if (selectedPack?.id != packId) {
      selectPack(packId);
    }
    return () => {
      isMounted = false;
    }; // Cleanup function to prevent memory leaks
  }, [packs, selectedPack]);

  const handleModuleClicked = (moduleId) => {
    setSelectedModuleId(moduleId);
    setIsModalOpen(true);
  };

  if (isLoading)
    return (
      <div>
        <PackHeader />
        {/* Loading content... */}
      </div>
    );
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="flex flex-grow flex-col">
      <PackHeader />
      {packs && packs[packId] && packs[packId].modules && (
        <ModulesTable
          modules={packs[packId].modules}
          handleModuleClicked={handleModuleClicked}
        />
      )}
      <ModuleModal
        moduleId={selectedModuleId}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
      />
    </div>
  );
};

export default PackModules;
