import { UserIcon } from "@heroicons/react/24/outline";

export function TableRowText({ rowName, text, icon, handleEdit }) {
  return (
    <tr>
      <td className="whitespace-nowrap py-8 text-base text-gray-900">
        {rowName}
      </td>
      <td className="whitespace-nowrap py-5 text-sm">
        <div className="flex flex-wrap">
          <div className="mr-5 flex">
            <div className="h-11 w-11 flex-shrink-0 rounded-full bg-neon-500 text-white">
              <div className="flex h-full w-full items-center justify-center text-xl opacity-70">
                {icon}
              </div>
            </div>
            <div className="ml-4 flex items-center font-semibold">{text}</div>
          </div>
        </div>
      </td>
      <td className="relative whitespace-nowrap py-5 text-right text-sm font-medium">
        <button
          onClick={() => {
            handleEdit(true);
          }}
          className="text-neon-600 hover:text-neon-900"
        >
          Edit
        </button>
      </td>
    </tr>
  );
}

export function TableRowUsers({ rowName, users, handleEdit }) {
  return (
    <tr>
      <td className="whitespace-nowrap py-8 text-base text-gray-900">
        {rowName}
      </td>
      <td className="whitespace-nowrap py-5 text-sm">
        <div className="flex flex-wrap">
          {users?.map((user) => (
            <div key={user.id} className="mr-5 flex">
              <div className="h-11 w-11 flex-shrink-0">
                {user.image_24 ? (
                  <img
                    className="h-11 w-11 rounded-full"
                    src={user.image_24}
                    alt="stakeholder profile picture"
                  />
                ) : (
                  <div className="rounded-full ring-1 ring-gray-300">
                    <UserIcon
                      className="h-11 w-11 p-1 text-gray-500"
                      aria-hidden="true"
                    />
                  </div>
                )}
              </div>
              <div className="ml-4">
                <div className="font-medium text-gray-900">{user.name}</div>
                <div className="mt-1 text-gray-500">{user.email}</div>
              </div>
            </div>
          ))}
        </div>
      </td>
      <td className="relative whitespace-nowrap py-5 text-right text-sm font-medium">
        <button
          onClick={() => {
            handleEdit(true);
          }}
          className="text-neon-600 hover:text-neon-900"
        >
          Edit
        </button>
      </td>
    </tr>
  );
}
