import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBitcoinSign,
  faBuildingColumns,
  faChartSimple,
  faCreditCard,
  faEllipsis,
  faGavel,
  faMask,
  faPeopleGroup,
  faWrench,
  faTruckField,
  faGem,
  faStar,
  faEarthAfrica,
  faShield,
  faEquals,
  faGreaterThan,
  faGreaterThanEqual,
  faLessThan,
  faLessThanEqual,
} from "@fortawesome/free-solid-svg-icons";
import {
  TruckIcon,
  HeartIcon,
  StarIcon,
  GlobeEuropeAfricaIcon,
  EllipsisHorizontalIcon,
  FaceFrownIcon,
  FaceSmileIcon,
  ExclamationCircleIcon,
  QuestionMarkCircleIcon,
  CursorArrowRaysIcon,
  WrenchIcon,
  BuildingLibraryIcon,
  ScaleIcon,
  CreditCardIcon,
  CpuChipIcon,
  BanknotesIcon,
  UsersIcon,
  ShieldCheckIcon,
} from "@heroicons/react/24/outline";
import { ApprovalStatus } from "./constants";

export const packTypeOptions = [
  {
    value: "product",
    label: "Product",
    icon: <FontAwesomeIcon icon={faGem} />,
    icon2: faGem,
    icon3: HeartIcon,
    heroIcon: HeartIcon,
    HeroIcon: <HeartIcon className="h-6 w-6" />,
  },
  {
    value: "feature",
    label: "Feature",
    icon: <FontAwesomeIcon icon={faStar} />,
    icon2: faStar,
    icon3: StarIcon,
    heroIcon: StarIcon,
    HeroIcon: <StarIcon className="h-6 w-6" />,
  },
  {
    value: "supplier",
    label: "Supplier",
    icon: <FontAwesomeIcon icon={faTruckField} />,
    icon2: faTruckField,
    icon3: TruckIcon,
    heroIcon: TruckIcon,
    HeroIcon: <TruckIcon className="h-6 w-6" />,
  },
  {
    value: "geography",
    label: "Geography",
    icon: <FontAwesomeIcon icon={faEarthAfrica} />,
    icon2: faEarthAfrica,
    icon3: GlobeEuropeAfricaIcon,
    heroIcon: GlobeEuropeAfricaIcon,
    HeroIcon: <GlobeEuropeAfricaIcon className="h-6 w-6" />,
  },
  {
    value: "other",
    label: "Other",
    icon: <FontAwesomeIcon icon={faEllipsis} />,
    icon2: faEllipsis,
    icon3: EllipsisHorizontalIcon,
    heroIcon: EllipsisHorizontalIcon,
    HeroIcon: <EllipsisHorizontalIcon className="h-6 w-6" />,
  },
];

export const packRiskOptions = [
  { value: 0, label: "NA", icon: "⚪️", heroIcon: QuestionMarkCircleIcon },
  { value: 1, label: "Low", icon: "🟢", heroIcon: FaceSmileIcon },
  { value: 2, label: "Medium", icon: "🟡", heroIcon: FaceFrownIcon },
  { value: 3, label: "High", icon: "🔴", heroIcon: ExclamationCircleIcon },
  { value: null, label: "", icon: "⚪️", heroIcon: QuestionMarkCircleIcon },
];

export const packLifecycleOptions = [
  { value: 5, label: "Initial Assessment" },
  { value: 0, label: "Not started" },
  { value: 1, label: "In progress" },
  { value: 2, label: "Live" },
  { value: 3, label: "On hold" },
  { value: 4, label: "Ended" },
];

export const packApprovalOptions = [
  { value: ApprovalStatus.APPROVED, label: "✅ Approved" },
  { value: ApprovalStatus.REJECTED, label: "🚫 Rejected" },
  { value: ApprovalStatus.UNANSWERED, label: "⏳ Not reviewed" },
];

export const vendorTypeOptions = [
  {
    value: "marketing",
    label: "Marketing",
    icon: <FontAwesomeIcon icon={faChartSimple} />,
    icon2: faChartSimple,
    heroIcon: CursorArrowRaysIcon,
  },
  {
    value: "infrastructure",
    label: "Infrastructure",
    icon: <FontAwesomeIcon icon={faWrench} />,
    icon2: faWrench,
    heroIcon: WrenchIcon,
  },
  {
    value: "banking",
    label: "Banking",
    icon: <FontAwesomeIcon icon={faBuildingColumns} />,
    icon2: faBuildingColumns,
    heroIcon: BuildingLibraryIcon,
  },
  {
    value: "legal",
    label: "Legal & Regulatory",
    icon: <FontAwesomeIcon icon={faGavel} />,
    icon2: faGavel,
    heroIcon: ScaleIcon,
  },
  {
    value: "payments",
    label: "Payments",
    icon: <FontAwesomeIcon icon={faCreditCard} />,
    icon2: faCreditCard,
    heroIcon: CreditCardIcon,
  },
  {
    value: "crypto",
    label: "Crypto",
    icon: <FontAwesomeIcon icon={faBitcoinSign} />,
    icon2: faBitcoinSign,
    heroIcon: CpuChipIcon,
  },
  {
    value: "fincrime",
    label: "Financial Crime",
    icon: <FontAwesomeIcon icon={faMask} />,
    icon2: faMask,
    heroIcon: BanknotesIcon,
  },
  {
    value: "operations",
    label: "Operations",
    icon: <FontAwesomeIcon icon={faPeopleGroup} />,
    icon2: faPeopleGroup,
    heroIcon: UsersIcon,
  },
  {
    value: "security",
    label: "Security",
    icon: <FontAwesomeIcon icon={faShield} />,
    icon2: faPeopleGroup,
    heroIcon: ShieldCheckIcon,
  },
  {
    value: "other",
    label: "Other",
    icon: <FontAwesomeIcon icon={faEllipsis} />,
    icon2: faEllipsis,
    heroIcon: EllipsisHorizontalIcon,
  },
];

export const vendorCostOptions = [
  { value: 0, label: "NA" },
  { value: 1, label: "0-2k" },
  { value: 2, label: "2-10k" },
  { value: 3, label: "> 10k" },
];

export const moduleStatusOptions = {
  0: "Not Started",
  1: "Draft",
  2: "Pending Review",
  3: "Approved",
  4: "Rejected",
  5: "Pending Review",
  6: "Done",
};

export const draftStatusOptions = {
  0: "",
  1: "First Pass",
  2: "Second Pass",
  3: "Done",
};

export const moduleCadenceOptions = {
  null: "Select a cadence",
  disabled: "Not Required",
  weekly: "Weekly",
  monthly: "Monthly",
  quarterly: "Quarterly",
  yearly: "Yearly",
};

// DEPRECATED
export const packTypeLabel = {
  supplier: "Supplier",
  product: "Product",
  feature: "Feature",
  other: "Other",
};

// DEPRECATED
export const packRiskLabel = {
  0: "NA",
  1: "Low",
  2: "Medium",
  3: "High",
};

// DEPRECATED
export const packLifecycleLabel = {
  0: "Not started",
  1: "In progress",
  2: "Live",
  3: "On hold",
  4: "Ended",
};

export const endorsementStatusLabel = (status) => {
  switch (status) {
    case 0:
      return "Pending";
    case 1:
      return "Endorsed";
    case 2:
      return "Opposed";
    case 3:
      return "Neutral";
  }
};

export const approvalStatusLabel = (status) => {
  switch (status) {
    case 0:
      return "Not reviewed";
    case 1:
      return "Approved";
    case 2:
      return "Rejected";
    case 3:
      return "Neutral";
  }
};

export const conditionOperators = [
  { value: "lt", label: "<", icon: <FontAwesomeIcon icon={faLessThan} /> },
  {
    value: "lte",
    label: "<=",
    icon: <FontAwesomeIcon icon={faLessThanEqual} />,
  },
  { value: "eq", label: "=", icon: <FontAwesomeIcon icon={faEquals} /> },
  {
    value: "gte",
    label: ">=",
    icon: <FontAwesomeIcon icon={faGreaterThanEqual} />,
  },
  { value: "gt", label: ">", icon: <FontAwesomeIcon icon={faGreaterThan} /> },
];

export const packConditionParameters = [
  {
    id: "assessment",
    name: "Initial Assessment",
    icon: <FontAwesomeIcon icon={faEllipsis} />,
    options: [
      { value: 0, label: "Not completed" },
      { value: 1, label: "Completed" },
    ],
  },
];

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
