import React from "react";
import { endorsementStatusLabel, classNames } from "../../utils";

const ViewApproval = ({ currentModule, approval, handleClick }) => {
  const approvalComment = (comment) => {
    if (comment !== null && comment !== "") {
      return `"${comment}"`;
    } else {
      return "No comment given";
    }
  };

  const reviewingNotAllowed = () => {
    return (
      <dt className="my-3 flex bg-red-200 p-3 text-sm font-medium text-gray-500">
        <img
          className="mr-2 h-6 w-6 rounded-full"
          src={approval.user.image_24}
          alt="user profile picture"
        />
        {currentModule.status} --
        {approval.user.name}:{" "}
        {approval.status === 1
          ? "Endorsed"
          : approval.status === 2
            ? "Opposed"
            : "Pending"}
      </dt>
    );
  };

  const reviewingAllwoed = () => {
    return (
      <dt className="my-3 flex bg-blue-200 p-3 text-sm font-medium text-gray-500">
        <img
          className="mr-2 h-6 w-6 rounded-full"
          src={approval.user.image_24}
          alt="user profile picture"
        />
        {currentModule.status} --
        {approval.user.name}:{" "}
        {approval.status === 1
          ? "Endorsed"
          : approval.status === 2
            ? "Opposed"
            : "Pending"}
      </dt>
    );
  };

  const cardRouter = () => {
    switch (currentModule.status) {
      case 0:
        return reviewingNotAllowed();
      case 1:
        return reviewingNotAllowed();
      case 2:
        return reviewingAllwoed();
      case 3:
        return reviewingAllwoed();
      case 4:
        return reviewingAllwoed();
      case 5:
        return reviewingAllwoed();
      case 6:
        return reviewingAllwoed();
      default:
        return reviewingAllwoed();
    }
  };

  return (
    <div
      className={classNames(
        approval.status === 0
          ? "rounded-lg border-2 border-dashed border-gray-300 p-6 hover:border-gray-400"
          : approval.status === 1
            ? "border-l-8 border-l-green-400 hover:border-green-500"
            : approval.status === 2
              ? "border-l-8 border-l-red-400 hover:border-red-500"
              : "border-l-8 border-l-gray-400 hover:border-gray-500",
        "mt-2 cursor-pointer p-6 text-sm",
      )}
      onClick={() => handleClick(approval, "approvals")}
    >
      <dt className="flex font-medium text-gray-500">
        <img
          className="mr-2 h-6 w-6 rounded-full"
          src={approval.user.image_24}
          alt="user profile picture"
        />
        {approval.user.name} (
        {endorsementStatusLabel(approval.status).toLowerCase()})
      </dt>
      {approval.status !== 0 && (
        <dd className="mt-1 italic text-gray-900">
          {approvalComment(approval.comment)}
        </dd>
      )}
    </div>
  );
};

export default ViewApproval;
